import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Steps, Button, Row, Col, Modal, Icon, Form} from 'antd';
import { Link } from "react-router-dom";
import registro from '../../img/1registro.png';
import docs from '../../img/2cargadoc.png';
import platica from '../../img/3platica.png';
import finaliza from '../../img/4finaliza.png';
import conDocs from '../../img/2condocs.png';
import conPlatica from '../../img/3conplatica.png';
import finalizado from '../../img/4finalizado.png';
import * as etiquetas from '../../ApplicationResources';
import {cargarAspirante} from '../../utils/funciones';
const { Step } = Steps;

class GuiaEstatus extends Component { 

    constructor(props) {    
        super(props);  
        
        this.state = { 
            permiteModificar: true,
            permiteDescargar: true,
            nuevo: true,
            modalAbrir: false
        }; 
    }
    
    componentWillMount() {
      const datosAspirante = this.props.datosAspirante || {};
      if(datosAspirante.estatusRegistro === 0){
        this.setState({nuevo: false})   //Desaparece Modificar y Descarga en un registro nuevo
      }

      if(datosAspirante.estatusRegistro === 0  ||                                      //Si es un registro nuevo
        (datosAspirante.estatusRegistro === 1 && datosAspirante.bAcudirJDE === 1) ||   //Si es aspirante compulsado
        (datosAspirante.estatusRegistro === 2 && datosAspirante.bObservacion === 0) || //Si es aspirante validado
         datosAspirante.estatusRegistro === 3 || datosAspirante.estatusRegistro === 4) { //Si es aspirante finalizado o declinado
            this.setState({permiteModificar: false})     //Deshabilita el boton para que no pueda acceder el usuario
      }

      var esClaveElectorFuar = etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(datosAspirante.claveElectorFuar);
      if(datosAspirante.estatusRegistro === 1 && esClaveElectorFuar === true) { //Si es un registro SIAP, no hay compulsa
        this.setState({permiteDescargar: false})    //Deshabilita el boton para que no pueda acceder el usuario
      }
    }

    modificar = () =>{
      const datosAspirante = this.props.datosAspirante || {};
      if(datosAspirante.estatusRegistro === 1 ||  //Si es aspirante registrado, inicia con carga de Documentos
         datosAspirante.estatusRegistro === 2){   //Si al aspirante aun no le validan documentos, podra modificar
          cargarAspirante(datosAspirante.folioAcceso).then(datosAspirante => {
            if(datosAspirante.estatusRegistro === 3){
              this.setState({modalAbrir: true});
            }else{
              this.props.history.push('/bandeja/modifica/'+ this.props.datosAspirante.folioAcceso+"&"+this.props.datosAspirante.claveElectorFuar);
            }
          });
           
      }     
    }

    abrirCerrarModal = () =>{
      this.setState({modalAbrir: false});
      window.location.reload();
    }

    descargarAcuses = () =>{
      this.props.history.push('/descargar_acuses');
    }
  
  render() {
    const datosAspirante = this.props.datosAspirante;
    var estatus;
    if (datosAspirante.estatusRegistro < 4) {
      if (datosAspirante.estatusRegistro === 3)
        estatus = 4;      
      else if (datosAspirante.estatusRegistro === 2 &&
               (datosAspirante.bPlatica === 0 || datosAspirante.bPlatica === 1))
        estatus = 3;
      else
        estatus = datosAspirante.estatusRegistro;
    }
    else
      estatus = -1;

    var classPasoAnterior;

    if (datosAspirante.bObservacion)
      classPasoAnterior = 'observaciones';

    const paso1 = estatus === 0 ? "Llena tu solicitud" : "Solicitud registrada";
    const paso2 = estatus <= 1 ? "Carga tus documentos" : "Documentos cargados";
    const paso3 = estatus <= 2 ? "Ve la cápsula de inducción" : "Cápsula concluida";
    const paso4 = estatus <= 3 ? "Por finalizar" : "Has finalizado";

    const img1 = estatus === 0 ? <img src={registro} id="img-guia"/> : <img src={registro} id="img-guia"/>;
    const img2 = estatus <= 1 ?  <img src={docs} id="img-guia"/>     : <img src={conDocs} id="img-guia"/>;
    const img3 = estatus <= 2 ?  <img src={platica} id="img-guia"/>  : <img src={conPlatica} id="img-guia"/>;
    const img4 = estatus <= 3 ?  <img src={finaliza} id="img-guia"/> : <img src={finalizado} id="img-guia"/>;

    const  pasos = [{"paso" :paso1, "img": img1},
                    {"paso" :paso2, "img": img2},
                    {"paso" :paso3, "img": img3},
                    {"paso" :paso4, "img": img4}];
    
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={6} sm={6} md={3} lg={6}></Col>
          <Col  xs={12} sm={12} md={16} lg={12}>
            <Steps className={classPasoAnterior}
                   size="" current={estatus}
                   status=""
            >
              {pasos.map((paso, index) => {
                return <Step key={index} title=' ' description={paso.paso} icon={paso.img}/>
              })}            
            </Steps>
          </Col>
          <Col xs={12} sm={6} md={4} lg={6}>
            <div id="modificar">
                {this.state.nuevo?
                  <div className="div_edit">
                    <Button type="" icon="edit"
                          style={{width:120}} 
                          htmlType="submit" 
                          disabled={!this.state.permiteModificar}
                          onClick={this.modificar}>
                        Modifica
                    </Button>
                    <br/>
                    <Button type="" icon="download"
                          style={{width:175}} 
                          htmlType="submit" 
                          disabled={!this.state.permiteDescargar}
                          onClick={this.descargarAcuses}>
                        Descarga Acuses
                    </Button>
                  </div>
                  : null}	
                  <br></br><br></br>		
            </div>
          </Col>
        </Row>
        <Modal title={null}
                visible={this.state.modalAbrir}
                centered
                width={347}
                closable={false}
                footer={null}>
          <div style={{ fontSize: '18px', textAlign: 'center'}}>
            {etiquetas.gral_documentos_validados}
          </div>
          <br/>
          <div id="modal_botones">
            <Button className="botonesModal" style={{width: 150}} onClick={this.abrirCerrarModal}> Aceptar </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  var datosAspirante, datosUsuario;

  if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante) {
    datosUsuario = state.sesion.datosUsuario;
    datosAspirante = state.sesion.datosUsuario.datosAspirante;
  }
  else
    datosAspirante = {estatusRegistro: 0};

  return {
    datosUsuario: datosUsuario,
    datosAspirante: datosAspirante,
  };
};

export default withRouter(connect(mapStateToProps)(GuiaEstatus));
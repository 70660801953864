import React from 'react';
import Template from '../interfaz/Template';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import AuthenticatedComponent from '../AuthenticatedComponent';
import {Layout, Row, Col, Form, Button, message, Modal} from 'antd';
import emitirMensaje from '../../redux/actions/emitirMensaje';
import {connect} from 'react-redux';
import moment from 'moment';
import iconoPlaticas from '../../img/icono_platicas.png';
import * as etiquetas from '../../ApplicationResources';
import exitoPlatica from '../../img/info09_Capsula_de_induccion.png';
import errorImgModal from '../../img/error_icon.svg';
import infow_iconModal from '../../img/infow_icon.png';

let msjError = "";
let msjInfoW = "";
const {Footer} = Layout;

class ExitoPlatica extends React.Component {
    state = {
        cargado : false,
        tipoMsj : 'info',
        msj : 'Validación de documentos en proceso.',
        correctas : 0,
        lugarExamen : '',
        fechaExamen : '',
        horaExamen: '',
        muestraSedeExamen : false,
        domicilioJunta : '',
        programacionVisible : false,
        programacionPlaticas : [],
        programacionNombreJunta : [],
        programacionDomicilioJunta : [],
        msjError: "", 
        abrirModalError: false,
        msjInfoW: "",
        abrirModalInfoW: false
    }
    constructor(props) {
        super(props);
        this.aspirante = this.props.getAspirante;
        this.validaBanderaPlatica();
    }

    validaBanderaPlatica = () =>{
            var jSonAspirante = {
                "idDetalleProceso" : this.props.datosAspirante.idDetalleProceso,
                "idAspirante" : this.props.datosAspirante.idAspirante,
                "idParticipacion" : this.props.datosAspirante.idParticipacion
            }

            // Obtenido los datos del servidor
            const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtineAspirante'
                                        , jSonAspirante
                                        , {
                                            headers: {
                                                'Accept': 'application/json'
                                                ,'Content-Type': 'application/json'
                                            }
                                        }
                                    );
            response.then((res)=>{
                console.log('Aspirante' + res.data.entity.aspiranteRes)
                console.log('Correctas: ' + this.props.correctas)
                this.aspirante = res.data.entity.aspiranteRes.aspirante
                this.setState({domicilioJunta : res.data.entity.aspiranteRes.jd})
                if(this.aspirante.bObservacion === 1){
                    this.setState({tipoMsj : 'warning'})
                    this.setState({msj : etiquetas.gral_observacion_documentos})
                } else if (this.aspirante.bObservacion === 0){
                    this.setState({tipoMsj : 'ok'})
                    this.setState({msj : etiquetas.gral_documentos_validados})
                }
                if(this.props.datosUsuario.tipoUsuario === 'I'){
                    this.setState({tipoMsj : ''})
                    this.setState({msj : ''})
                }
                this.props.emitirMensaje(
                    {
                        id: 'mensajeUsuario',
                        tipoMensaje: this.state.tipoMsj,
                        contenido: this.state.msj,
                    }
                )
                if(this.aspirante.idSedeProgramada)
                    this.obtenerSedeExamen();
            })
            return response.data;
    }

    handleDescargarAcuseClick = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcusePlatica'
            ,{
                idProcesoElectoral : this.props.datosAspirante.idProcesoElectoral
                , idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idConvocatoria : this.props.datosAspirante.idConvocatoria
                , folio : this.props.datosAspirante.folio
                , claveElector: this.props.datosAspirante.claveElectorFuar
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `Capsula_Induccion_${this.props.datosAspirante.claveElectorFuar}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(error => {
            console.error("Error en la descarga del acuse de platica", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_descarga_acuse_capsula;
                }
            } else {
                msjError = etiquetas.gral_error_descarga_acuse_capsula;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    obtenerSedeExamen = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/consulta_info_sede'
            ,{
                idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idSede : this.aspirante.idSedeProgramada
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")
                }
            },
        ).then(response => {
            if(response.data){
                this.sede = response.data;
                this.setState(
                    {
                        lugarExamen : this.sede.lugar + ' | ' + this.sede.domicilioSede + ', Col. ' + this.sede.colonia
                            + ', C.P. ' + this.sede.cp + ', ' + this.sede.cabeceraDistritalFederal + ', ' + this.sede.nombreEstado + ' ',
                        fechaExamen : this.aspirante.fechaProgramada ? moment(this.aspirante.fechaProgramada).format("DD [de] MMMM [de] YYYY"):null,
                        muestraSedeExamen : true
                    }
                )
            }
        }).catch(error => {
            console.error("Error al obtener Sede de Examen", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_sede_examen;
                }
            } else {
                msjError = etiquetas.gral_error_sede_examen;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    handleDescargarGuia = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarGuiaExamen'
            ,{
                idProcesoElectoral : this.props.datosAspirante.idProcesoElectoral
                , idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idConvocatoria : this.props.datosAspirante.idConvocatoria
                , folio : this.props.datosAspirante.folio
                , claveElector: this.props.datosAspirante.claveElectorFuar
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `GUIA_DE_ESTUDIO_PE_2020_2021.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(error => {
            console.error("Error al descargar la Guia de estudio", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_guia_estudio;
                }
            } else {
                msjError = etiquetas.gral_error_guia_estudio;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    handleProgramacionClick = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/consulta_programacion_platicas'
            ,{
                idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idSede : this.aspirante.idSedeReclutamiento
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")
                }
            },
        ).then(response => {
            if(response.data){
                this.setState({
                    programacionVisible : true,
                    programacionNombreJunta : response.data[0].nombreJunta,
                    programacionDomicilioJunta : response.data[0].domicilioJunta,
                    programacionPlaticas : response.data
                });
            }
        }).catch(error => {
            console.error("Error al consultar handleProgramacionClick");
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_platicas;
                }
            } else if(error.response === undefined){
                msjInfoW = etiquetas.gral_error_no_hay_platicas;
            } else {
                msjError = etiquetas.gral_error_platicas;
            }
            error.response === undefined ? this.setState({msjInfoW: msjInfoW, abrirModalInfoW: true}) :
                                           this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    handleProgramacionClose = () => {
        this.setState({
            programacionVisible : false
        });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }
    cerrarInfoWModal() {
        this.setState({abrirModalInfoW:false});
    }

    render() {
        const fechaProgramada = this.state.fechaExamen;
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        const domicilioJunta = this.state.domicilioJunta;
        const programacionNombreJunta = this.state.programacionNombreJunta;
        const programacionDomicilioJunta = this.state.programacionDomicilioJunta;
        const programacionFecha = this.state.programacionPlaticas.map(
            (item) => <li className="vinetaContacto">{moment(item.fechaHoraPlatica).format("DD [de] MMMM [de] YYYY")} <hr/></li>
        ) || {};
        const programacionHora = this.state.programacionPlaticas.map(
            (item) => <li className="vinetaContacto">{moment(item.fechaHoraPlatica).format("HH:mm")} hrs <hr/></li>
        ) || {};
        return(
            <AuthenticatedComponent externo={tipoUsuario === 'E'}>
                <Template guia = {tipoUsuario === 'E'} contenido={
                    <div className="exito_container">
                        <div >
                            <img src={exitoPlatica} alt="ExitoPlatica" width="100%" height="50%"/>
                            <br/><br/>
                                <span className='exito_detalle'>Respuestas correctas: {this.props.correctas} </span>
                            
                            {this.state.muestraSedeExamen ?
                                <ul id='indicaciones_finalizado'>
                                    <li>Acude en la fecha y hora indicada para presentar tu examen.</li>
                                    <li>
                                        <span><b>Lugar</b>: {this.state.lugarExamen}</span>
                                        <span><b>Fecha</b>: {fechaProgramada}</span>
                                        <span><b> Hora</b>: {this.aspirante.horaProgramada} hrs</span>
                                    </li>
                                    <br/>
                                    <div style={{'fontSize': '16px'}}>
                                        <span><font color="red"><b>¡Es importante contar con tu presencia 40 minutos antes de la hora citada para dar inicio puntualmente con la aplicación del examen!</b></font></span>
                                    </div>
                                </ul>
                                
                            :null}

                            {tipoUsuario === 'E' ?
                                null
                                :   <Footer>
                                        <Row>
                                            <Col id = 'colBtn' span={8}>
                                                <Button type="primary"
                                                        className="button_style"
                                                        style={{width:170}}
                                                        onClick={() => this.handleDescargarAcuseClick()}>Descargar Acuse
                                                </Button>
                                            </Col>
                                            <Col span={8}></Col>
                                            <Col id = 'colBtn' span={8}>
                                                <Button type="primary"
                                                        className="button_style"
                                                        style={{width:170}}
                                                        onClick={() => this.handleDescargarGuia()}>Descargar Guia
                                                </Button>
                                            </Col>
                                            <Col span={8}></Col>
                                        </Row>
                                    </Footer>
                            }
                                <span className='exito_detalle'>{domicilioJunta}</span>
                            
                                <span className='exito_detalle'>
                                    <a href="#!" onClick={this.handleProgramacionClick} id="linkPlaticas">
                                        Consulta la programación de las pláticas de inducción.
                                    </a>
                                </span>
                            
                                <span className='exito_detalle'>Gracias por tu participación.</span>
                        </div>
                    </div>
                }/>
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.programacionVisible}
                    onOk={this.handleProgramacionClose}
                    onCancel={this.handleProgramacionClose}
                    className="modal_platicas">
                    <div>
                        <h2 style={{'textAlign': 'center'}}><b>Programación de Pláticas de Inducción</b></h2>
                        <ul>
                            <div id="modal_infoSede">
                                <Col xs={4} sm={4} md={2} lg={2}>
                                    <img src={iconoPlaticas} id="icono_platicas"/>
                                </Col>
                                <Col xs={20} sm={8} md={18} lg={18}>
                                    <span id="titulo_junta"><b>{programacionNombreJunta}</b></span>
                                    <br/>
                                    <span className="text_junta">{programacionDomicilioJunta}</span>
                                </Col>
                            </div>
                            <hr></hr>
                            <div className="lstPlaticas">
                                <Col xs={6} sm={6} md={12} lg={12}>
                                    {programacionFecha}
                                </Col>
                                <Col xs={6} sm={6} md={12} lg={12}>
                                    {programacionHora}
                                </Col>
                            </div>
                        </ul>
                    </div>
                </Modal>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
                <Modal className="modalError"
                       title={<img  src={infow_iconModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarInfoWModal()}
                       visible={this.state.abrirModalInfoW}
                       footer={false}
                       width={400}>
                    {this.state.msjInfoW}
                </Modal>
            </AuthenticatedComponent>
        );
    }
};

/*const mapStateToProps = state => {
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario,
        ventanaExito: state.sesion.exito
    }
}*/

const mapStateToProps = state => {
    console.log(state)
    var datosAspirante;
    var correctas;
    var datosUsuario;

    if(state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;

    if(state.operaciones)
        correctas = state.operaciones.correctas;

    return {
        datosAspirante: datosAspirante,
        correctas : correctas,
        datosUsuario : datosUsuario,
    };
}
const frm = connect(mapStateToProps,  {emitirMensaje})(ExitoPlatica)
export default withRouter(Form.create({ name: 'Cuestionario' })(frm));

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Icon, Tabs, Row, Button, Modal} from "antd";
import Formulario from "./FormBusquedaBandeja";
import ComponentTablaBandeja from "./TablaBandeja.js";
import ComponentTablaBandejaBusqueda from "./TablaBandejaBusqueda.js";
import AuthenticatedComponent from "./../../AuthenticatedComponent";
import Template from "./../../interfaz/Template.js";
import * as etiquetas from '../../../ApplicationResources';
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";
import '../../../css/modelStyle.css';
const { TabPane } = Tabs;

function Bandeja({ history }) {

  // Utilizar useState con 3 states
  const [bandejaSeleccionada, actualizaBandeja] = useState(1);
  const [tabla, agregartabla] = useState({});
  const [bandera, cambiarBandera] = useState({ bandera: true });
  const [cargando, cambiarCargando] = useState({ cargando: true });
  const [datosSesion, sesionData] = useState(false);
  const [tipoUsuario, establecerTipoUsuario] = useState(0);
  const [rolCaptura, establecerRolCaptura] = useState(false);
  const [avisoUsuarioNovalidoVisible, cambiarAvisoUsuarioNovalidoVisible] = useState({ avisoUsuarioNovalidoVisible : false });
  const datosAspirante = useSelector(state => state);
  const roles =
  [ "ELECINE.ADMIN.OC",
  "ELECINE.CAU.OC",
  "ELECINE.CAPTURA.OC",
  "ELECINE.CAPTURA.JL",
  "ELECINE.CAPTURA.JD",
  "ELECINE.CAPTURA_VECEYEC.JD",
  "ELECINE.CAPTURA_VECEYEC.JL",
  "ELECINE.CAPTURA_VE.JL",
  "ELECINE.CAPTURA_VE.JD",
  "ELECINE.CAPTURA_CONSEJERO.JL",
  "ELECINE.CONSEJERO.JD"  ,"ELECINE.CONSULTA_EXT.REST.OC", "ELECINE.CONSULTA_EXT.REST.JL", "ELECINE.CONSULTA_EXT.REST.JD"
];

  const funcionListo = () => {
    console.log(datosAspirante.sesion.datosUsuario.rolUsuario);
    if(!roles.includes(datosAspirante.sesion.datosUsuario.rolUsuario)){
        cambiarAvisoUsuarioNovalidoVisible({ avisoUsuarioNovalidoVisible: true}); // No se permite la entrada del usuario
    }
    sesionData(true);
    consultar();
  };

  const buscarAspirante = async busqueda => {
    const { claveElector } = busqueda;
    const {idProcesoElectoral,idDetalle,idEstado,idDistrito} = datosAspirante.sesion.datosUsuario;
    if(idEstado === 0){
      establecerTipoUsuario(1); //OC
    }else{
      if(idDistrito === 0){
        establecerTipoUsuario(2); //JL
      }else{
        establecerTipoUsuario(3); //JD
      }
    }
    var postData = {
      idProcesoElectoral: idProcesoElectoral,
      idDetalleProceso: idDetalle,
      idEstado: idEstado,
      idDistrito: idDistrito,
      claveElector: claveElector
    };
    const jwt = localStorage.getItem("jwt-token");
    const resultado = await axios.post(
      process.env.REACT_APP_API_URL +
        "/centralReclutaSEyCAE/ws/buscarAspirante",
      postData,
      { headers: { Authorization: jwt } }
    );
    agregartabla({});
    agregartabla(resultado.data.tablaBandeja);
    cambiarBandera({ bandera: false });
    actualizaBandeja("6");
  };

  function actualizaTab(key) {
    actualizaBandeja(key);
  }

  const consultar = async () => {

    var text="abcABC983";
    text="1111888885555599947";
    if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(text)){
      console.log("text solo digitos:"+text);
    }
    const { idProcesoElectoral, idDetalle, idEstado,  idDistrito, rolUsuario } = datosAspirante.sesion.datosUsuario;
    if(idEstado === 0){
      establecerTipoUsuario(1); //OC
    }else{
      if(idDistrito === 0){
        establecerTipoUsuario(2); //JL
      }else{
        establecerTipoUsuario(3); //JD
      }
    }
    if (localStorage.estadoBandeja === undefined) {
      const estadoBandeja = {
        bandejaSeleccionada: bandejaSeleccionada,
        tabla: tabla
      };
      localStorage.setItem("estadoBandeja", estadoBandeja);
    }

    var postData = {
      bandejaSeleccionada: bandejaSeleccionada,
      idProcesoElectoral: idProcesoElectoral,
      idDetalleProceso: idDetalle,
      idEstado: idEstado,
      idDistrito: idDistrito
    };
    const jwt = localStorage.getItem("jwt-token");
    if (bandejaSeleccionada < 6) {
      cambiarCargando({cargando : true});
      const resultado = await axios.post(
        process.env.REACT_APP_API_URL +
          "/centralReclutaSEyCAE/ws/obtenInformacionBandeja",
        postData,
        { headers: { Authorization: jwt } }
      );
      cambiarCargando({cargando : false});
      cambiarBandera({ bandera: true });
      agregartabla({});
      agregartabla(resultado.data.tablaBandeja);
      const estadoBandeja = {
        bandejaSeleccionada: bandejaSeleccionada,
        tabla: tabla
      };
      localStorage.setItem("estadoBandeja", estadoBandeja);
    }
    establecerRolCaptura(false);
     if(roles.includes(rolUsuario)){
        establecerRolCaptura(true);
     }
  };

  const handleAvisoUsuarioNovalidoClose = async () => {
    cambiarAvisoUsuarioNovalidoVisible({ avisoUsuarioNovalidoVisible : false });
    history.push('/menuJDE');
  };

  /* Uso de efectos */
  useEffect(() => {
    if (datosSesion === true) consultar();
  }, [bandejaSeleccionada]);
  useEffect(() => {}, [bandera, cargando, avisoUsuarioNovalidoVisible]);
  
  const resultadoValidar        = "Resultados de ciudadanos por validar";
  const resultadoObservaciones  = "Resultados de ciudadanos con observaciones";
  const resultadoAcudenJDE      = "Resultados de ciudadanos que deben acudir a JDE";
  const resultadoDeclinados     = "Resultados de ciudadanos con solicitud declinada";
  const resultadoFinalizados    = "Resultados de ciudadanos con solicitud finalizada";

  /*Dibuja tabla*/
  return (
    <AuthenticatedComponent listo={funcionListo}>
      <Template
        contenido={
          <div className="contenedor">
              <Link to={`/menuJDE`}className="btn btn-success mr-2">
                <Icon type="arrow-left" /> Regresar
                </Link>
            <div className="contenedor">
              <Row>
                <Formulario buscarAspirante={buscarAspirante} />
              </Row>
            </div>
            <Row>
              <Tabs activeKey={`${bandejaSeleccionada}`}  onChange={actualizaTab} >
                <TabPane tab="Por validar" key="1">
                  <ComponentTablaBandeja datos={tabla.datos} tipoUsuario={tipoUsuario} banderaVisualizar={bandera.bandera} tituloTabla={resultadoValidar}       rolCaptura={rolCaptura} keyTabPane="1" />
                </TabPane>
                <TabPane tab="Con observaciones" key="2">
                  <ComponentTablaBandeja datos={tabla.datos} tipoUsuario={tipoUsuario} banderaVisualizar={bandera.bandera} tituloTabla={resultadoObservaciones} rolCaptura={rolCaptura} keyTabPane="2" />
                </TabPane>
                <TabPane tab="Ciudadanos que acuden a la JDE" key="3">
                  <ComponentTablaBandeja datos={tabla.datos}  tipoUsuario={tipoUsuario} banderaVisualizar={bandera.bandera} tituloTabla={resultadoAcudenJDE}    rolCaptura={rolCaptura} keyTabPane="3" />
                </TabPane>
                <TabPane tab="Solicitud declinada" key="4">
                  <ComponentTablaBandeja datos={tabla.datos} tipoUsuario={tipoUsuario} banderaVisualizar={bandera.bandera} tituloTabla={resultadoDeclinados}    rolCaptura={rolCaptura} keyTabPane="4"/>
                </TabPane>
                <TabPane tab="Finalizados" key="5">
                  <ComponentTablaBandeja datos={tabla.datos} tipoUsuario={tipoUsuario} banderaVisualizar={bandera.bandera}  tituloTabla={resultadoFinalizados}  rolCaptura={rolCaptura} keyTabPane="5" />
                </TabPane>
              </Tabs>
            </Row>
            {
              <Row>
                <ComponentTablaBandejaBusqueda
                  datos={tabla.datosBusqueda}
                  tipoUsuario={tipoUsuario}
                  banderaVisualizar={bandera.bandera}
                  keyTabPane="6"
                  rol={datosAspirante.sesion.datosUsuario} />
              </Row>
            }
          </div>
        }
        bloqueado={false}
        guia={false}
        cargando={cargando.cargando}
      >
      </Template>
      <Modal
          title={null}
          footer={null}
          centered={true}
          visible={avisoUsuarioNovalidoVisible.avisoUsuarioNovalidoVisible}
          onOk={handleAvisoUsuarioNovalidoClose}
          onCancel={handleAvisoUsuarioNovalidoClose}>
        <div>
          <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
          <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
        </div>
      </Modal>
    </AuthenticatedComponent>
  );
}

export default withRouter(Bandeja);

import React, {Component} from 'react'
import { Row, Col, Icon, Button, message, Checkbox, Checkbox as CheckboxANTD, Modal} from 'antd';
import { Link } from "react-router-dom";
import axios from 'axios';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import errorImgModal from '../../img/error_icon.svg';
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';

let msjError = "";
const rolesCaptura = ['ELECINE.ADMIN.OC', 'ELECINE.CAU.OC', 'ELECINE.CAPTURA.JD', 'ELECINE.CAPTURA.JL', 'ELECINE.CAPTURA.OC', 'ELECINE.CAPTURA_CONSEJERO.JL', 'ELECINE.CAPTURA_VE.JD', 'ELECINE.CAPTURA_VE.JL', 'ELECINE.CAPTURA_VECEYEC.JD', 'ELECINE.CAPTURA_VECEYEC.JL', 'ELECINE.CONSEJERO.JD'];

class ReenvioAcuses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            datosAspirante : this.props.datosAspirante || {},
            enviar: false,
            checkedValues: null,
            modalReenvioOk: false,
            cargando: false,
            acuses: [], 
            msjError: "",
            abrirModalError: false,
            abrirModalPermisos: false
        };   
    }
    componentWillMount=() =>{
        this.setState({modalReenvioOk: false});
    }

    listo = () => {
        const datosAspirante = this.props.datosAspirante;

        if (!datosAspirante) {
            this.props.history.push('/bandeja');
            return;
        }
        this.asignaNotificaciones(datosAspirante);

        if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario)){
            this.setState({abrirModalPermisos: true})
        }

        if(datosAspirante.correo === null){
            msjError = etiquetas.gral_error_no_hay_correo;
            this.setState({msjError: msjError, abrirModalError: true});
        }
    }

    asignaNotificaciones = (datosAspirante) =>{
        var acusesNotifica = [];
        var estatusRegistro = datosAspirante.estatusRegistro;

        //Todos los estatus cuentan con la validacion de Correo
        if(estatusRegistro === 1 || estatusRegistro === 2 || estatusRegistro === 3 || estatusRegistro === 4 && datosAspirante.bAcudirJDE === 0){
            acusesNotifica.push({value: 1 , label:'Validación de Correo'});
        }
        //Aspirante con documentos cargados
        if((estatusRegistro === 2 || estatusRegistro === 3 || estatusRegistro === 4) && datosAspirante.bPlatica === null 
            && (datosAspirante.bAcudirJDE === 0 || datosAspirante.bAcudirJDE === 1)){
            acusesNotifica.push({value: 2 , label:'Recepción de Documentos'});
        }
        //Por validar, Finalizado y Declinado o AcudirJDE por inconsistencias en documentacion
        if((estatusRegistro === 2 || estatusRegistro === 3 || estatusRegistro === 4) && datosAspirante.bPlatica === 0 && (datosAspirante.bAcudirJDE === 0 || datosAspirante.bAcudirJDE === 1)){
            acusesNotifica.push({value: 2 , label:'Recepción de Documentos'}, {value: 3 , label:'Notificación de Plática de Inducción'});
        }
        //Declinado
        if(estatusRegistro === 4 && datosAspirante.bObservacion === 1 && datosAspirante.bAcudirJDE === 0){
            acusesNotifica.push({value: 4 , label:'Solicitud Declinada'});
        }
        //Finalizado
        if(((estatusRegistro === 2 && datosAspirante.bPlatica === null) || (estatusRegistro === 3 && datosAspirante.bPlatica === 0))
            && datosAspirante.bObservacion === 0 && datosAspirante.bAcudirJDE === 0){
            acusesNotifica.push({value: 5 , label:'Finalizado'});
        }
        this.setState({acuses: acusesNotifica});
    }

    onChange(checkedValues) {
        console.log('checked = ', checkedValues);
        this.setState({checkedValues});
        this.setState({enviar:checkedValues});
    }

    reenviarNotificaciones=()=>{
        this.setState({cargando: true});
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/reenviarNotificaciones', {
            idProcesoElectoral : this.state.datosAspirante.idProcesoElectoral
            , idDetalleProceso : this.state.datosAspirante.idDetalleProceso
            , idParticipacion : this.state.datosAspirante.idParticipacion
            , idConvocatoria : this.state.datosAspirante.idConvocatoria
            , folioPrincipal : this.state.datosAspirante.folio
            , folioAcceso : this.state.datosAspirante.folioAcceso
            , claveElectorFuar: this.state.datosAspirante.claveElectorFuar
            , correoElectronico: this.state.datosAspirante.correo
            , idSedeReclutamiento: this.state.datosAspirante.idSedeReclutamiento
            , checkedValues: this.state.checkedValues
        },{
            headers: {
                'Content-Type': 'application/json'
                , 'Authorization': localStorage.getItem("jwt-token")        
            }
        }).then(res => {
            this.setState({cargando: false});
            this.setState({modalReenvioOk: true});
            console.log('Se envio notificaciones');
        }).catch(error => {
            console.error("Error al reenviar los acuses", error);
            this.setState({cargando: false});
            msjError = etiquetas.gral_error_reenvio;
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    aceptar= () =>{
        this.setState({modalReenvioOk: false})
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
        if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario) || this.props.datosAspirante.correo === null){
            this.props.history.push('/bandeja');
        }
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};

        const Acuses = this.state.acuses && this.state.acuses.map((opcion, index) => (
                        <Col span={20} offset={4} key={index}>
                            <CheckboxANTD className='container_reenvioCheck' 
                                          value={opcion.value}>{opcion.label}
                            </CheckboxANTD>
                        </Col>
                    )) || {};

        return (
            <AuthenticatedComponent externo={false} listo={this.listo}>
            <Template contenido={
                <div className="contenedorReenvio">
                    <Link to={`/bandeja`}className="btn btn-success mr-2">
                        <Icon type="arrow-left" /> Regresar
                    </Link>
                    <div className="container_reenvio">
                        <div>
                            <div style={{textAlign:'center'}}>
                            <span className='exito_titulo' style={{fontSize: '2em'}}><b> Reenvio de Acuses </b></span> 
                            </div>
                            <br/>
                            <div>
                                <span className='subtitulo_cargaDocumentos'><b>NOMBRE: </b></span>
                                <span className='subtitulo_cargaDocumentos'>{datosAspirante.nombre +" "+ datosAspirante.apellidoPaterno +" "+ datosAspirante.apellidoMaterno}</span>
                            </div>
                            <br/>
                            <div>
                                <span className='subtitulo_cargaDocumentos'><b>CLAVE DE ELECTOR: </b></span>
                                <span className='subtitulo_cargaDocumentos'>{datosAspirante.claveElectorFuar}</span>
                            </div>
                            <br/><hr/><br/>
                            
                            <center>
                                <div>
                                    <span className='subtitulo_cargaDocumentos'>Selecciona el o los acuses que requieras reenviar. </span>
                                    <br/><br/>
                                </div>
                                <div>
                                    <Checkbox.Group rules={[{type: 'array'}]}
                                                    style={{ width: '100%'}}
                                                    onChange={(value) => this.onChange(value)}>
                                    <Row style={{textAlign:'center'}}>
                                                {Acuses}
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                                <br/>
                                <Button type="" icon="mail" id="boton-resend"
                                        className="button_style"
                                        style={{width:150}} 
                                        htmlType="submit" 
                                        disabled={!this.state.enviar}
                                        onClick={()=>this.reenviarNotificaciones()}>
                                            Enviar
                                </Button>
                            </center>
                            <br/>
                        </div>
                    </div>                
                </div>
            }
            guia={false}
            cargando={this.state.cargando}
            />
                <Modal
                    visible={this.state.modalReenvioOk}
                    title={null}
                    onOk={()=>this.aceptar()}
                    onCancel={()=>this.aceptar()}
                    footer={null}
                >
                    <p style={{fontSize: '20px'}}>Los acuses han sido enviados con éxito </p>
                </Modal>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.abrirModalPermisos}
                    onOk={()=>this.cerrarErrorModal()}
                    onCancel={()=>this.cerrarErrorModal()}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
                        <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
                    </div>
                </Modal>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante, datosUsuario;

    if (state.sesion.datosUsuario) {
        datosUsuario = state.sesion.datosUsuario;
        datosAspirante = datosUsuario.datosAspirante;
    }

    return {
        datosUsuario: datosUsuario,
        datosAspirante: datosAspirante,
    };
}

export default connect(mapStateToProps)(ReenvioAcuses);
import React from 'react';
import {Card} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import ExperienciaLaboralItem from './ExperienciaLaboralItem'

class ExperienciaLaboral extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = { };

    }

    render() {
        return(
           <React.Fragment>

                <Card title={etiquetas.datos_experiencia_laboral_lbl_titulo} bordered={false}>
                  <div style={{'font-weight':'bold','font-size': '13px'}}>
                    {etiquetas.general_experiencia_laboral}
                  </div>
                    <ExperienciaLaboralItem
                        form={this.props.form}
                        nombreEmpresa={'nombreEmpresa1'}
                        puestoEmpresa={'puestoEmpresa1'}
                        fechaInicioEmpresa={'fechaInicioEmpresa1'}
                        fechaFinEmpresa={'fechaFinEmpresa1'}
                        telefonoEmpresa={'telefonoEmpresa1'}
                        style={{'margin-top':'-6px'}}/>
                    <hr style={{margin: '0px'}} />
                    <ExperienciaLaboralItem
                        form={this.props.form}
                        nombreEmpresa={'nombreEmpresa2'}
                        puestoEmpresa={'puestoEmpresa2'}
                        fechaInicioEmpresa={'fechaInicioEmpresa2'}
                        fechaFinEmpresa={'fechaFinEmpresa2'}
                        telefonoEmpresa={'telefonoEmpresa2'} />
                    <hr style={{margin: '0px'}} />
                    <ExperienciaLaboralItem
                        form={this.props.form}
                        nombreEmpresa={'nombreEmpresa3'}
                        puestoEmpresa={'puestoEmpresa3'}
                        fechaInicioEmpresa={'fechaInicioEmpresa3'}
                        fechaFinEmpresa={'fechaFinEmpresa3'}
                        telefonoEmpresa={'telefonoEmpresa3'} />
                    <hr style={{margin: '0px'}} />

                </Card>

           </React.Fragment>
        );
    }
};

export default ExperienciaLaboral;

import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Input, Button, Table,Popover, Tooltip, Popconfirm} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import ayudaClaveFUAR from '../../../img/ayudaCambioClave.jpg';
import icono_admin from '../../../img/icono_admin.svg';
import * as etiquetas from '../../../ApplicationResources';
import { cargarAspirante } from "../../../utils/funciones";
import { implantarAspirante } from "../../../redux/actions/accionesSesion";
import { emitirOperacion } from "../../../redux/actions/accionesOperacion";
import { connect } from "react-redux";
const { Column } = Table;

/* Este componente es la tabla que muestra el listado  de busqueda en la  bandeja de entrada*/
const rolesAdmin =  [ "ELECINE.ADMIN.OC", "ELECINE.UNICOM.OC", "ELECINE.CAPTURA.OC", "ELECINE.CAPTURA.JD", "ELECINE.CAPTURA_VECEYEC.JD" ];

function TablaBandejaBusqueda({
  datos,
  banderaVisualizar,
  keyTabPane,
  tipoUsuario,
  implantarAspirante,
  history,
  emitirOperacion,
  rol,
}) {
  //State que se usa
  const [setState] = useState({ searchText: "" });
  /*Metodos para el manejo de tablas */

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ searchText: selectedKeys[0] });
  };

  var handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: "" });
  };

  const functionFilterIcon = filtered => (
    <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
  );

  const functionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar `}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
      );
    };

  const functionFilterDropdownA = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
    }) => {
    return (
      <Popover overlayClassName='pop_tooltip'
          disabled={false}
          content={<img src={ayudaClaveFUAR} width='500px'/>}>
            <marginRight>
            <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
            </marginRight>
        </Popover>
    );
  };

  const functionOnFilterDropdownVisibleChange = visible => {};

  const functionRender = text => {

    return (
      text+""
    );
  };

  const etiquetasExpediente = (text, record) => (
    <Tooltip title={"Visor"}
              placement="top">
      <span>
      <Link
        className="btn btn-success mr-2"
        onClick={() =>
          cargarAspirante(record.folioAcceso).then(datosAspirante => {
            emitirOperacion({
              accion: "captura"
            });
            implantarAspirante(datosAspirante);
            history.push("/visor");
          })
        }
      >
        <Icon
          type="eye"
          theme="outlined"
          style={{ fontSize: "16px", color: "#08c" }}
        />



      </Link>
    </span>

    </Tooltip>
  );

  const etiquetasSolicitud = (text, record) => (

    <Tooltip title={"Descargar solicitud"}
              placement="bottom">
      <Popconfirm
          title={<p><b>Responsiva de Acceso a Información</b><br/>
                Reconozco que la información sensible, reservada o confidencial<br/>
                 a la que tendré acceso, no podré utilizarla, difundirla,<br/>
                 reproducirla, publicarla, cederla y/o usarla con fines<br/>
                 lucrativos, personales o comerciales o en beneficio de terceros.<br/>
                  Artículos 3, 110 y 113 de la Ley Federal de Transparencia y<br/>
                 Acceso a la Información Pública 14 y 15 del Reglamento del<br/>
                 Instituto Nacional Electoral en materia de Transparencia y<br/>
                 Acceso a la Información Pública.
               </p>}
          onConfirm={() =>
            cargarAspirante(record.folioAcceso).then(datosAspirante => {
              emitirOperacion({
                accion: "captura"
              });
              implantarAspirante(datosAspirante);

              axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarSolicitud'
                  ,{
                    idProcesoElectoral: datosAspirante.idProcesoElectoral,
                    idDetalleProceso: datosAspirante.idDetalleProceso,
                    idParticipacion: datosAspirante.idParticipacion,
                    idAspirante: datosAspirante.idAspirante,
                    idConvocatoria : datosAspirante.idConvocatoria,
                    folio : datosAspirante.folio,
                    claveElector: datosAspirante.claveElectorFuar,
                    usuario: null,
                    ipUsuario: null,
                    sede: datosAspirante.sede
                  },{
                      responseType: 'blob',
                      transformResponse: [ data => {
                      let blob = new window.Blob([data], {type: "application/pdf"});
                      var link = document.createElement('a');
                      link.href = window.URL.createObjectURL(blob);
                      link.setAttribute('download', `${datosAspirante.claveElectorFuar}.pdf`);
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                  }]
                },
              ).then(res => {
                console.log("respuesta...",res);
              })
            })
          }
          onCancel={()=>console.log("no acepto la descarga")}
          okText="Aceptar"
          cancelText="Declinar"
        >
      <span>
        <Icon
          type="file"
          theme="outlined"
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </span>
    </Popconfirm>
    </Tooltip>
  );

  const etiquetaReenvioAcuses =(text, record) =>(
    <Tooltip title={"Reenviar Acuses"} placement="top">
      <span>
        <Link
          className="btn btn-success mr-2"
          onClick={() =>
            cargarAspirante(record.folioAcceso).then(datosAspirante => {
              emitirOperacion({
                accion: keyTabPane === "1" ? "captura" : "consulta"
              });
              implantarAspirante(datosAspirante);

              history.push("/reenvioAcuses");
            })
          }
        >
          <Icon
            type="mail" theme="outlined"
            style={{ fontSize: "16px", color: "#08c" }}
          />
        </Link>
      </span>
    </Tooltip>
  );

  const etiquetaDescargaCompulsa = (text, record) => {
    var esClaveElectorFuar = etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(record.claveElector);
    return (
    !esClaveElectorFuar ? 
    <Tooltip title={"Compulsa"} placement="top">
      <span>
        <Link className="btn btn-success mr-2" disabled={esClaveElectorFuar}
              onClick={() =>
                cargarAspirante(record.folioAcceso).then(datosAspirante => {
                  implantarAspirante(datosAspirante);

                  axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse', {
                      idProcesoElectoral : datosAspirante.idProcesoElectoral,
                      idDetalleProceso : datosAspirante.idDetalleProceso,
                      idParticipacion : datosAspirante.idParticipacion,
                      idAspirante: datosAspirante.idAspirante,
                      idConvocatoria : datosAspirante.idConvocatoria,
                      folio : datosAspirante.folio,
                      claveElector: datosAspirante.claveElectorFuar
                  },{
                      responseType: 'blob',
                      transformResponse: [ data => {
                        let blob = new window.Blob([data], {type: "application/pdf"});
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.setAttribute('download', `Compulsa_${datosAspirante.claveElectorFuar}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }]
                  }).then(res => {})
                })
              }
        > <Icon type="download" theme="outlined" style={{fontSize: "16px", color: "#08c"}}/> 
        </Link>
      </span>
    </Tooltip>:
    <Tooltip title={"Compulsa"} placement="top">
      <Link className="btn btn-success mr-2"> 
        <Icon type="download" theme="outlined" style={{fontSize: "16px", color: "#bfbfbf"}}/> 
      </Link>
    </Tooltip>
    )};

    const etiquetaAdmin =(text, record) =>(
      <Tooltip title={"Administrador"} placement="top">
        <span>
          <Link
            className="btn btn-success mr-2"
            onClick={() =>
              cargarAspirante(record.folioAcceso).then(datosAspirante => {
                implantarAspirante(datosAspirante);
                history.push("/administrarRegistro");
              })
            }
          >
            <img src={icono_admin} width="16px"/>
          </Link>
        </span>
      </Tooltip>
    );

  const OC = 1;
  const JL = 2;

  /*   Pintar la tabla     */
  return (
    <Fragment>
       {banderaVisualizar === false ? (
          <Table
            dataSource={datos}
            title={() => "Resultados de búsqueda"}
            scroll={{x:'max-content'}}
          >{
            console.log(datos)}
            {tipoUsuario === OC?
              <Column
              title="Estado" dataIndex="estado"  key={`estado{keyTabPane}`}
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["estado"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />:<span></span>
          }

          {tipoUsuario === OC || tipoUsuario === JL?
            <Column
            title="Distrito" dataIndex="distrito"  key={`distrito{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["distrito"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
            :<span></span>}
            <Column title="Folio" dataIndex="folio" key="folio"
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["folio"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column title="Nombre" dataIndex="nombre" key="nombre"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["nombre"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }} />
            <Column
              title="Clave de elector o FUAR"
              dataIndex="claveElector"
              key="claveElector"
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["claveElector"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column
              title="Convocatoria"
              dataIndex="numeroConvocatoria"
              key="numeroConvocatoria"
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["numeroConvocatoria"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column
              align={'right'}
              title={functionFilterDropdownA}
              key="action"
              render={(text, record) => (
                <Tooltip title={"Modificar"}
                          placement="top">
                <span>
                  <Link to={`/bandeja/modifica/${record.folioAcceso}&${record.claveElector}`}
                        className="btn btn-success mr-2">
                    <Icon 
                      type="edit"
                      theme="outlined"
                      style={{ fontSize: "16px", color: "#08c" }}
                    />
                  </Link>
                </span>
                </Tooltip>
              )}
            />
            <Column align={'right'} title="" key="exp" render={etiquetasExpediente} />
            {
              rol.rolUsuario === "ELECINE.CAPTURA_VECEYEC.JD" ?
              <Column align={'right'} title="" key="exp" render={etiquetasSolicitud} />
              : ""
            }
            <Column align={'right'} title="" key="exp" render={etiquetaReenvioAcuses}/>
            {tipoUsuario === OC ?
              <Column align={'right'} title="" key="exp" render={etiquetaDescargaCompulsa} />
            : null}
            {rolesAdmin.includes(rol.rolUsuario) ?
              <Column align={'right'} title="" key="exp" render={etiquetaAdmin} />
            : null}
          </Table>
        ) : (
          <span></span>
        )}
    </Fragment>
  );
}


export default withRouter(
  connect(
    _ => {
      return {};
    },
    { implantarAspirante,emitirOperacion}
  )(TablaBandejaBusqueda)
);

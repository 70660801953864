import React, {Component} from 'react'
import {Icon, Button} from 'antd';
import Template from './interfaz/Template';
import axios from 'axios';
import {cambiarEstatus} from '../redux/actions/accionesSesion';
import {connect} from 'react-redux';
import validaCorreo from '../img/info02_Correo_validado.png'; 

export class ValidaCorreo extends Component{

    state = {
        cargado: false,
        folioAcceso: undefined,
        claveElectorFuar: undefined,
    } 
    
    componentDidMount(){
        const v = window.location.search.slice(3);
        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/validaAspirante', {
            valor: v, 
        }
        ).then(res => {
            const entidad = res.data.entity;

            this.setState({folioAcceso: entidad.data.folioAcceso,
                           claveElectorFuar: entidad.data.claveElectorFuar,
                           cargado: true});
            this.props.cambiarEstatus(1);
        });
    }

    render(){
        return(
            <Template contenido={  
                <div className="exito_container">
                    <div>
                        <img src={validaCorreo} alt="ValidaCorreo" width="100%" height="60%"/>
                        <div className='exito_informacion'>
                            {/*<div>Ahora, inicia sesión con el usuario y contraseña proporcionado para continuar con el proceso</div>*/}
                            <div className='usu_contr'>
                                <div><span>Usuario: </span>{this.state.folioAcceso}</div>
                                <div><span>Contraseña: </span>{this.state.claveElectorFuar}</div>
                            </div>
                        </div>
                        <span id='inicia_sesion'><Button onClick={() => this.props.history.push('/acceso')}>Inicia sesión</Button></span>
                        <p/>
                    </div>
                </div>
            }
            bloqueado={!this.state.cargado}/>
        )
    } 
}

export default connect((_state) => {return {}}, {cambiarEstatus})(ValidaCorreo);

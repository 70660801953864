import React, {Component} from 'react'
import { Form, Icon, Row, Col , Button, Tooltip, Modal, Popover} from 'antd';
import Template from '../interfaz/Template';
import axios from 'axios';
import AuthenticatedComponent from '../AuthenticatedComponent'
import FileUpload from '../interfaz/FileUpload'
import {connect} from 'react-redux'
import {cambiarEstatus,registrarTerminado,implantarAspirante} from '../../redux/actions/accionesSesion'
import {irAtras} from '../../utils/funciones'
import emitirMensaje from '../../redux/actions/emitirMensaje'
import ayudaClaveElector from '../../img/Carga_tus_documentos_V1.gif'
import ayudaINEFrente from '../../img/Adjunta_credencial_frente.gif'
import ayudaINEReverso from '../../img/Adjunta_credencial_reverso.gif'
import ayudaINEAmbos from '../../img/Adjunta_credencial_ambosLados.gif'
import errorImgModal from '../../img/error_icon.svg';
import * as etiquetas from '../../ApplicationResources';
import ProgresoCargaDocs from '../interfaz/ProgresoCargaDocs';

import { Link } from "react-router-dom";

let msjError = "";

export class CargaDocumentos extends Component{
    state = {
         cargado: false,
         cargando: false,
         fileLists:[],
         guardar:false,
         resCompulsado: false,
         mensajeError: '',
         porcentaje: 0,
         megas: 0,
         msjError: "",
         abrirModalError: false
    }

    metadatosArchivos = [];
    irAtras = irAtras.bind(this);

    changeList = (func, datosArchivo) => {
		this.setState(state => {
            const nuevosArchivos = func(state.fileLists[datosArchivo.indice]);
            if(nuevosArchivos.length>0){
                const repetido = state.fileLists.some(valor=>{return valor.length>0 && valor[0].name === nuevosArchivos[0].name})||
                    this.listaDocumentos.some(valor => ((valor.archivoValido !== 'N') && nuevosArchivos[0].name === valor.nombreArchivo));
                
                if(repetido){
                    datosArchivo.archivo = undefined;
                    this.progressload(0);
                    msjError = 'El archivo ' + nuevosArchivos[0].name + ' se encuentra previamente especificado';
                    this.setState({msjError: msjError, abrirModalError: true});
                    return state.fileLists;
                }

                const repetidoReemplazar = state.fileLists.some(valor=>{return valor.length>0 && valor[0].name === nuevosArchivos[0].name})||
                    this.listaDocumentos.some(valor => ((valor.archivoValido === 'N') && nuevosArchivos[0].name === valor.nombreArchivo));
                if(repetidoReemplazar){
                    datosArchivo.archivo = undefined;
                    this.progressload(0);
                    msjError = 'El nombre de los archivos no debe ser igual a los ya adjuntados.';
                    this.setState({msjError: msjError, abrirModalError: true});
                    return state.fileLists;
                }
            }
            const copia = state.fileLists.slice();
            copia[datosArchivo.indice] = nuevosArchivos;

            this.iniciaIndices(datosArchivo.indice, nuevosArchivos.length>0);

            datosArchivo.cumplido = copia[datosArchivo.indice].length>0;
            if(datosArchivo.archivoValido !== null && datosArchivo.cumplido === true)
                this.changeLoad(datosArchivo);
            if(nuevosArchivos.length === 0){
                const tamanioArchivo = datosArchivo.size/1024/1024;
                this.progressload(tamanioArchivo, 1)
            }

            const guardar =!this.metadatosArchivos.some(valor=>{return (valor.obligatorio=== 'S' || valor.archivoValido === 'N') &&
                                                                      !valor.cumplido && !valor.disabled})

			return {fileLists: copia, guardar:guardar};
        });
    }

    componentWillUnmount() {
        this.props.emitirMensaje(
            {
                id: 'mensajeUsuario',
                tipoMensaje: '',
                contenido: '',
            }
        )
    }

    validacion=(archivo)=>{

        const permitido = archivo.type === 'image/jpeg' || archivo.type === 'image/png' || archivo.type === 'application/pdf';
        if (!permitido) {
            msjError = 'El archivo ' + archivo.name + ' es de formato no permitido'+ etiquetas.gral_archivos_permitidos;
            this.setState({msjError: msjError, abrirModalError: true});
            return false;
        }
        
        const tamanioPermitido = archivo.size / 1024 / 1024 < 1.5; //15
        const archivoVacio = archivo.size === 0;
        if (!tamanioPermitido) {
            msjError = 'El archivo ' + archivo.name + etiquetas.gral_peso_archivo;
            this.setState({msjError: msjError, abrirModalError: true});
            return false;
        }
    
        if(archivoVacio){
            msjError = 'El archivo ' + archivo.name + ' está vacío';
            this.setState({msjError: msjError, abrirModalError: true});
        }
        const nombreLargo = archivo.name.length > 100;
        if(nombreLargo){
            msjError = 'El nombre del archivo ' + archivo.name + ' es más largo de lo permitido';
            this.setState({msjError: msjError, abrirModalError: true});
            return false;
        }

        console.log("*****VALOR ARCHIVO EN VALIDACION ****");
        console.log(archivo);
        const archNombrePermitido = this.validaNombreArchivo(archivo.name);
        if(!archNombrePermitido){
            this.progressload(0,0);
            msjError = 'El nombre de archivo '+ archivo.name + ' es inválido. '+ etiquetas.gral_error_nombre_archivo;
            this.setState({msjError: msjError, abrirModalError: true});
        }else {
            if(this.props.datosAspirante.bObservacion === 1){
                this.differenceLoad(0, archivo.size);}
            else{
                this.progressload((archivo.size/1024/1024), 0);}
        }

        return permitido && tamanioPermitido && archNombrePermitido && !archivoVacio && !nombreLargo
    }
    
    validaNombreArchivo=(e) =>{
        var patron = /^[0-9a-zA-Z_-\s]+.[pdf/PDF/jpg/JPG/jpeg/JPEG/png/PNG]+$/;
        if(e.match(patron)){
            return true;
        }else{
            return false;
        }
    }

    changeLoad = (datosArchivo) => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtenerPropsArchivos', {
            idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
            idDetalleProceso: this.props.datosAspirante.idDetalleProceso,
            idParticipacion: this.props.datosAspirante.idParticipacion,
            idConvocatoria: this.props.datosAspirante.idConvocatoria,
            folio: this.props.datosAspirante.folio,
            claveElectorFuar: this.props.datosAspirante.claveElectorFuar,
            idAspirante: this.props.datosAspirante.idAspirante,
            idDocumento: datosArchivo.idDocumento,
            idTipoDocumento: datosArchivo.indice
        },{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.propsArchivos = res.data;
            this.propsArchivos.forEach(valor => {
                if(valor.idTipoDocumento === datosArchivo.indice){
                    this.differenceLoad(valor.tamaño, 0)
                } else{
                    this.differenceLoad(0, 0)
                }
            });
        });
    }

    differenceLoad = (tamanioDifencia, tamanioValidacion) =>{
        const size = tamanioDifencia - tamanioValidacion;
        this.progressload((size/1024/1024), 1);
    }
    
    progressload=(tamanioArchivo, n)=> {
        const tamanioPorcentaje = (tamanioArchivo)*(100/15);
        if(n === 0){
            this.setState({porcentaje: this.state.porcentaje + tamanioPorcentaje});
            this.setState({megas: this.state.megas + tamanioArchivo});
        }else{
            this.setState({porcentaje: this.state.porcentaje - tamanioPorcentaje});
            this.setState({megas: this.state.megas - tamanioArchivo});
        }
    }

    handleAvisoUsuarioNovalidoClick = () => {
        this.setState({
            avisoUsuarioNovalidoVisible : true
        });
    }

    handleAvisoUsuarioNovalidoClose = () => {
        this.setState({
            avisoUsuarioNovalidoVisible : false
        }, this.props.history.goBack());
    }

    funcionListo= () => {
        if (!this.props.datosAspirante)
        this.props.history.goBack();

        const rolesCaptura = ['ELECINE.ASP.JD', 'ELECINE.ADMIN.OC', 'ELECINE.CAPTURA.OC',
                              'ELECINE.CAPTURA.JL', 'ELECINE.CAPTURA.JD', 'ELECINE.CAU.OC',
                              'ELECINE.CAPTURA_VECEYEC.JL','ELECINE.CAPTURA_VECEYEC.JD',
                              'ELECINE.CAPTURA_VE.JL','ELECINE.CAPTURA_VE.JD',
                              'ELECINE.CAPTURA_CONSEJERO.JL','ELECINE.CONSEJERO.JD',
                              'ELECINE.CAPTURA_VS.JL', 'ELECINE.CAPTURA_VS.JD',
                              'ELECINE.CAPTURA_VERFE.JL', 'ELECINE.CAPTURA_VERFE.JD',
                              'ELECINE.CAPTURA_VOE.JL', 'ELECINE.CAPTURA_VOE.JD'];

        if (this.props.datosUsuario){
            if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario)){
                this.handleAvisoUsuarioNovalidoClick(); // No se permite la entrada del usuario
            }
            if(this.props.datosUsuario.rolUsuario === 'ELECINE.CAU.OC')
                this.setState({guardar: false});
        }
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/aspiranteCompulsado', {
            idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
            idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
            idParticipacion: this.props.datosAspirante.idParticipacion,
            idAspirante: this.props.datosAspirante.idAspirante,
            claveElector: this.props.datosAspirante.claveElectorFuar,
            jdePermiteRegistro:0
        },
        ).then(res => {
            console.log('aspiranteCompulsado' + JSON.stringify(res.data))
           if(res.data.compulsado === 1){
               this.props.history.push('/aspiranteCompulsado');
           }else{
               axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/capturaModificaDocumentos', {
                     idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
                     idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
                     idParticipacion: this.props.datosAspirante.idParticipacion,
                     idAspirante: this.props.datosAspirante.idAspirante
                },
                ).then(res => {
                    console.log('capturaModificaDocumentos ' + JSON.stringify(res.data))
                    if(this.props.datosAspirante.estatusRegistro === 2 &&
                       !this.props.datosAspirante.bObservacion){
                        this.props.history.push('/platica_induccion');
                        return;
                    }

                    if(this.props.datosAspirante.bObservacion === 1 &&
                      (this.props.datosAspirante.bAcudirJDE === null || this.props.datosAspirante.bAcudirJDE === 0) ||
                      (this.props.datosAspirante.bAcudirJDE === 1 &&  this.props.datosUsuario.tipoUsuario === 'I'))
                      {
                        this.props.emitirMensaje(
                            {
                                id: 'mensajeUsuario',
                                tipoMensaje: 'warning',
                                contenido: etiquetas.gral_observacion_documentos,
                            }
                        )
                        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtenerPropsArchivos', {
                            idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
                            idDetalleProceso: this.props.datosAspirante.idDetalleProceso,
                            idParticipacion: this.props.datosAspirante.idParticipacion,
                            idConvocatoria: this.props.datosAspirante.idConvocatoria,
                            folio: this.props.datosAspirante.folio,
                            claveElectorFuar: this.props.datosAspirante.claveElectorFuar
                        },        {
                            headers: {
                                'Accept': 'application/json'
                                ,'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            this.archivoTamanio = res.data;
                            this.archivoTamanio.forEach(valor => {
                                const sizeArchivo = valor.tamaño/1024/1024;
                                this.progressload(sizeArchivo, 0);
                            });
                        });

                    }else if(this.props.datosAspirante.bAcudirJDE === 1 && this.props.datosAspirante.bObservacion === 1 &&
                             this.props.datosUsuario.tipoUsuario === 'E'){
                        this.props.history.push('/acudirJDE');
                        return;
                    }

                    this.listaDocumentos = res.data.archivosObservaciones.listaObservaciones;

                    const relacion = {2:[4], 3:[4], 4:[2,3]}
                    this.listaDocumentos.forEach((valor, indice)=>
                    {

                        this.metadatosArchivos[valor.idTipoDocumento] ={ indice:valor.idTipoDocumento,
                                                                         obligatorio:valor.obligatorio,
                                                                         cumplido:  valor.archivoValido==='S',
                                                                         archivoValido: valor.archivoValido,
                                                                         observaciones:valor.observaciones,
                                                                         capturado: valor.nombreArchivo !== undefined,
                                                                         idDocumento:valor.idDocumento,
                                                                         nombreArchViejo:valor.nombreArchivo
                                                                            };
                    })

                    //Cuando es modificación
                    Object.entries(relacion).forEach((valor)=>{
                        const deshabilitar = valor[1].some(_valor=>{
                            const actual = this.metadatosArchivos[_valor];
                            return actual.cumplido
                    })

                    this.metadatosArchivos[valor[0]].disabled = deshabilitar;
                    })

                    const guardar =!this.metadatosArchivos.some(valor=>
                    {
                        return (valor.obligatorio=== 'S' ) &&  !valor.cumplido && !valor.disabled})
                        this.setState({cargado:true,guardar});
                    }
                    )
                } //Termina else
            }
        )
    }

    guardar=()=>{
        this.setState({blocking: true});
        const formData = new FormData();
        var arregloEnviar=[];
        this.metadatosArchivos.forEach(
            (valor1)=>{
            this.metadatosArchivos.forEach(
                (valor2)=>{
                if(((valor1.indice === 2 && valor1.disabled === true) || (valor1.indice === 3 && valor1.disabled === true)) 
                    && valor2.indice === 4){
                    if(valor1.archivo !== undefined && valor2.archivo !== undefined){
                        valor1.archivo = undefined;
                    }
                }else if((valor1.indice === 4 && valor1.disabled === true) && (valor2.indice === 2 || valor2.indice === 3)){
                    if(valor1.archivo !== undefined && valor2.archivo !== undefined){
                        valor1.archivo = undefined;
                    }
                }
            })
        })
        this.metadatosArchivos.forEach(
            (valor)=>{ 
                        if(valor.archivo === undefined)
                        return;
                        const metadatoArchivo ={idTipoDocumento:valor.indice,
                                                uid: valor.archivo.uid,
                                                type: valor.archivo.type,
                                                name: valor.archivo.name,
                                                idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
                                                idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
                                                idParticipacion: this.props.datosAspirante.idParticipacion,
                                                idConvocatoria: this.props.datosAspirante.idConvocatoria,
                                                folio: this.props.datosAspirante.folio,
                                                folioAcceso: this.props.datosAspirante.folioAcceso,
                                                idAspirante: this.props.datosAspirante.idAspirante,
                                                idSedeReclutamiento: this.props.datosAspirante.idSedeReclutamiento,
                                                claveElectorFuar:this.props.datosAspirante.claveElectorFuar,
                                                origenRegistro:this.props.datosAspirante.origenRegistro,
                                                correo:this.props.datosAspirante.correo,
                                                idDocumento: valor.idDocumento,
                                                usuario:this.props.datosUsuario.username,
                                                nombreArchViejo: valor.nombreArchViejo,
                                                tipoUsuario: this.props.datosUsuario.tipoUsuario
                                               };

                        arregloEnviar.push(metadatoArchivo)
                        formData.append('files', valor.archivo)

                     }
            )

            formData.append('metadatos',new Blob([JSON.stringify(arregloEnviar)],{type:"application/json"}))
            this.setState({cargando: true});
            const jwt = localStorage.getItem('jwt-token');
			axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/guardarDocumentos', formData, {
				headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: jwt
                }

			}).then(res => {
                const entidad = res.data.entity;
                this.setState({blocking: false});

                if(entidad.code === 200){
                    this.props.cambiarEstatus(2);
                    this.props.registrarTerminado(true);
                    if(this.props.datosAspirante.bPlatica !== 0)
                        this.props.history.push('/exitoDocumentos');
                    else {
                        const datosAspirante2 = {};
                        //Copiando objeto datosAspirante
                        Object.assign(datosAspirante2, this.props.datosAspirante);
                        if(this.props.datosUsuario.tipoUsuario === 'E')
                            datosAspirante2.bObservacion = null;
                        else{
                            datosAspirante2.bObservacion = 0;
                            datosAspirante2.estatusRegistro = 3;
                        }
                        this.props.implantarAspirante(datosAspirante2);
                        this.props.history.push('/finalizado');
                    }
                }
                if(entidad.code === 400){
                    msjError = entidad.message;
                    if(entidad.message === null){
                        msjError = "Ocurrió un error en la carga de documentos. Por favor inténtalo más tarde.";
                    }
                    this.setState({msjError: msjError, abrirModalError: true});
                    this.setState({guardar: false});
                    this.setState({cargando: false});
                }

			}).catch(error => {
				console.log('Algo malió sal!');
                this.setState({blocking: false});
                this.setState({cargando: false});
                if (error.response &&error.response.data === 'error_token')
                        this.props.history.push('/acceso');
			});
    }

    iniciaIndices(indice, bandera) {
        var indicesCat = [];

        //Cuando se carga un archivo y el indice coincide con una llave del arreglo indicesCat, se deshabilita
        //la carga de archivos para los elementos del arreglo asignado esa llave.
        //Cuando se quita un archivo, se revisa cada uno de los arreglos de cada llave.
        //Y si el indice coincide con alguno de sus elementos y todos los elementos de ese arreglo no
        //tienen archivo, se habilita la carga en la llave correspondiente de indicesCat.
        indicesCat[2] = [4];
        indicesCat[3] = [4];
        indicesCat[4] = [2,3];

        indicesCat.forEach((valor,index) => {
            if(indice === index && bandera)
                valor.forEach(_valor =>{
                    this.metadatosArchivos[_valor].disabled = true;
                })
            else if(!bandera && valor.some(_valor => (_valor === indice))) {
                var disabled = valor.some(_valor => {
                    if(_valor === indice)
                        return false;

                    return this.metadatosArchivos[_valor].cumplido;
                });

                this.metadatosArchivos[index].disabled = disabled;
            }
        });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render(){
        const datosUsuario = this.props.datosUsuario || {};
        const datosAspirante = datosUsuario.datosAspirante || {};
        var listaRenderizadaDoctosOblig=[];
        var listaRenderizadaDoctosOpcion=[];

        if(!this.state.cargado){
            listaRenderizadaDoctosOblig = undefined
            listaRenderizadaDoctosOpcion = undefined
        }else{
            var modObligatorio=false, modOpcional = false;

            this.listaDocumentos.some((valor)=>{
                modObligatorio = modObligatorio || valor.obligatorio === 'S' && (valor.observacion || valor.archivoValido);
                modOpcional = modOpcional || valor.obligatorio === 'N' && (valor.observacion || valor.archivoValido);

                return modObligatorio && modOpcional
            })

            this.listaDocumentos.forEach((valor, indice)=>
            {
                const etiquetaBoton= (valor.archivoValido === 'N'?'Reemplazar':'Adjuntar');

                const claveFUARInfo	= 	    <Popover overlayClassName='pop_tooltip'
                                                     content={<img src={ayudaClaveElector} width='370px'/> }>
                                                <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.45)' }} theme='filled'/>
                                            </Popover>
                var imagenPop = undefined;
                if(valor.idTipoDocumento === 2){
                    imagenPop = ayudaINEFrente;
                }else if(valor.idTipoDocumento === 3){
                    imagenPop = ayudaINEReverso;
                }else if(valor.idTipoDocumento === 4){
                    imagenPop = ayudaINEAmbos;
                }

                var popIne = "";
                if(valor.idTipoDocumento >1 && valor.idTipoDocumento < 5){
                    popIne = <Popover key={'pop'+indice} overlayClassName='pop_tooltip'
                                            content={<img src={imagenPop} width='370px'/> }
                                            placement = "right">
                                        <span id="font_cargaDocs">&nbsp;<Icon type="paper-clip" /> {etiquetaBoton}</span>
                              </Popover>
                }else{
                    popIne = <span id="font_cargaDocs">&nbsp;<Icon type="paper-clip" /> {etiquetaBoton}</span>
                }
                
                const columnaAdjuntar = (valor.archivoValido !== 'S' ?
                        <FileUpload key={valor.idTipoDocumento}
                                    datosArchivo={this.metadatosArchivos[valor.idTipoDocumento]}
                                    disabled = {this.metadatosArchivos[valor.idTipoDocumento].disabled}
                                    defaultfileList={[]}
                                    multiple={false}
                                    changeList={this.changeList}
                                    validacion={this.validacion}
                                    fileList={this.state.fileLists[valor.idTipoDocumento]}
                                    etiqueta={popIne}

                                    style={{width:220}}
                                    accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                    className="button_style"
                                />
                        :null)
                var styleHeightObs= { height : "auto", padding:'5px 0'};
                const columnasObservaciones = (valor.obligatorio === 'S' && modObligatorio) || (modOpcional && valor.obligatorio === 'N')?
                (<React.Fragment>
                    <Col xs={{span:5}} sm={{span:6}} md={{span:5}} lg={{span:5}} style={styleHeightObs}> <font id="font_cargaDocs"> &nbsp;{valor.nombreArchivo}</font> </Col>
                    <Col xs={{span:9}} sm={{span:8}} md={{span:8}} lg={{span:7}} style={styleHeightObs} id="font_cargaDocs"> {columnaAdjuntar} </Col>
                    <Col xs={{span:5}} sm={{span:4}} md={{span:5}} lg={{span:6}} style={styleHeightObs} id="font_cargaDocs"> {valor.archivoValido === 'S'?
                            <span style={{fontWeight:'bold'}}>
                                <Icon type="check-circle" style={{ fontSize: '16px', color: '#8BD23F' }} theme="filled"/> &nbsp;Validado</span>:
                        valor.archivoValido === 'N'?
                            <span style={{fontWeight:'bold'}}>
                                <Icon type="exclamation-circle" style={{ fontSize: '16px', color: '#ffba00' }} theme="filled"/>
                                &nbsp;{valor.observaciones}
                            </span>
                       :null}
                    </Col>
                </React.Fragment>
                ):(null);
                
                var styleOBs = (valor.obligatorio === 'S' && modObligatorio) || (modOpcional && valor.obligatorio === 'N')?
                    styleHeightObs : null

                const renglon = <Row key={valor.idTipoDocumento} style={{display: 'flex'}}>
                                    <Col xs={{span:5}} sm={{span:6}} md={{span:5}} lg={{span:6}} style={styleHeightObs}>
                                        <font id="font_cargaDocs">{valor.nombreDocumento}</font> </Col>
                                    {columnasObservaciones}
                                    {datosAspirante.bObservacion === null ?
                                    <Col span={9} style={styleHeightObs}>
                                        {columnaAdjuntar}
                                    </Col>:null}
                                </Row>;
                if(valor.obligatorio === 'S'){
                    listaRenderizadaDoctosOblig.push(renglon);
                }else{
                    listaRenderizadaDoctosOpcion.push(renglon);
                }
            })
        }

        return(
           <AuthenticatedComponent listo={this.funcionListo}>
                <Template contenido={
                    <div className='carga_documentos' style={{overflowX: 'auto'}}>
                        <div className="titulo_cargaDocumentos">
                            Adjunta tu documentación
                        </div>

                        <div className="info_ayuda">
                            <Icon type="info-circle" theme="filled"/>
                            La documentación puede ser escaneada o fotografiada para adjuntar en formatos PDF, JPG y PNG, 
                            no excediendo 15MB y excluir en el nombre de los archivos . , # $ % & [] =
                        </div>
                        <div className='contenedor_progreso'>
                            <br/>
                            <ProgresoCargaDocs porcentaje={this.state.porcentaje} megas={this.state.megas}/>
                        </div>
                        <div></div>
                        <div className="subtitulo_cargaDocumentos"><b>Documentos obligatorios</b></div>
                        <hr></hr>
                        <div className="tabla_documentos">
                            {listaRenderizadaDoctosOblig}
                            <div className="subtitulo_cargaDocumentos"><b>Documentos opcionales</b></div>
                            <hr></hr>
                            {listaRenderizadaDoctosOpcion}
                            <p></p>
                            <Row style={{'marginBottom':'40px'}}>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <Button type="primary"
                                            className="button_style"
                                            style={{width:220, 'marginLeft': '25%'}}
                                            htmlType="submit"
                                            disabled={!this.state.guardar}
                                            onClick={this.guardar}
                                    >
                                        Guardar
                                    </Button>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </div>

                    </div>
                }
                bloqueado={!this.state.cargado}

                guia={datosUsuario.tipoUsuario==='E'?true:false}
                cargando={this.state.cargando}
                />
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.avisoUsuarioNovalidoVisible}
                    onOk={this.handleAvisoUsuarioNovalidoClose}
                    onCancel={this.handleAvisoUsuarioNovalidoClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
                        <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
                    </div>
                </Modal>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
            </AuthenticatedComponent>
        )
    }
}

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
        datosUsuario = state.sesion.datosUsuario;

    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}

export default Form.create({ name: 'cargaDocumentos' })(connect(mapStateToProps,{registrarTerminado,cambiarEstatus,implantarAspirante,emitirMensaje})(CargaDocumentos));

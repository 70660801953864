import React from 'react';
import {Card, Row, Col, message, Modal} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import InputText from '../interfaz/InputText';
import Select, {Option} from '../interfaz/Select';
import errorImgModal from '../../img/error_icon.svg';

let msjError = "";
let idLocalidad = 0; // variable para guardar el valor de la localidad seleccionada
//en el campo localidad agregué el onChange para asiganar el valor de la localidad seleccionada a la variable idLocalidad

class DatosDireccion extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {
          lstLocalidades : null,
          lstMunicipios : null,
          msjError : "",
          abrirModalError : false
        };

        this.handleMunicipioChange = this.handleMunicipioChange.bind(this);
        this.handleTelefonoChange = this.handleTelefonoChange.bind(this);
        this.handleCelularChange = this.handleCelularChange.bind(this);
        //si idlocalidad es difernte a undefined, vovlemos a llamar al método handleMunicipioChange()
        if(idLocalidad > 0 ) {
          this.handleMunicipioChange();
        }
          console.log("state",this.state,idLocalidad);
    }

    cerrarErrorModal(){
      this.setState({abrirModalError:false});
    }
    //Método que sirve par asignar el valor de la localidad a la variable idLocalidad
    asignarLocalidad(valor){
      idLocalidad = valor;
    }
    componentWillMount() {

            this.props.getMunicipios()
                    .then(lstMunicipios => this.setState({lstMunicipios}))
                    .catch(error => {
                      console.error("Error cargar los municipios "+
                          "componentWillMount()",error);
                      if (error.response){
                          if (error.response.status === 409){
                              msjError = error.response.data.message;
                          } else if (error.response.data && error.response.data.causa
                              && error.response.data.causa.includes("Error de validación")){
                              msjError = error.response.data.message;
                          } else {
                              msjError = etiquetas.gral_error_cau;
                          }
                      } else {
                          msjError = etiquetas.gral_error_cau;
                      }
                      this.setState({
                        msjError : msjError,
                        abrirModalError : true
                      });
                    });

          /*  this.props.getLocalidades()
                .then(lstLocalidades => {
                    this.setState({lstLocalidades});
                }).catch(error => {
                    if (error.response && error.response.status === 409){
                        message.warning(error.response.data.message, 5);
                    } else {
                        message.error(etiquetas.gral_serivicio_no_disponible+"ww", 5);
                    }
                });*/

    }

    handleMunicipioChange = (value, event) => {
            this.props.getLocalidades()
                .then(lstLocalidades => {
                    this.setState({lstLocalidades});
                    this.props.form.setFields({
                        idLocalidad: {value: idLocalidad > 0 ? idLocalidad: null},
                    });
                    console.info("listaLocalidades",this.state.lstLocalidades);
                }).catch(error => {
                  console.error("Error en handleMunicipioChange()",error);
                  if (error.response){
                      if (error.response.status === 409){
                          msjError = error.response.data.message;
                      } else if (error.response.data && error.response.data.causa
                          && error.response.data.causa.includes("Error de validación")){
                          msjError = error.response.data.message;
                      } else {
                          msjError = etiquetas.gral_error_cau;
                      }
                  } else {
                      msjError = etiquetas.gral_error_cau;
                  }
                  this.setState({
                    msjError : msjError,
                    abrirModalError : true
                  });
                });

    }

    handleTelefonoChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    telefono: value,
                },
                () => {
                    this.props.form.validateFields(['telefono', 'celular'], { force: true });
                }
            );
    }

    handleCelularChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    celular: value,
                },
                () => {
                    this.props.form.validateFields(['telefono', 'celular'], { force: true });
                }
            );
    }

    render() {
        return(
            <React.Fragment>
              <>
                <Card title={etiquetas.datos_direccion_lbl_titulo} bordered={false}>


                    <Row gutter={16}>
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="calleNumero"
                                       placeholder={etiquetas.datos_direccion_lbl_calleNumero}
                                       form={this.props.form}
                                       required={true}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={250}
                                       rules={[{
                                                max: 250
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="colonia"
                                       placeholder={etiquetas.datos_direccion_lbl_colonia}
                                       form={this.props.form}
                                       required={true}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={120}
                                       rules={[{
                                                max: 120
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="cp"
                                       placeholder={etiquetas.datos_direccion_lbl_cp}
                                       form={this.props.form}
                                       required={true}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={5}
                                       rules={[{
                                                len: 5
                                                , pattern: /^\d{5,5}$/i
                                            }]}
                                       custom={{
                                            getValueFromEvent: (event, previo) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return previo;
                                            }
                                        }}/>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <Select nombre='idMunicipio'
                                    placeholder={etiquetas.datos_direccion_lbl_municipio}
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{type: 'number'}]}
                                    showSearch={false}
                                    onChange={(value, event) => this.handleMunicipioChange(value, event)}>
                                        {
                                            this.state.lstMunicipios &&
                                            this.state.lstMunicipios.map((municipio, index) => (
                                                <Option key={index} value={municipio.idMunicipio}>{municipio.nombreMunicipio}</Option>
                                            ))
                                        }
                            </Select>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <Select nombre='idLocalidad'
                                    placeholder={etiquetas.datos_direccion_lbl_localidad}
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{type: 'number'}]}
                                    onChange={(value, event) => this.asignarLocalidad(value, event)}
                                    showSearch={true}>
                                        {
                                            this.state.lstLocalidades &&
                                            this.state.lstLocalidades.map((localidad, index) => (
                                                <Option key={index} value={localidad.idLocalidad}>
                                                  {localidad.idLocalidad} - {localidad.nombreLocalidad}
                                                </Option>
                                            ))
                                        }
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                    <div style={{'font-weight':'bold','font-size': '13px'}}>
                        {etiquetas.general_telefono_requerido_leyenda}
                    </div>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="telefono"
                                       placeholder={etiquetas.datos_direccion_lbl_telefono}
                                       form={this.props.form}
                                       required={!this.props.form.getFieldValue("celular")}
                                       msjRequerido={etiquetas.general_telefono_requerido}
                                       maxLength={10}
                                       rules={[{
                                                min: 8
                                                , max: 10
                                                , pattern: etiquetas.REGEX_FORMATO_TELEFONO
                                            }]}
                                       custom={{
                                            getValueFromEvent: (event, previo) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return previo;
                                            }
                                        }}
                                        onChange={(value) => this.handleTelefonoChange(value)}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="celular"
                                       placeholder={etiquetas.datos_direccion_lbl_celular}
                                       form={this.props.form}
                                       required={!this.props.form.getFieldValue("telefono")}
                                       msjRequerido={etiquetas.general_telefono_requerido}
                                       maxLength={10}
                                       rules={[{
                                                min: 8
                                                , max: 10
                                                , pattern: etiquetas.REGEX_FORMATO_TELEFONO
                                            }]}
                                       custom={{
                                            getValueFromEvent: (event, previo) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return previo;
                                            }
                                        }}
                                        onChange={(value) => this.handleCelularChange(value)}/>
                        </Col>
                    </Row>
                </Card>
                <Modal
                  className="modalError"
                  title={<img  src={errorImgModal} id="img-error-modal"/>}
                  centered
                  onCancel={()=>this.cerrarErrorModal()}
                  visible={this.state.abrirModalError}
                  footer={false}
                  width={400}
                >
                  {this.state.msjError}
                </Modal>
                </>
           </React.Fragment>
        );
    }
};

export default DatosDireccion;

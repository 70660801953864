import React, {Component} from 'react';
import {ConfigProvider, Layout, Icon, Row, Col} from 'antd';
import esES from 'antd/es/locale/es_ES';
import logoINE from '../../img/logo_INE_blanco-06.png';
import imgBandaTitulo from '../../img/logo_recluta.svg'
import {withRouter} from 'react-router-dom';
import BlockLoader from './Loader.js';
import * as etiquetas from '../../ApplicationResources';

const {Header, Content, Footer} = Layout;

class TemplateExterno extends Component {
	render() {
		const bloquear = this.props.bloqueado || this.props.cargando;

		return (
			<ConfigProvider locale={esES}>
				<BlockLoader blocking={bloquear && this.props.bloquearTodo}
							 id='template_externo_bloq'
							 bloquearTodo={true}>
					<Layout className="layout template_externo">
						<Header className="header_style_externo">
							<div id="banda_titulo">
								<a href="/loginSEyCAE">
									<img src={imgBandaTitulo} alt=""/>
								</a>
							</div>
						</Header>
						<Content className={this.props.contenidoExpandido ? 'contenido_expandido' : undefined}>
							<BlockLoader blocking={bloquear && !this.props.bloquearTodo}>
								{this.props.children}
							</BlockLoader>
						</Content>
						<Footer className='footer_style_externo web'>
							<Row>
								<Col xs={2} sm={4} md={12} lg={12}>
									<Row>
										<Col xs={2} sm={6} md={6} lg={12} id="div-logo">
											<img id="logoINE" src={logoINE} alt=""/>
										</Col>
										<Col xs={2} sm={6} md={6} lg={12}>
											<h5 className='text_footer' id="ine-footer">
												<div id="division-footer"></div>
												<Icon type="copyright" id="icon-copy"/> INE M&eacute;xico 2020.
												<a href="#" id="cau">CAU</a>
													<span style={{'fontSize':'7px','marginTop':'0px','float':'left','color':"#000"}}>
														{etiquetas.hora_fecha_version}
													</span >
											</h5>

										</Col>
									</Row>
								</Col>
								<Col xs={2} sm={6} md={6} lg={12} id="div-version">
									<h5 className='text_footer' id="footer-version">
										Versi&oacute;n del sistema 2.0
									</h5>
								</Col>
							</Row>
						</Footer>
						<Footer className='footer_style movil'>
								<div id="div-logo-mov">
											<img id="logoINE-mov" src={logoINE} alt=""/>
											<div className='text_footer'
														id="division-footer"></div>
											<Icon className='text_footer'
														type="copyright" id="icon-copy"/>
											<h5 className='text_footer'>INE M&eacute;xico 2020.</h5>
											<a className='text_footer'
														href="#" id="cau">CAU</a>
													<span style={{'fontSize':'7px','marginTop':'0px','float':'left','color':"#000"}}>
															{etiquetas.hora_fecha_version}
														</span >
								</div>
								<div id="div-version-mov">
									<h5 className='text_footer' id="footer-version">
										Versi&oacute;n del sistema 2.0
									</h5>
								</div>
						</Footer>
					</Layout>
				</BlockLoader>
			</ConfigProvider>
		);
	}
}

TemplateExterno.defaultProps = {
	bloqueado: false,
	cargando: false,
	enlaces: true,
	contenidoExpandido: false,
}

export default withRouter(TemplateExterno);

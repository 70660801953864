import React, {Component} from 'react';
import Template from '../interfaz/Template';
import * as etiquetas from '../../ApplicationResources';
import AuthenticatedComponent from '../AuthenticatedComponent';
import axios from 'axios';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import '../../css/options_menu.css';
import iconRegistro from "../../img/registra_icon.png";
import iconReporte from "../../img/reporte_icon.png";
import iconValidar from "../../img/validar_icon.png";


class MenuJDE extends Component {
  constructor(props){
    super(props);
    this.state = {
      rutaPublicador:""
    }
  }

  funcionListo = () =>{
    this.obtenerRutaPublicador();
  }

    onRegistroAspirante = () => {
        this.props.history.push('/registro_aspirante');

    }
    onBandeja = () => {
        this.props.history.push('/bandeja');
    }

    obtenerRutaPublicador(){
        var datos = this.props.datosUsuario;
        var datosUsuario = { idEstado: datos.idEstado,
                            idDistrito: datos.idDistrito,
                            rolUsuario: datos.rolUsuario,
                            usuario: datos.username
                          };
    		var postData = {
    			idProceso : etiquetas.id_proceso_electoral,
    			idDetalle : etiquetas.id_detalle_proceso,
          idEtiqueta : 54,
          datosUsuario : datosUsuario
    		}
        axios.post(process.env.REACT_APP_API_URL +
          "/centralReclutaSEyCAE/ws/obtenerRutaPublicador", postData,
              {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
              }
           ).then(response => {
            this.setState({
    					rutaPublicador : response.data !== null ?
                                      response.data.replace(/['"]+/g, '') : ""
    				});
        }).catch(error => {
          var msjError = "";
          console.error("Error al consultar la ruta del publicador "+
              "obtenerRutaPublicador()",error);
          if (error.response){
              if (error.response.status === 409){
                  msjError = error.response.data.message;
              } else if (error.response.data && error.response.data.causa
                  && error.response.data.causa.includes("Error de validación")){
                  msjError = error.response.data.message;
              } else {
                  msjError = etiquetas.gral_error_cau;
              }
          } else {
              msjError = etiquetas.gral_error_cau;
          }
          console.error( "error...", msjError);
        });
    }

    render() {
        return (
        <AuthenticatedComponent externo={false} listo={this.funcionListo}>
          <Template contenido={
            <>
                <div id="options-menu">
                  <div className="element_option_menu" id="validar">
                    <span onClick={this.onBandeja} className="btn btn-success mr-2">
                      <img src={iconValidar}/>
                      <label className="label_lement_menu"
                              id="validar-label">
                        {etiquetas.menu_element_uno}
                      </label>
                    </span>
                  </div>
                  <div className="element_option_menu" id="registro">
                    <span onClick={this.onRegistroAspirante}
                          className="btn btn-success mr-2">
                      <img src={iconRegistro}/>
                      <label className="label_lement_menu" id="registro-label">
                        {etiquetas.menu_element_dos}
                      </label>
                    </span>
                  </div>
                  <div className="element_option_menu" id="reportes">
                      <a href={this.state.rutaPublicador}
                         className="btn btn-success mr-2" target="_blank">
                      <img src={iconReporte}/>
                        <label className="label_lement_menu" id="reporte-label"
                          style={{'marginLeft':'-50px'}}>
                          {etiquetas.menu_element_tres}
                        </label>
                      </a>
                  </div>
                </div>
          </>
          }
          guia={false}/>
        </AuthenticatedComponent>);
    }
};

const mapStateToProps = state => {
  var datosUsuario;

  if (state.sesion.datosUsuario) {
      datosUsuario = state.sesion.datosUsuario;
  }

  return {
      datosUsuario: datosUsuario,
  };
}

export default connect(mapStateToProps)(MenuJDE);

import React, {Component} from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {Provider} from 'react-redux'
import Login from './Login'
import store from '../redux/store'
import Logout from './Logout'
import ModificaCorreoComponent from './aspirante/ModificaCorreoComponent'
import Aspirante from './aspirante/AspiranteComponent'
import ExitoRegistro from './aspirante/ExitoRegistro'
import CargaDocumentos from './documentacion/CargaDocumentos'
import AspiranteCompulsado from './documentacion/AspiranteCompulsado'
import AcudirJDE from './documentacion/AcudirJDE'
import MenuJDE from './modulosJDE/MenuJDE'
import PlaticaInduccion from './platicaInduccion/Platica'
import Cuestionario from './platicaInduccion/Cuestionario'
import ExitoPlatica from './platicaInduccion/ExitoPlatica'
import ExitoDocumentos from './documentacion/ExitoDocumentos'
import ValidaCorreo from './ValidaCorreo'
import DescargarAcuses from './documentacion/DescargarAcuses'
import EnConstruccion from './EnConstruccion'
import ValidacionDocumentos from './modulosJDE/ValidacionDocumentos'
import ReenvioAcuses from './modulosJDE/ReenvioAcuses'
import AdministrarRegistro from './modulosJDE/AdministrarRegistro'
import ComponentBandeja from "./modulosJDE/bandejaJDE/Bandeja"
import ComponentValidaBandeja from "./modulosJDE/bandejaJDE/Valida"
import ComponentModificaAspirante from "./modulosJDE/bandejaJDE/ModificaAspirante"
import SinResultados from '../componentes/SinResultados'
import OperacionExitosa from '../componentes/modulosJDE/OperacionExitosa'
import ExitoModifica from './aspirante/ExitoModifica'
import Contacto from './aspirante/Contacto'
import {visitas} from "../utils/scripts";
import Finalizado from './aspirante/Finalizado'
import SolicitudDeclinada from './aspirante/SolicitudDeclinada'
import Home from './Home'
import Visor from './visor/Visor'

import '../css/styles.css'
import '../css/checkbox.css'
import '../css/guia_estatus.css'
import '../css/autocomplete.css'
import '../css/select.css'
import '../css/radio.css'
import '../css/mensaje.css'
import '../css/modal.css'
import '../css/calendar.css'
import '../css/template_externo.css'
import '../css/exitoInfos.css';
import packageJson from "../../package.json";
import { getBuildDate } from "../utils/utils";
import withClearCache from "../ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function AppIni() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
        <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
  );
}

class App extends Component {
	render() {
		return (
			<div className="App" onChange={visitas} onLoad={visitas}>
		  		<Provider store={store}>
					<BrowserRouter>
					  	<Switch >
					  		<Redirect exact from="/" to="/loginSEyCAE"/>
					  		<Redirect exact from="/loginSEyCAE.html" to="/loginSEyCAE"/>
					  		<Route path="/acceso" component={Login}/>
							<Route path="/registro_aspirante" component={ (props) => (
								<Aspirante timestamp={new Date().toString()} objetos={[]} {...props} />
							)}/>
							<Route path='/menuJDE' component={MenuJDE}/>
							<Route path='/visor' component={Visor}/>
							<Route path='/validacion_documentos' component={ValidacionDocumentos}/>
							<Route path='/reenvioAcuses' component={ReenvioAcuses}/>
							<Route path='/administrarRegistro' component={AdministrarRegistro}/>
					              {/* Ini: Pantallas de la bandeja JDE */}
						    <Route 	path="/bandeja/modifica/:folioAcceso&:claveElector"
					                render={props => {

									  const folioAcceso = props.match.params.folioAcceso;
									  const claveElector = props.match.params.claveElector;
					                  return (
					                    <ComponentModificaAspirante folio={folioAcceso} claveElector={claveElector}/>
					                  );
					                }}
					              />
							<Route 	path="/bandeja/valida/:folioAcceso"
					                render={props => {
					                  const folioAcceso = props.match.params.folioAcceso;
					                  return <ComponentValidaBandeja folioAcceso={folioAcceso} />;
					                }}
					              />
							<Route path="/bandeja">
								<ComponentBandeja />
							</Route>
							{/* Fin: Pantallas de la bandeja JDE */}
							<Route path='/modifica_correo' component={ModificaCorreoComponent}/>
							<Route path='/exito_registro' component={ExitoRegistro}/>
							<Route path='/exito_modifica' component={ExitoModifica}/>
							<Route path="/validaCorreo" component={ValidaCorreo}/>
							<Route path="/cargaDocumentos" component={CargaDocumentos}/>
							<Route path='/exitoDocumentos' component={ExitoDocumentos}/>
							<Route path='/acudirJDE' component={AcudirJDE}/>
							<Route path='/aspiranteCompulsado' component={AspiranteCompulsado}/>
							<Route path="/enConstruccion" component={EnConstruccion}/>
							<Route path="/operacion_exitosa" component={OperacionExitosa}/>
							<Route path="/platica_induccion" component={PlaticaInduccion}/>
							<Route path="/cuestionario" component={Cuestionario}/>
				  			<Route path="/loginSEyCAE" component={Home}/>
							<Route path="/exitoPlatica" component={ExitoPlatica}/>
							<Route path="/contacto" component={Contacto}/>
							<Route path="/descargar_acuses" component={DescargarAcuses}/>
				  			<Route path="/logout" component={Logout}/>
				  			<Route path="/finalizado" component={Finalizado}/>
				  			<Route path="/solicitud_declinada" component={SolicitudDeclinada}/>
							<Route path='/exito_autoriza/tipoAspirante/:tipo&:folioAcceso'
							 render={props => {
								//const tipo = props.match.params.tipo;
								var tipo = parseInt(props.match.params.tipo);
								const folioAcceso = props.match.params.folioAcceso;
								return <ComponentValidaBandeja folioAcceso={folioAcceso} tipo={tipo}/>;
							  }}


							 />
							<Route path="/*" component={SinResultados}/>

					  	</Switch>
					</BrowserRouter>
				</Provider>
			</div>
		);
	}
}

export default App;

import React from 'react';
import {Card, Row, Col, Typography, Icon} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';
import {valueUpperCase} from '../../utils/funciones.js';
import InputText from '../interfaz/InputText';
import Checkbox from '../interfaz/Checkbox';
import Calendario from '../interfaz/Calendario';
import RadioGroup, {Radio} from '../interfaz/Radio';

const { Text } = Typography;

class DatosPersonales extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {
            edad : null,
            modifica: false
        };

        this.handleApellidoPaternoChange =
                                    this.handleApellidoPaternoChange.bind(this);
        this.handleApellidoMaternoChange =
                                    this.handleApellidoMaternoChange.bind(this);
        this.handleCorreoBlur = this.handleCorreoBlur.bind(this);
        console.log("wer",this.props.objetos);
    }

    componentDidMount() {
        if(this.props.modifica === true){  // si es modifica entonces desaparece el  guia de estatus
            const usuarioInterno = (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I'?true:false);
            const modifica = !usuarioInterno && this.props.objetos[etiquetas.WIZARD_DATOS_PERSONALES]['correoElectronico'].value
                         && this.props.objetos[etiquetas.WIZARD_DATOS_PERSONALES]['confirmaCorreoElectronico'].value !== null ? true : false;
            this.setState({ modifica : modifica});
        }
    }

    handleApellidoPaternoChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    apellidoPaterno: value,
                },
                () => {
                    this.props.form.validateFields(['apellidoPaterno',
                                      'apellidoMaterno'], { force: true });
                }
            );
    }

    handleApellidoMaternoChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    apellidoMaterno: value,
                },
                () => {
                    this.props.form.validateFields(['apellidoPaterno', 'apellidoMaterno'], { force: true });
                }
            );
    }

    /**
     * Realiza el manejo de cambio en el control de fecha de nacimiento.
     */
    handleFechaNacimientoChange = value => {
        // Validar maximo fecha
        // Validar edad (minimo 18 años)
        let edad = value ? moment().diff(moment(value, etiquetas.FORMATO_FECHA_MOMENT), 'years') : 0;
        if(edad >= 17){
            this.props.form.setFieldsValue({
                edad: edad,
            });
        } else {
            // Error menor a 17 años
            this.props.callbackResponse({1: {edad: {error: etiquetas.gral_error_menor_edad}}});
        }
        /* Error para adultos mayores*/
        if(edad >=60){
            this.props.callbackResponse({1: {edad: {error: etiquetas.gral_error_mayor_edad}}});
        }
    }

    handleCorreoBlur = (value, source) => {
        var correo1 = document.getElementById("wizard_correoElectronico").value;
        var correo2 = document.getElementById("wizard_confirmaCorreoElectronico").value;
        const regexCorreo = new RegExp(etiquetas.REGEX_FORMATO_CORREO);

        if(correo1 !== " "){
          correo1 = correo1.trim().replace(/^\s+|\s+$/gm, "");
          correo1 = correo1.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
          correo1= correo1.trim().replace(/\s\s+/g, "");
          correo1 = correo1.replace(/ /g, "");
        }
        if(correo2 !== " "){
          correo2 = correo2.trim().replace(/^\s+|\s+$/gm, "");
          correo2 = correo2.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
          correo2= correo2.trim().replace(/\s\s+/g, "");
          correo2 = correo2.replace(/ /g, "");
        }
        this.props.form.setFieldsValue({
          correoElectronico : correo1,
          confirmaCorreoElectronico : correo2
        });
        var usuarioInterno = this.props.datosUsuario &&
                      this.props.datosUsuario.tipoUsuario === 'I'? true : false;
        const correoElectronico = this.props.form.getFieldValue('correoElectronico');
        const confirmaCorreoElectronico = this.props.form.getFieldValue('confirmaCorreoElectronico');
        console.info("correos ingresados", correoElectronico,"-",confirmaCorreoElectronico);
        if (!usuarioInterno) {
          if(correoElectronico.length === 0 ){
            this.props.callbackResponse({1: {correoElectronico: {
                                    error: etiquetas.general_dato_requerido}}});
          }
          if(confirmaCorreoElectronico.length === 0){
            this.props.callbackResponse({1: {confirmaCorreoElectronico: {
                                    error: etiquetas.general_dato_requerido}}});
          }
        }
        if(correoElectronico){
            if(!regexCorreo.test(correo1)){
              this.props.callbackResponse({1: {correoElectronico: {error: etiquetas.general_formato_invalido}}});
            }
        }
        if(confirmaCorreoElectronico){
            if(!regexCorreo.test(correo2)){
              this.props.callbackResponse({1: {confirmaCorreoElectronico: {error: etiquetas.general_formato_invalido}}});
            }
        }
        if(correoElectronico && confirmaCorreoElectronico){
            if(correoElectronico !== confirmaCorreoElectronico){
                if(source === 'correoElectronico'){
                    this.props.callbackResponse({1: {correoElectronico: {error: etiquetas.gral_correos_diferentes}}});
                }
                if(source === 'confirmaCorreoElectronico'){
                    this.props.callbackResponse({1: {confirmaCorreoElectronico: {error: etiquetas.gral_correos_diferentes}}});
                }
            }
        }

    }

    render() {
        const usuarioInterno = (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I'?true:false);
        const usuario = (this.props.datosUsuario && (this.props.datosUsuario.tipoUsuario === 'I' ||
                                                            this.props.datosUsuario.tipoUsuario === 'E')?true:false);

        return(
            <React.Fragment>
                <Card title={etiquetas.datos_personales_lbl_titulo} bordered={false}>
                    <Text style={{color: '#1890ff'}}>
                      <Icon type="info-circle" theme="filled"
                            style={{fontSize: '1.0em'}} />
                          Registra tu nombre tal y como aparece en tu credencial elector.
                    </Text>

                      <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="rfc"
                                    placeholder={etiquetas.datos_personales_lbl_rfc}
                                    form={this.props.form}
                                    maxLength={13}
                                    rules={[{len: 13, pattern: etiquetas.REGEX_FORMATO_RFC}]}
                                    custom={{
                                            getValueFromEvent: valueUpperCase
                                        }}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="curp"
                                    placeholder={etiquetas.datos_personales_lbl_curp}
                                    form={this.props.form}
                                    maxLength={18}
                                    rules={[{len: 18, pattern: etiquetas.REGEX_FORMATO_CURP}]}
                                    custom={{
                                            getValueFromEvent: valueUpperCase
                                        }}/>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="apellidoPaterno"
                                    placeholder={etiquetas.datos_personales_lbl_apellidoPaterno}
                                    form={this.props.form}
                                    inputType='text'
                                    required={!this.props.form.getFieldValue("apellidoMaterno")}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={40}
                                    rules={[{
                                                max: 40
                                                , pattern: etiquetas.REGEX_FORMATO_NOMBRES
                                            }]}
                                    custom={{
                                            getValueFromEvent: valueUpperCase
                                        }}
                                    onChange={(value) => this.handleApellidoPaternoChange(value)}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="apellidoMaterno"
                                    placeholder={etiquetas.datos_personales_lbl_apellidoMaterno}
                                    form={this.props.form}
                                    inputType='text'
                                    required={!this.props.form.getFieldValue("apellidoPaterno")}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={40}
                                    rules={[{
                                                max: 40
                                                , pattern: etiquetas.REGEX_FORMATO_NOMBRES
                                            }]}
                                    custom={{
                                            getValueFromEvent: valueUpperCase
                                        }}
                                    onChange={(value) => this.handleApellidoMaternoChange(value)}/>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="nombre"
                                    placeholder={etiquetas.datos_personales_lbl_nombre}
                                    form={this.props.form}
                                    inputType='text'
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={50}
                                    rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NOMBRES
                                            }]}
                                        custom={{
                                            getValueFromEvent: valueUpperCase
                                        }}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="lugarNacimiento"
                                    placeholder={etiquetas.datos_personales_lbl_lugarNacimiento}
                                    form={this.props.form}
                                    inputType='text'
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={50}
                                    rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <Calendario nombre="fechaNacimiento"
                                        placeholder={etiquetas.datos_personales_lbl_fechaNacimiento}
                                        label={etiquetas.datos_personales_lbl_fechaNacimiento}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        format={etiquetas.FORMATO_FECHA_MOMENT}
                                        formato="dd/mm/aaaa"
                                        showTime={false}
                                        showToday={true}
                                        form={this.props.form}
                                        onChange={this.handleFechaNacimientoChange}
                                        minDate="01/01/1950"
                                        maxDate={moment().format(etiquetas.FORMATO_FECHA_MOMENT)}/>
                        </Col>
                        <Col sm={24} md={4} lg={4} style={{'margin-top':'-7px'}}>
                            <InputText nombre="edad"
                                    placeholder={etiquetas.datos_personales_lbl_edad}
                                    form={this.props.form}
                                    inputType='text'
                                    disabled={true}
                                    required={true}
                                    rules={[{
                                                type: 'integer'
                                            }]}/>
                        </Col>
                        <Col sm={24} md={8} lg={8}>
                            <RadioGroup nombre='sexo'  style={{'margin-top': '6px', padding: '0px'}}
                                        placeholder={etiquetas.datos_personales_lbl_sexo}
                                        form={this.props.form}
                                        label={etiquetas.datos_personales_lbl_sexo}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        rules={[{
                                                type: "enum"
                                                , enum: ['H', 'M']
                                            }]}>
                                <Radio value={'H'}>H</Radio>
                                <Radio value={'M'}>M</Radio>
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="correoElectronico"
                                    placeholder={etiquetas.datos_personales_lbl_correoElectronico}
                                    required={!usuarioInterno || this.props.form.getFieldValue("confirmaCorreoElectronico")}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    form={this.props.form}
                                    maxLength={60}
                                    disabled={this.state.modifica}
                                    rules={[{
                                                type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                            }]}
                                    onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                    onBlur={(value) => this.handleCorreoBlur(value, 'correoElectronico')}/>
                        </Col>

                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="confirmaCorreoElectronico"
                                    placeholder={etiquetas.datos_personales_lbl_confirmaCorreoElectronico}
                                    required={!usuarioInterno || this.props.form.getFieldValue("correoElectronico")}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    form={this.props.form}
                                    maxLength={60}
                                    disabled={this.state.modifica}
                                    rules={[{
                                                type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                            }]}
                                    onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                    onBlur={(value) => this.handleCorreoBlur(value, 'confirmaCorreoElectronico')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox nombre="aceptaContactoCorreo"
                                        form={this.props.form}
                                        disabled={usuarioInterno && !(this.props.form.getFieldValue("correoElectronico") || this.props.form.getFieldValue("confirmaCorreoElectronico"))}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        onChange={val => {
                                            this.props.wizard.current.cambiaNegocio('aceptaContactoCorreo', val);
                                        }}
                                        //validacionNegocio={!usuarioInterno || (this.props.form.getFieldValue("correoElectronico") || this.props.form.getFieldValue("confirmaCorreoElectronico"))}
                                        validacionNegocio={!usuario || (this.props.form.getFieldValue("correoElectronico") || this.props.form.getFieldValue("confirmaCorreoElectronico"))}
                                        style={{ 'textAlign': 'justify' }}>
                                Acepto ser contactado vía correo electrónico para algún seguimiento o notificación de información sobre el proceso de reclutamiento y selección, en que estoy participando.
                            </Checkbox>
                        </Col>
                    </Row>

                </Card>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {

    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario,
    };
}

export default connect(mapStateToProps)(DatosPersonales);

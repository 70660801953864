import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import { Icon, Row, Col, Modal } from 'antd';
import { Link } from "react-router-dom";
import axios from 'axios';
import TemplateExterno from '../interfaz/TemplateExterno';
import contactoJDE from '../../img/contactoJDE.png';
import errorImgModal from '../../img/error_icon.svg';
import '../../css/contacto.css';
import {irAtras} from '../../utils/funciones';
import * as etiquetas from '../../ApplicationResources';

let msjError = "";

class Contacto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contactoInfo: [],
            msjError : "",
            abrirModalError : false
        };
    }

    irAtras = irAtras.bind(this);

    componentWillMount () {
        console.log(this.props.history.location.state.data);
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/contactoJDE'
        ,{
            idEstado: this.props.history.location.state.data
        },{headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        },).then(response => {
            if(response.data){
                this.setState({ contactoInfo : response.data });
            }
        }).catch(error => {
          console.error("Error al cargar el componente de contacto " + "componentWillMount()",error);
          if (error.response){
              if (error.response.status === 409){
                  msjError = error.response.data.message;
              } else if (error.response.data && error.response.data.causa
                  && error.response.data.causa.includes("Error de validación")){
                  msjError = error.response.data.message;
              } else {
                  msjError = etiquetas.gral_error_cau;
              }
          } else {
              msjError = etiquetas.gral_error_cau;
          }
          this.setState({
            msjError : msjError,
            abrirModalError : true
          });
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        console.log(this.state.contactoInfo);
        const contacto = this.state.contactoInfo.map((item) =>
                <li className="vinetaContacto"><p className="text_junta"><b id="titulo_junta">{item.nombreJunta}</b><br/>
                    <Icon id="icon-contacto" type="user" theme="outlined"/><b>Responsable: </b>{/*item.nombreContacto*/}<br/>
                    <Icon id="icon-contacto" type="environment" theme="outlined"/><b>Domicilio: </b>{item.domicilioJunta} <br/>
                    <Icon id="icon-contacto" type="phone" theme="outlined" rotate={90}/><b>Teléfono: </b> {/*item.telefono*/} <br/>
                    <Icon id="icon-contacto" type="mail" theme="outlined"/><b>Correo electrónico: </b> {/*item.correoElectronico */} <br/></p>
                    <hr></hr>
                </li>
            ) || {};
        return(
                <TemplateExterno>
                  <Modal
                    className="modalError"
                    title={<img  src={errorImgModal} id="img-error-modal"/>}
                    centered
                    onCancel={()=>this.cerrarErrorModal()}
                    visible={this.state.abrirModalError}
                    footer={false}
                    width={400}
                  >
                    {this.state.msjError}
                  </Modal>
                    <div className="contacto_container">
                        <Row>
                            <Col xs={8} sm={4} md={8} lg={11}>
                                    <img src={contactoJDE} id="contacto-img"/>
                            </Col>
                            <Col xs={8} sm={12} md={8} lg={10}>
                                {/*<Link to={`/modifica_correo`} className="btn btn-success mr-2">
                                    <Icon type="arrow-left" /> Regresar
                                </Link>*/}

                                <div className="lstJuntas">
                                    {contacto}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
                </TemplateExterno>
        );
    }
};

export default withRouter(Contacto);

import axios from 'axios';
import React from 'react'
import {Form} from 'antd';
import {general_dato_requerido, general_formato_invalido,REGEX_FORMATO_SIAP,
				REGEX_FORMATO_CLAVE_ELECTOR} from '../ApplicationResources';

const {Item} = Form;

export function change(e, target) {
	const valor = e.target.value;

	this.setState(state => {
		var peticion = state.peticion;
		peticion[target] = valor;

		return {peticion: peticion}
	});
};

export function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export function convertirAErrorApropiado(errors) {
	const keys = Object.keys(errors);
	const errorRes = {};

	for (var i = 0; i < keys.length; i++) {
		const llave = keys[i];
		errorRes[llave] = {};

		if (errors[llave].value !== undefined)
			errorRes[llave].value = errors[llave].value;

		if (errors[llave].error !== undefined)
			errorRes[llave].errors = [new Error(errors[llave].error), new Error('')];
	}

	return errorRes;
}

export function convertirAValorApropiado(valores) {
	const keys = Object.keys(valores);
	const valorRes = {};

	for (var i = 0; i < keys.length; i++) {
		const llave = keys[i];
		valorRes[llave] = {};
		valorRes[llave].value = valores[llave];
	}

	return valorRes;
}

export function funcComunComponent(antdInput, className, conEtiqueta = true, valuePropName = 'value') {
	const form = this.props.form;
	const {getFieldDecorator, getFieldError, isFieldTouched} = form;
	const fieldError = getFieldError(this.props.nombre);
	const lenError = fieldError !== undefined && fieldError.length > 1 && typeof fieldError[1] !== "string";
	const required = this.props.required || false;
	const campoError = lenError || ((form.haValidado && required || isFieldTouched(this.props.nombre)) && !this.props.disabled) && fieldError !== undefined;
	const msjRequerido = this.props.msjRequerido || general_dato_requerido;
	const mensajeValidacion = this.props.mensajeValidacion || general_formato_invalido;
	const fieldValue = form ? form.getFieldValue(this.props.nombre) : undefined;
	const estilo = typeof fieldValue !== 'undefined' ? 'transicion_terminada' : 'transicion' + (this.props.type ? ' transicion_prefijo' : '');
	const mensajeFinal = lenError ? fieldError[0] : (fieldValue ? mensajeValidacion : msjRequerido);

	const star = required ? <span className='star'>*</span> : null;
	const colon = <span className='colon_class'>:</span>

	var obj = {};
	Object.assign(obj, this.customRules);
	if (this.props.rules && this.props.rules.length > 0)
		Object.assign(obj, this.props.rules[0]);

	obj.required = required;
	obj.preserve = true;

	var custom = {};
	custom.trigger = 'onBlur';
	Object.assign(custom, this.custom);
	if (this.props.custom)
		Object.assign(custom, this.props.custom);

	if (this.props.validacionNegocio === true && form.customVal[this.props.nombre] === undefined)
		form.customVal[this.props.nombre] = false;
	else if (this.props.validacionNegocio === false)
		delete form.customVal[this.props.nombre];

	var props = {maxLength: this.props.maxLength,
				 autoSize: this.props.autoSize,
				 style: this.props.elementStyle};
	const copy = React.cloneElement(antdInput, props);

	const elementClass = ' ' + (this.props.elementClass || '');

	return (<div id={this.props.id || this.props.nombre} className={className + elementClass} style={this.props.style}>
				{this.props.label ? <span className={'label_' + className}>{star}{this.props.label}:</span>: null}
				<Item {...this.props.itemLayout}
					  validateStatus={campoError ? 'error' : ''}
				      help={mensajeFinal}>
					{getFieldDecorator(this.props.nombre, {
						rules: [obj],
						valuePropName: valuePropName,
						...custom
					})(copy)}
				</Item>
				{conEtiqueta ? (<span className={estilo}><div>{star}<label>{this.props.placeholder}</label>{colon}</div></span>) : null}
			</div>);
}

export function onBlurAndChange() {
	if (this.props.onBlur) {
		setTimeout(() => {
			const valor = this.props.form.getFieldValue(this.props.nombre);

			if (this.valorPrevio === undefined && valor === undefined)
				;
			else if (this.valorPrevio === undefined ||
					this.valorPrevio !== valor) {
				this.props.onBlur();
				this.valorPrevio = valor;
			}
		}, 0);
	}
}

export function handleChange() {
	if (this.state.cambio) {
		this.setState({cambio: false});

		const valor = this.props.form.getFieldValue(this.props.nombre);

		if (this.props.validationChange && !this.props.validationChange(valor)) {
			this.props.form.setFields({[this.props.nombre]: {value: this.state.valor}});
			return;
		}

		this.setState({valor: valor});

		if (this.props.onChange)
			this.props.onChange(valor);
	}
}

export function configuraComponente() {
	if (!this.state)
		this.state = {};

	this.customRules = {};
	this.custom = {};
	this.state.cambio = false;
	this.state.valor = undefined;
	this.state.errors = undefined;
	this.valueProp = 'value';

	this.funcComunComponent = funcComunComponent.bind(this);
	this.onBlurAndChange = onBlurAndChange.bind(this);
    this.handleChange = handleChange.bind(this);

	this.UNSAFE_componentWillReceiveProps = (nextProps) => {
		const errors = nextProps.form.getFieldError(this.props.nombre);
		this.setState({errors});
	}

	const shouldComponentUpdate = this.shouldComponentUpdate;
	this.shouldComponentUpdate = (nextProps, nextState) => {
		const fv = nextProps.form.getFieldValue(this.props.nombre);
		if (fv !== this.state.valor ||
			!comparaArreglos(this.state.errors, nextState.errors) ||
			cambiaronProps(this.props, nextProps))
			return true;

		return Boolean(shouldComponentUpdate) && shouldComponentUpdate(nextProps, nextState);
	}

    this.onChange = (e) => {
		var valorNuevo;

		if (typeof e !== 'object' || !e.target)
			valorNuevo = e;
		else
			valorNuevo = e.target[this.valueProp];

		this.setState({cambio: true, valor: valorNuevo});
		if (this.custom.trigger === '')
			this.props.form.setFields({[this.props.nombre]: {value: valorNuevo}});

		if (this.props.validacionNegocio)
		    this.props.form.customVal[this.props.nombre] = false;
	}

	const componentDidUpdate = this.componentDidUpdate ? this.componentDidUpdate.bind(this) : undefined;
	this.componentDidUpdate = (prevProps) => {
		if (prevProps.required !== this.props.required)
			this.props.form.validateFields([this.props.nombre]);

		this.handleChange();

		componentDidUpdate && componentDidUpdate(prevProps);
	}
}

export function configuraCompInterno() {
	this.state = {
		value: undefined,
		valor: undefined,
	}

	this.valueProp = 'value';

	this.onChange = (e) => {
		var valorNuevo;

		//Si tiene reglas personalizadas para este componente...
		if (this.props.custom) {
			const custom = this.props.custom;
			if (custom.getValueFromEvent) {
				valorNuevo = custom.getValueFromEvent(e, this.state.valor);
				if (this.state.valor === valorNuevo) //Por personalizar
					return;
			}
		}

		var val;
		if (typeof e !== 'object' || !e.target)
			val = e;
		else
			val = e.target[this.valueProp];

		this.setState({valor: valorNuevo || val});

		if (this.props._onChange)
			this.props._onChange(e);
	}

	this.UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value,
				valor: nextProps.value
			});
		}
	}
}

export function valueUpperCase (e) {
	return e.target.value.toUpperCase();
};

export function cargarAspirante(folioAcceso) {
	return new Promise ((resolver, rechazar) => {
		const jwt = localStorage.getItem('jwt-token');

		axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/consulta_registro_aspirante', {
			folioAcceso: folioAcceso + ''
		},
		{
			headers: {Authorization: jwt}
		}).then(res => {
			resolver(res.data);
		}).catch(_error => {
			rechazar()
		});
	});
}

export function irAtras(nueva) {
	if (this.props.history.action === 'PUSH')
		this.props.history.goBack();
	else
		this.props.history.push(nueva);
}

export function toggleMostrar(e, toggleProp) {
	e.preventDefault();

	this.setState(state => {
		return {[toggleProp]: !state[toggleProp]};
	});
}

export function establecerIniciales() {
	if (!this.state.inicialesEstablecidos) {
		const iniciales = this.props.form.getFieldsValue();
		this.valoresIniciales = iniciales;
		this.setState({inicialesEstablecidos: true});
	}
}

export function compararValores(callbackCambio, callbackRegreso) {
	if (this.state.inicialesEstablecidos) {
		const actuales = this.props.form.getFieldsValue();

		const cambio = Object.entries(this.valoresIniciales).some(entry => {
			return actuales[entry[0]] !== entry[1];
		});

		if (cambio)
			callbackCambio();
		else
			callbackRegreso();
	}
}

export function comparaArreglos(arr1, arr2) {
	if (!arr1 && !arr2)
		return true;

	if (!arr1 && arr2 || arr1 && !arr2)
		return false;

	if (arr1.length !== arr2.length || typeof arr1.length !== "number")
		return false;

	for (var i = 0; i < arr1.length; i++)
		if (arr1[i] !== arr2[i])
			return false;

	return true;
}

//¿Cambiaron props distintos del 'form'?
export function cambiaronProps(oldProps, nextProps) {
	const llaves1 = Object.keys(oldProps);
	const llaves2 = Object.keys(nextProps);

	if (llaves1.length !== llaves2.length)
		return true;

	return llaves1.some(llave => {
		if (llave === 'form')
			return false;

		return !(llave in nextProps) ||
			   nextProps[llave] !== oldProps[llave]
	});
}

export  function obtenerEstado(idEstado){
	var estados = {
				0:" ",1 : "AGUASCALIENTES", 2 : "BAJA CALIFORNIA",
				3 : "BAJA CALIFORNIA SUR", 4 : "CAMPECHE",
				5 : "COAHUILA", 6 : "COLIMA", 7 : "CHIAPAS",
				8 : "CHIHUAHUA",9 : "CIUDAD DE MÉXICO", 10 : "DURANGO", 11: "GUANAJUATO"
				,12 : "GUERRERO",
				13 : "HIDALGO", 14 : "JALISCO", 15 : "MÉXICO", 16 : "MICHOACÁN",
				17 : "MORELOS",	18 : "NAYARIT", 19 : "NUEVO LEÓN", 20 : "OAXACA",
				21 : "PUEBLA", 22 : "QUERÉTARO", 23 : "QUINTANA ROO",
				24 : "SAN LUIS POTOSÍ", 25 : "SINALOA", 26 : "SONORA", 27 : "TABASCO",
				28 : "TAMAULIPAS", 29 : "TLAXCALA", 30 : "VERACRUZ", 31 : "YUCATÁN",
				32 : "ZACATECAS"
	}
	return estados[idEstado] !== "" ? estados[idEstado] : "";

}

export  function obtenerEstadoPorClave(clave){
	var estados = {
				"AS" : "AGUASCALIENTES", "BC" : "BAJA CALIFORNIA",
				"BS" : "BAJA CALIFORNIA SUR", "CC" : "CAMPECHE","CL" : "COAHUILA",
				"CM" : "COLIMA", "CS" : "CHIAPAS", "CH" : "CHIHUAHUA",
				"DF" : "CIUDAD DE MÉXICO", "DG" : "DURANGO", "GT": "GUANAJUATO",
				"GR" : "GUERRERO","HG" : "HIDALGO", "JC" : "JALISCO", "MC" : "MÉXICO",
				"MN" : "MICHOACÁN",	"MS" : "MORELOS",	"NT" : "NAYARIT", "NL" : "NUEVO LEÓN",
				"OC" : "OAXACA","PL" : "PUEBLA", "QT" : "QUERÉTARO", "QR" : "QUINTANA ROO",
				"SP" : "SAN LUIS POTOSÍ", "SL" : "SINALOA", "SR" : "SONORA",
				"TC" : "TABASCO","TS" : "TAMAULIPAS", "TL" : "TLAXCALA",
				"VZ" : "VERACRUZ", "YN" : "YUCATÁN","ZS" : "ZACATECAS",
				"NE" : "Nacido en el extranjero"
	}
	return estados[clave] !== "" ? estados[clave] : "";

}

export function obtenerFormatoDistrito(idDistrito){
	if (idDistrito !== null) {
		if (idDistrito === 0 || idDistrito === '0') {
			return '';
		}
		if (idDistrito.toString().length === 1) {
			return "0"+idDistrito;
		}
		if(idDistrito.toString().length > 1){
			return idDistrito;
		}
	}

}

/*
* obtien los valores de la posición 13 a la 14
* para obtener la entidad de la clave de elector
*/
export function obtenerEntidadDeClaveE(claveE){
	var claveElector = claveE;
	var entidad = claveElector.substring(12,14);

	return entidad;
}

/**
* valida que la clave ingresada sea SIAP o una clave de elector
* 1 = clave elector 2 = SIAP 0 = valor incorrecto
**/
export function esClaveEoSiap(claveSiap){
	if (claveSiap === null || claveSiap === undefined) {
		return 0;
	}
	var regexClaveE = REGEX_FORMATO_CLAVE_ELECTOR;
	var regexSiap = REGEX_FORMATO_SIAP;

	var esClave = claveSiap.search(regexClaveE);
	var esSiap = claveSiap.search(regexSiap);

	if (esClave === 0 && esSiap === -1) {
		return 1;
	}else if(esClave === -1 && esSiap === 0) {
		return 2;
	}else{
		return 0;
	}
}

import React from 'react'
import '../../css/progreso_carga.css';

function ProgresoCargaDocs(props) {
    return (
        <div className='progreso_carga'>
            <span className='progresoCarga' style={{width: props.porcentaje + '%'}}></span>
            <span className="megas_carga"><b>{(props.megas).toFixed(3)}MB / 15MB</b></span>
        </div>
    );
}

export default ProgresoCargaDocs;
import React from 'react';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import moment from 'moment';
import * as etiquetas from '../../ApplicationResources';
import finalizadoSede from '../../img/info10_Sede_examen.png';
import finalizadoSinSede from '../../img/info04_Documentos_validos.png';
import finalizadoPorValidar from '../../img/info13_documentos_en_validacion.png';

class Finalizado extends React.Component {
    state = {
        cargado: false,
    }

    constructor(props) {
        super(props);
    }

    listo = () => {
        const datosAspirante = this.props.datosAspirante || {};

        this.espera = (datosAspirante.estatusRegistro === 2 &&
                       !datosAspirante.bObservacion &&
                       datosAspirante.bPlatica === 0);

        if (datosAspirante.estatusRegistro === 3 ||
            this.espera)
            ;
        else
            this.props.history.push('/acceso');

        /*if (datosAspirante.estatusRegistro !== 3 || datosAspirante.idSedeProgramada)
            this.props.emitirMensaje({
                id: 'mensajeUsuario',
                tipoMensaje: (this.espera ? 'info' :'ok'),
                contenido: (this.espera ? etiquetas.gral_validando_documentos :
                                           etiquetas.gral_documentos_validados),
            });

        if(this.props.datosUsuario.tipoUsuario === 'I'){
            this.props.emitirMensaje({
                id: 'mensajeUsuario',
                tipoMensaje: ' ',
                contenido: ' ',
            });
        }*/

        this.setState({cargado: true});
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        const fechaProgramada = datosAspirante.fechaProgramada ? moment(datosAspirante.fechaProgramada, 'DD/MM/YYYY').format("DD [de] MMMM [de] YYYY") : null;

        return(
            <AuthenticatedComponent listo={this.listo}>
                <Template guia = {tipoUsuario === 'E'} contenido={
                    <div className="exito_container indicaciones_finalizado">
                        <div>
                            {/*<span className='exito_titulo'>
                                <span className='cara_feliz'>
                                    :)
                                </span>
                                Has finalizado
                            </span>*/}
                            <ul id='indicaciones_finalizado'>
                                {datosAspirante.idSedeProgramada ?
                                    <>
                                        <center>
                                            <p/>
                                            <img src={finalizadoSede} alt="FinalizadoSede" width="100%" height="100%"/>
                                            <p/>
                                        </center>
                                        {/*<li>Acude en la fecha y hora indicada para presentar tu examen.</li>*/}
                                        <li>
                                            <span><b>Lugar</b>: {datosAspirante.lugarSede}</span>
                                            <span><b>Fecha</b>: {fechaProgramada}</span>
                                            <span><b>Hora</b>: {datosAspirante.horaProgramada} hrs</span>
                                        </li>
                                        <br/>
                                        <div style={{'fontSize': '16px'}}>
											<span><font color="red"><b>¡Es importante contar con tu presencia 40 minutos antes de la hora citada para dar inicio puntualmente con la aplicación del examen!</b></font></span>
										</div>
                                        <br/><br/>
                                    </>
                                :
                                    datosAspirante.bObservacion === 0 ?
                                    <>
                                        <center>
                                            <p/>
                                            <img src={finalizadoSinSede} alt="FinalizadoSinSede" width="100%" height="80%"/>
                                            <p/>
                                        </center>
                                        <li> Espera a que te sean asignados lugar, fecha y hora para la presentación de tu examen.</li>
                                    </>
                                    :
                                    <>
                                    <center style={{'padding':'50px'}}>
                                         <p/>
                                        <img src={finalizadoPorValidar} alt="FinalizadoPorValidar" width="100%" height="100%"/>
                                        <p/>
                                    </center>
                                    </>
                                }
                            </ul>

                            {tipoUsuario === 'I' ?
                            <center>
                                <Link to={`/bandeja`}className="btn btn-success mr-2" style={{fontSize: '1.5em'}}>
                                    Regresar a bandeja
                                </Link>
                            </center>
                            : null}
                        </div>
                    </div>
                }
                contenidoExpandido={true}
                bloqueado={!this.state.cargado}/>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if(state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    else
        datosAspirante = {estatusRegistro: 0};

    return {
        datosAspirante: datosAspirante,
        datosUsuario : datosUsuario
    }
}

export default connect(mapStateToProps)(Finalizado);

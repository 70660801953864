import React from 'react'
import {Carousel, Layout} from 'antd'
import TemplateExterno from './interfaz/TemplateExterno'
import {Link} from "react-router-dom"
import AuthenticatedComponent from './AuthenticatedComponent'
import animacionRecluta from '../img/animacion_recluta.mp4';
import btn_consulta from '../img/btn_consulta.png';
import btn_registro from '../img/btn_registro.png';
import btn_sesion from '../img/btn_sesion.png';
import no_correo from '../img/no_correo.png';
import icon_info from '../img/info_icon.png';
import Pdf from '../documentos/convocatoria_PE2021.pdf';
import guiaAspirante from '../documentos/guia_aspirante.pdf';

const {Sider, Content} = Layout;


function Home() {
	return (
		<AuthenticatedComponent privado={false}>
			<TemplateExterno bloquearTodo={true} >
				<React.Fragment>
					<Layout className='layout_home_login'>
						<Content id="carousel-home-web">
							<div id='p2' className='pantalla_login'>
								<video	src={animacionRecluta} autoPlay loop muted id="carousel-login" ></video>
							</div>
							</Content>

						<Sider width='40%' theme='light'>
							<div id="menu-home-lateral">
								<div id="seccion-sesion">
									<img src={ btn_sesion} id="btn_sesion"
											 alt="Imagen descriptiva"/>
									<div className="label_div">
										<label>¿Ya est&aacute;s registrado?</label>
										<Link to='/acceso'>Inicia sesi&oacute;n</Link>
									</div>
								</div>
								<hr className ="division_hprozontal"/>
								<div id="seccion-registro">
									<img src={btn_registro} id="btn_registro"
											 alt="Imagen descriptiva"/>
									<div className="label_div">
										<label>¿No te has registrado?</label>
										<Link to='/registro_aspirante'>Regístrate ahora</Link>
									</div>
								</div>
								<hr className ="division_hprozontal"/>
								<div id="seccion-convoca">
									<img src={btn_consulta} id="btn_consulta"
											 alt="Imagen descriptiva"/>
									<div className="label_div">
										<label>¿Conoces la convocatoria?</label>
										<a href= {Pdf} target = "_blank" rel="noopener noreferrer">
											Cons&uacute;ltala aqu&iacute;
										</a>
									</div>
								</div>
								<div id="no-correo-seccion">
									<img src={no_correo} id="img-correo" alt="Imagen descriptiva"/>
									<Link to="/modifica_correo" id="txt-no-correo">
										No recib&iacute; correo electr&oacute;nico
									</Link>
								</div>
								<div id="guia-aspirante">
									<img src={icon_info} id="img-info" alt="Imagen descriptiva"/>
									<a href= {guiaAspirante} target = "_blank" rel="noopener noreferrer" id="linkPlaticas">
										Gu&iacute;a del aspirante
									</a>
								</div>
							</div>
						</Sider>
					</Layout>
				</React.Fragment>
			</TemplateExterno>
		</AuthenticatedComponent>
	);
};



export default Home;

import React, {Component} from 'react';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import {Button, Form, Layout} from 'antd';
import InputText from './interfaz/InputText.js';
import {establecerSesion, terminarSesion} from '../redux/actions/accionesSesion';
import {connect} from 'react-redux';
import {change} from '../utils/funciones.js';
import {Link} from "react-router-dom"
import AuthenticatedComponent from './AuthenticatedComponent'
import animacionRecluta from '../img/animacion_recluta.mp4';
import imgLogo from '../img/1_login.png'
import TemplateExterno from './interfaz/TemplateExterno';

import * as etiquetas from '../ApplicationResources';

//Llave creada con el correo reclutaseycae@gmail.com
const CAP_KEY = "6LdAcewUAAAAADACE_abwqHcxwQdcGr8fFvv577S"
const {Sider, Content} = Layout;
let recaptchaInstance;



class Login extends Component {
	state = {
		peticion: {},
		isVerified: false,
		mensajeError: '',
		username: undefined,
		password: undefined,
		cargando: false
	}

	constructor(props) {
		super(props);

		this.change = change.bind(this);
		this.submit = this.submit.bind(this);
		this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
		this.verifyCallback = this.verifyCallback.bind(this);
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
        nextProps.form.haValidado = this.props.form.haValidado;
	}

	componentDidMount() {
		this.props.form.validateFields();
	}

  executeCaptcha () {
    recaptchaInstance.execute();
  };



	submit() {
		this.props.form.validateFields((err, values) => {
			this.props.form.haValidado = true;
			if (!err){

        if (this.state.isVerified) {

          values["usuario"] = values["usuario"].trim();
    			values["password"] = values["password"].trim();

    			values["idSistema"] = "208";

    			this.setState({cargando: true});

    			axios.post(process.env.REACT_APP_API_URL +
                    '/centralReclutaSEyCAE/ws/validateUser', values).then(res => {
    				this.setState({cargando: false});
    				const datos = res.data.entity.datosUsuario;
    				const entidad = res.data.entity;

    				if (datos && datos.code !== 400) {

    					localStorage.setItem('jwt-token', datos.tknJWT);

    					this.props.establecerSesion({
    						datosUsuario: datos,
    					});

    					//Direccionando por tipo de usuario
    					if(datos.tipoUsuario === 'I')
    						this.props.history.push('/menuJDE');
    					else {
    						if (datos.datosAspirante.estatusRegistro === 1)
    							this.props.history.push('/cargaDocumentos');
    						else if (datos.datosAspirante.estatusRegistro === 2) {
    							if (datos.datosAspirante.bObservacion)
    								this.props.history.push('/cargaDocumentos');
    							else if (typeof datos.datosAspirante.bObservacion !== "number" &&
    									 datos.datosAspirante.bPlatica === 0)
    								this.props.history.push('/finalizado');
    							else
    								this.props.history.push('/platica_induccion');
    						}
    						else if (datos.datosAspirante.estatusRegistro === 3)
    							this.props.history.push('/finalizado');
    						else if (datos.datosAspirante.estatusRegistro === 4)
    							this.props.history.push('/solicitud_declinada');
    						else
    							this.props.history.push('/enConstruccion');
    					}
    				}
    				else {
    					this.props.history.push('/acceso');
    					this.props.terminarSesion();

    					if(entidad.code === 400){
    						this.setState({
    							//Asignando mensajes de error.
    							mensajeError: entidad.message
    						})
    					}
    				}
    			}).catch(() => {
    				this.setState({cargando: false});
    			});
        }
      }
		});
	}

	recaptchaLoaded(){
		console.log('captcha cargado satisfactoriamente...');
	}

	verifyCallback(response){
		console.log("Verificando token de captcha..."+response);
		if(response){
			this.setState({
			     isVerified:true
			})
		}
    this.submit();
	}

	render() {
		return (
			<AuthenticatedComponent privado={false}>
				<TemplateExterno enlaces={false} bloquearTodo={true} bloqueado={this.state.cargando}>
					<React.Fragment>
						<Layout className='layout_home'>
							<Content id="diapositivas">
								<div id='p2' className='pantalla'>
									<video	src={animacionRecluta} autoPlay loop muted id="carousel-login"></video>
								</div>
							</Content>
							<Sider width='35%' theme='light' id="sider-login">
								<div className="div_login_m">
									<div id='log_img'>
										<img src={imgLogo} alt=""/>
									</div>
									<Form id="form-login">
										<div id='usr_div'>
											<InputText  nombre="usuario"
														form={this.props.form}
														style={{width: '100%'}}
														type="user"
														inputType="text"
														required={true}
														onChange={(valor)=>{this.setState({username:valor})}}
														placeholder={etiquetas.login_lbl_usuario}/>
										</div>
										<div id='pws_div'>
											<InputText 	nombre="password"
														form={this.props.form}
														style={{width: '100%'}}
														type="lock"
														inputType="password"
														required={true}
														onChange={(valor)=>{this.setState({password:valor})}}
														placeholder={etiquetas.login_lbl_constrasenia}
														title={etiquetas.login_msg_constrasenia}/>
										</div>
										<div>
											<Button type="primary"
													className="button_style"
													id="btn-sesion"
													onClick={this.executeCaptcha}>
													{etiquetas.login_btn_inicioSesion}
											</Button>
										</div>
										<div className='capcha_container'>
											<Recaptcha
												ref={e => recaptchaInstance = e}
												sitekey={CAP_KEY}
												verifyCallback={this.verifyCallback}
												onloadCallback={this.recaptchaLoaded}
												size="invisible"
											/>
										</div>
										{false ?
											<div id='registrate_login' className='registrate'>
												<span>¿Eres nuevo?</span>
												<Link to='/registro_aspirante'>Regístrate ahora</Link>
											</div>
											: null
										}
										<div>
											<span><font color="red">{this.state.mensajeError}</font></span>
										</div>
									</Form>
								</div>
							</Sider>
						</Layout>
					</React.Fragment>
				</TemplateExterno>
			</AuthenticatedComponent>
		);
	}
}

const mapDispatchToProps = {
	establecerSesion,
	terminarSesion
};

const Login2 = Form.create({name: 'login_form'})(Login);

export default connect(_=> {return {}}, mapDispatchToProps)(Login2);

import React, {Component} from 'react'
import "antd/dist/antd.css";
import {Button} from 'antd';
import Template from '../interfaz/Template';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux';
import AuthenticatedComponent from '../AuthenticatedComponent'
import {registrarTerminado} from '../../redux/actions/accionesSesion'
import exitoDocumentos from '../../img/info03_Documentos_adjuntos.png'; 

import EnlaceDescarga from '../interfaz/EnlaceDescarga';

export class ExitoDocumentos extends Component{
    enviarACapsula(e){
        this.props.history.push('/platica_induccion');
      }
      
      componentDidMount() {

    }

    componentWillUnmount() {
        this.props.registrarTerminado(false);
    }
    
    funcionListo= () => {
        if(this.props.datosUsuario.tipoUsuario === 'E'){
            this.props.emitirMensaje(
                {
                    id: 'mensajeUsuario',
                    tipoMensaje: 'info',
                    contenido: 'Validación de documentos en proceso.',
                }
            )
        } 

        if (!this.props.ventanaExito)
            this.props.history.push('/cargaDocumentos');
    }

    render(){
        const datosAspirante = this.props.datosAspirante || {};
        const datosUsuario = this.props.datosUsuario || {};
        //Está pendiente que se confirme si será dinámico el botón.
        // const botonPlatica = this.props.datosAspirante.bPlatica === null?
        //                 <React.Fragment>
        //                     <p>Continúa con la:</p>
        //                     <Button type="primary" 
        //                             className="button_style"
        //                             style={{width:220}} 
        //                             onClick={(e)=>this.enviarACapsula(e)}>Cápsula de inducción
        //                     </Button>
        //                 </React.Fragment>:"";
        const mensajeCorreoElectronico = datosAspirante.correo !== null?
                                         'Recibirás un Acuse con tu información por correo electrónico.':""; 


        return(
            <AuthenticatedComponent listo={this.funcionListo}>
                <Template contenido={
                    <React.Fragment>
                        <center>
                        <div id="infoDocs">
                            <img src={exitoDocumentos} alt="ExitoDocumentos" width="70%" height="60%"/>
                            <Button type="primary" 
                                    id="buttonPlatica"
                                    style={{width:170}} 
                                    onClick={(e)=>this.enviarACapsula(e)}>Cápsula de inducción
                            </Button>
                        </div>
                            <br/>
                            <p></p>
                            &nbsp;&nbsp;&nbsp;
                            <EnlaceDescarga urlDoc={process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse'} data = {{
                                    idProcesoElectoral :this.props.datosAspirante.idProcesoElectoral,
                                    idDetalleProceso   :this.props.datosAspirante.idDetalleProceso,
                                    idParticipacion    :this.props.datosAspirante.idParticipacion,
                                    idConvocatoria     :this.props.datosAspirante.idConvocatoria,
                                    folio :this.props.datosAspirante.folio,
                                    claveElector:this.props.datosAspirante.claveElectorFuar,
                                    tipoAcuse : 1
                            }}
								nombreDescarga='Acuse Documentos.pdf'
                                boton = 'true'
                                style = {{width:220}}>
								Descargar acuse
							</EnlaceDescarga>
                            <p></p><p></p><p></p>
                        </center>
                    </React.Fragment>
                }
                guia={datosUsuario.tipoUsuario==='E'?true:false}
                /> 
            </AuthenticatedComponent>
        )
    } 
}

const mapStateToProps = state => {
    var datosAspirante;
    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;

    
    return {
        ventanaExito: state.sesion.exito,
        datosAspirante: datosAspirante,
        //Para obtener los datos de sesion del usuario    
        datosUsuario: state.sesion.datosUsuario,   
    }
}

const mapDispatchToProps = {
    emitirMensaje,
    registrarTerminado
};



export default connect(mapStateToProps, mapDispatchToProps)(ExitoDocumentos);

import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Input, Button, Table, Modal, Popover } from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import ayudaBAcudirJDE from '../../../img/ayudaBAcudirJDE.png';
import ayudaAsignar from '../../../img/ayudaAsignar.png';
import * as etiquetas from '../../../ApplicationResources';
import { cargarAspirante } from "../../../utils/funciones";
import { implantarAspirante } from "../../../redux/actions/accionesSesion";
import { emitirOperacion } from "../../../redux/actions/accionesOperacion";
import { connect } from "react-redux";
import axios from "axios";
const { Column } = Table;

/* Este componente es la tabla que muestra el listado  de cada pestania
seleccionada en la  bandeja de entrada*/

function TablaBandeja({
  datos,
  banderaVisualizar,
  keyTabPane,
  tituloTabla,
  implantarAspirante,
  history,
  tipoUsuario,
  emitirOperacion,
  rolCaptura
}) {
  //State que se usa
  const [state, setState] = useState({ searchText: "" });
  const [visible, hacerVisible] = useState({
    visible: false,
    confirmDialog: false,
    conCompulsa: 1
  });
  const [visibleRepetido, hacerVisibleRepetido] = useState({
    visibleRepetido: false,
    confirmDialog: false,
  });
  const [aspirante, autorizarAspirante] = useState({
    folioAcceso: "",
    claveElector: "",
    idConvocatoria: ""
  });
  const [exito, setExito] = useState(false);
  const [doc, agregaDoc] = useState({});
  /*Metodos para el manejo de tablas */

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ searchText: selectedKeys[0] });
  };

  var handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: "" });
  };

  const functionFilterIcon = filtered => (
    <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
  );
  const functionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar `}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    );
  };

  const functionOnFilterDropdownVisibleChange = visible => {
  };

  const functionRender = text => {
    return (
      text + ""
    );
  };

  const asignarSedePantalla = async () => {
    //debugger;
    hacerVisible({ visible: false, confirmDialog: true });
    cargarAspirante(aspirante.folioAcceso).then(datosAspirante => {
      implantarAspirante(datosAspirante);
      emitirOperacion({
        accion: "asignaSede"
      });
      history.push("/validacion_documentos");
    });
  };


  const consultar = async () => {
    var postData = {
      folioAcceso: aspirante.folioAcceso,
      claveElector: aspirante.claveElector,
      flujoCompulsa: aspirante.conCompulsa,
      idProceso: aspirante.idProceso,
      idDetalle: aspirante.idDetalle,
      idParticipacion: aspirante.idParticipacion
    };
    const jwt = localStorage.getItem("jwt-token");
    //si tiene compulsa tiene que actualizar BD  mostrar pantallla de exito
    if (aspirante.conCompulsa === 1) {
      const resultado = await axios.post(
        process.env.REACT_APP_API_URL +
        "/centralReclutaSEyCAE/ws/autoriza_aspirante",
        postData,
        { headers: { Authorization: jwt } }
      );
    }

    hacerVisible({ visible: false, confirmDialog: true });

    cargarAspirante(aspirante.folioAcceso).then(datosAspirante => {
      implantarAspirante(datosAspirante);

      if (aspirante.conCompulsa === 1) {
        history.push(`/exito_autoriza/tipoAspirante/${aspirante.conCompulsa}&${aspirante.folioAcceso}`);
      }

      // si no se tiene compulsa por descargar entonces es aspirante que ya ha subido su documentacion
      if (aspirante.conCompulsa === 0 || aspirante.conCompulsa === null) {
        emitirOperacion({
          accion: "captura"
        });
        //verifica si la clave de elector esta repetida en la misma convocatoria
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/esClaveRepetida',  {
          idProcesoElectoral: aspirante.idProceso,
          idDetalleProceso: aspirante.idDetalle,
          idParticipacion: aspirante.idParticipacion,
          idConvocatoria: aspirante.idConvocatoria,
          folio: aspirante.folio,
          claveElectorFuar: aspirante.claveElector
        },{
          responseType: 'application/json',
          headers : {Authorization: jwt}
        }).then(res => {
          console.log(res.data);
          if(res.data.esClaveRepetida === true){
            keyTabPane === "3" ? 
            history.push("/validacion_documentos") :
            hacerVisibleRepetido({visibleRepetido: true, confirmDialog: true});
          }else{
            history.push("/validacion_documentos");
          }
        });
      }
    });
  };
  const descargarCompulsa = async (record) => {
    cargarAspirante(record.folioAcceso).then(datosAspirante => {
      implantarAspirante(datosAspirante);

      const idProcesoElectoral = record.idProceso;
      const idDetalleProceso = record.idDetalle;
      const idParticipacion = record.idParticipacion;
      const idConvocatoria = record.idConvocatoria;
      const folio = record.folio;
      const claveElector = record.claveElector;
      const idAspirante = datosAspirante.idAspirante;

      axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse', {
                  idProcesoElectoral: idProcesoElectoral,
                  idDetalleProceso: idDetalleProceso,
                  idParticipacion: idParticipacion,
                  idConvocatoria: idConvocatoria,
                  folio: folio,
                  claveElector: claveElector,
                  idAspirante: idAspirante
      }, {
          responseType: 'blob',
          transformResponse: [data => {
            let blob = new window.Blob([data], { type: "application/pdf" });
            var srcURLObject = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', `Compulsa_${claveElector}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            agregaDoc(srcURLObject)
          }]
        },
      ).then(res => { })
    });
  };
  const mostrarModal = async (record) => {

    autorizarAspirante({
      folioAcceso: record.folioAcceso,
      claveElector: record.claveElector,
      conCompulsa: record.conCompulsa,
      idProceso: record.idProceso,
      idDetalle: record.idDetalle,
      idParticipacion: record.idParticipacion,
      idConvocatoria: record.idConvocatoria,
      folio: record.folio
    });
    hacerVisible({ visible: true, conCompulsa: record.conCompulsa });
  };
  const aceptar = async () => {
    keyTabPane === "5" ? asignarSedePantalla() : consultar();
  };
  const descargar = async () => {
    descargarCompulsa();
  };
  const cancelar = async () => {
    autorizarAspirante({});
    hacerVisible({ visible: false, conCompulsa: 1 });
  };
  const cerrarRepetido = async () => {
    autorizarAspirante({});
    hacerVisibleRepetido({visibleRepetido: false});
  };

  const etiquetaDescargaCompulsa = (text, record) => {
    var esClaveElectorFuar = etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(record.claveElector);
    return (
      record.conCompulsa === 1 || record.conCompulsa === 0 || record.conCompulsa === null ?
        <span>
          <a href="#" disabled={esClaveElectorFuar}
            onClick={() => descargarCompulsa(record)}
            size="small"
            style={{ width: 90, textDecorationLine: 'underline' }}
          >
            Descargar
          </a>
        </span>
        :
        <span></span>
    );
  };

  const etiquetaAutorizar = (text, record) => {
    return (
      record.conCompulsa === 1 ?
          <span>
            <Link onClick={() => mostrarModal(record)} size="small" style={{ width: 180, textDecorationLine: 'underline' }} disabled={!rolCaptura} >
              Autorizar
            </Link>
          </span> :
          <span>
            <Link onClick={() => mostrarModal(record)} size="small" style={{ width: 180, textDecorationLine: 'underline' }} >
              Revisar
            </Link>
          </span>
    );
  };

  const accionAutorizar = () => {
    return (<>
      <span>Acción&nbsp;</span>
      <Popover placement="bottomRight"
               disabled={false}
               content={<img src={ayudaBAcudirJDE} width='500px' />}>
        <marginRight>
          <Icon type="info-circle" theme="filled" style={{ color: '#bfbfbf' }} />
        </marginRight>
      </Popover>
      </>
    );
  };

  const etiquetaObservaciones = (text, record) =>
    record.observacionesArchivos === true ? (
      <span>
        <Icon type="reload" theme="outlined" style={{ fontSize: "16px", color: "#08c" }} />
        Con observaciones
      </span>
    ) : (
        <span></span>
      );

  const etiquetasAcciones = (text, record) => {
    return (
      <span>
        <Link to=""
            className="btn btn-success mr-2"
            onClick={() =>
              cargarAspirante(record.folioAcceso).then(datosAspirante => {
                emitirOperacion({
                  accion: keyTabPane === "1" ? "captura" : "consulta"
                });
                implantarAspirante(datosAspirante);

                history.push("/validacion_documentos");
              })
            }
            style={{textDecorationLine: 'underline'}}
          >
          {keyTabPane === "1" ? "Validar": "Ver detalle"}
        </Link>
      </span>
    );
  };

  const accionAsignarSede = () => {
    return (<>
      <span>Asignar sede&nbsp;</span>
      <Popover placement="bottomRight"
               disabled={false}
               content={<img src={ayudaAsignar} width='500px' />}>
        <marginRight>
          <Icon type="info-circle" theme="filled" style={{ color: '#bfbfbf' }} />
        </marginRight>
      </Popover>
      </>
    );
  };

  const etiquetaAsignarSede = (text, record) => {
    return (
      record.idSedeProgramada !== null ?
          <span>
            <Link size="small" style={{ width: 180, textDecorationLine: 'underline', color: '#bfbfbf', cursor: 'no-drop' }} >
              Asignada
            </Link>
          </span> :
          <span>
            {record.idPlatica !== null || record.bPlatica !== null ?
              <Link onClick={() => mostrarModal(record)} size="small" style={{ width: 180, textDecorationLine: 'underline' }} >
                Asignar
              </Link>
            :
            <Link size="small" style={{ width: 180, textDecorationLine: 'underline', color: '#bfbfbf', cursor: 'no-drop' }} >
              Asignar
            </Link>}
          </span>
    );
  };

  const OC = 1;
  const JL = 2;

  /*   Pintar la tabla     */
  return (
    <Fragment key={keyTabPane}>

      {banderaVisualizar === true ? (
        <Table title={() => tituloTabla} dataSource={datos} scroll={{ x: 'max-content' }}>{console.log(datos)}
          {tipoUsuario === OC ? (
            <Column
              title="Estado"
              dataIndex="estado"
              key={`estado{keyTabPane}`}
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["estado"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
          ) : (
              <span></span>
            )}

          {tipoUsuario === OC || tipoUsuario === JL ? (
            <Column
              title="Distrito"
              dataIndex="distrito"
              key={`distrito{keyTabPane}`}
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["distrito"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
          ) : (
              <span></span>
            )}
          <Column
            title="Folio"
            dataIndex="folio"
            key={`folio{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["folio"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Nombre"
            dataIndex="nombre"
            key={`nombre{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["nombre"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Clave"
            dataIndex="claveElector"
            key={`claveElector{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["claveElector"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Registro"
            dataIndex="fechaSolicitud"
            key="fechaSolicitud"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            render={functionRender}
            onFilter={(value, record) => {
              return record["fechaSolicitud"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Sección"
            dataIndex="seccion"
            key="seccion"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            render={functionRender}
            onFilter={(value, record) => {
              return record["seccion"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Convocatoria"
            dataIndex="numeroConvocatoria"
            key="numeroConvocatoria"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            render={functionRender}
            onFilter={(value, record) => {
              return record["numeroConvocatoria"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Sede"
            dataIndex="numeroSede"
            key="numeroSede"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            render={functionRender}
            onFilter={(value, record) => {
              return record["numeroSede"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          {keyTabPane === "1" || keyTabPane === "2" ? (
            <Column title="" key="action" render={etiquetasAcciones} />
          ) : (
              null
            )}
          {keyTabPane === "1" ? (
            <Column title="" key="obs" render={etiquetaObservaciones} />
          ) : (
              null
            )}
          {keyTabPane === "3" || (keyTabPane === "5" && tipoUsuario !== OC) ? (
            <Column
              title="Compulsa"
              key="compulsa"
              render={etiquetaDescargaCompulsa}
            />
          ) : (
              null
            )}
          {keyTabPane === "3" ? (
            <Column title={accionAutorizar} key="autorizar" render={etiquetaAutorizar} />
          ) : (
              null
            )}
          {keyTabPane === "4" ? (
            <Column title="Acción" key="revertir" render={etiquetaAutorizar} />
          ) : (
              null
            )}
          {keyTabPane === "5" ? (
            <Column title={accionAsignarSede} key="asignarSede" render={etiquetaAsignarSede} />
          ) : (
              null
            )}
        </Table>
      ) : (
          <span></span>
        )}

      <div>
        <Modal title={null}
               visible={visible.visible}
               confirmLoading={visible.confirmDialog}
               onOk={aceptar}
               onCancel={cancelar}
               okText="Aceptar"
               cancelText="Cancelar"
               closable={false}
               footer={null}
        >
          {keyTabPane === "5" ? <div className="mensaje_confirmacion">Va a asignar sede ¿Desea continuar?</div> : visible.conCompulsa === 1 ?
                                <div className="mensaje_confirmacion">¿Estás seguro de autorizar el registro?</div> :
                                <div className="mensaje_confirmacion">Va a revisar la documentación ¿Desea continuar?</div>
          }
          <br />
          <div id="modal_botones">
            <Button className="botonesModal" onClick={aceptar}>
              Si
            </Button>
            <Button className="botonesModal" onClick={cancelar}>
              No
            </Button>
          </div>
        </Modal>
      </div>
      <div>
        <Modal title={null}
               visible={visibleRepetido.visibleRepetido}
               confirmLoading={visibleRepetido.confirmDialog}
               onCancel={cerrarRepetido}
               centered
               closable={true}
               footer={null}
        >
          <div className="mensaje_confirmacion" style={{margin:'5px 25px'}}>
            <span style={{ fontSize: '18px', textAlign: "justify"}}>
              El registro del aspirante fue declinado, por lo tanto, volvió a realizarlo y se encuentra actualmente con dicho registro activo. 
              Es por ello que a esta declinación, no podrás realizarle ninguna modificación.
            </span>
            <br/>
          </div>
        </Modal>
        </div>
    </Fragment>
  );
}

export default withRouter(
  connect(
    _ => {
      return {};
    },
    { implantarAspirante, emitirOperacion }
  )(TablaBandeja)
);

import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { Layout, Card, Row, Col, Form, Select as _Select, Button, Modal } from 'antd';
import axios from 'axios';
import "antd/dist/antd.css";
import { withRouter } from 'react-router-dom';
import Template from '../interfaz/Template';
import { connect } from 'react-redux';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import AuthenticatedComponent from '../AuthenticatedComponent'
import * as etiquetas from '../../ApplicationResources';
import errorImgModal from '../../img/error_icon.svg';

const { Header, Sider, Footer, Content } = Layout;
let msjError = "";

export class Platica extends Component {

    state = {
        cargado: false,
        tipoMsj: 'info',
        msj: 'Validación de documentos en proceso.',
        tipoVida: {},
        msjError: "",
        abrirModalError: false
    }

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);
        // Setting the initial state
        this.aspirante = this.props.getAspirante;
        this.submit = this.submit.bind(this);
    }

    getTiposVida = () => {
        var jSonTipoVida = {
            "idProcesoElectoral": this.props.datosAspirante.idProcesoElectoral,
            "idDetalleProceso": this.props.datosAspirante.idDetalleProceso,
            "idParticipacion": this.props.datosAspirante.idParticipacion
        }

        // Obtenido los datos del servidor
        const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtineTipoVida'
            , jSonTipoVida
            , {
                headers: {
                    'Accept': 'application/json'
                    , 'Content-Type': 'application/json'
                }
            }
        );
        response.then((res) => {
            console.log(res.data.entity.resultadoTipoVida)
            this.tipoVida = res.data.entity.resultadoTipoVida.tipoVida
            this.setState({ tipoVida: this.tipoVida })
        })
        return response.data;
    }

    getAspirante = () => {
        var jSonAspirante = {
            "idDetalleProceso": this.props.datosAspirante.idDetalleProceso,
            "idAspirante": this.props.datosAspirante.idAspirante,
            "idParticipacion": this.props.datosAspirante.idParticipacion
        }

        // Obtenido los datos del servidor
        const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtineAspirante'
            , jSonAspirante
            , {
                headers: {
                    'Accept': 'application/json'
                    , 'Content-Type': 'application/json'
                }
            }
        );
        response.then((res) => {
            console.log(res.data.entity.aspiranteRes)
            this.aspirante = res.data.entity.aspiranteRes.aspirante
            if (this.aspirante.estatusRegistro === 2 && this.aspirante.bObservacion === 1) {
                this.setState({ tipoMsj: 'warning' })
                this.setState({ msj: etiquetas.gral_observacion_documentos })
            } else if ((this.aspirante.estatusRegistro === 2 || this.aspirante.estatusRegistro === 3) && this.aspirante.bObservacion === 0) {
                this.setState({ tipoMsj: 'ok' })
                this.setState({ msj: etiquetas.gral_documentos_validados })
            }
            if (this.props.datosUsuario.tipoUsuario === 'I') {
                this.setState({ tipoMsj: '' })
                this.setState({ msj: '' })
            }

            this.props.emitirMensaje(
                {
                    id: 'mensajeUsuario',
                    tipoMensaje: this.state.tipoMsj,
                    contenido: this.state.msj,
                }
            );

        })
        return response.data;
    }

    ejecutaFunciones = () => {
        this.getTiposVida();
        this.getAspirante();
        this.setState({ cargado: true })
    }

    submit() {
        console.log('Entró al submit');
        this.setState({cargando: true});
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/guardarCapsulaInduccion', {
            idDetalleProceso : this.props.datosAspirante.idDetalleProceso,
            idParticipacion : this.props.datosAspirante.idParticipacion,
            idAspirante : this.props.datosAspirante.idAspirante
        },{
            headers: {
                'Content-Type': 'application/json'
                , 'Authorization': localStorage.getItem("jwt-token")        
            }
        }).then(res => {
            this.setState({cargando: false});
            if(res.status === 200)
                this.props.history.push('/Cuestionario');
        }).catch(error => {
            console.error("Error Platica", error);
            this.setState({cargando: false});
            msjError = 'Ocurrio un error al guardar la fecha de la cápsula de inducción';
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        const tipoVida = this.state.tipoVida || {};
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        return (
            <AuthenticatedComponent listo={this.ejecutaFunciones}>
                <Template guia={tipoUsuario === 'E'} bloqueado={!this.state.cargado} contenido={
                    <React.Fragment>
                        <Card title=" " bordered={false}>
                            <Row gutter={16}>
                                <Col sm={24} md={24} lg={24}>
                                    <h1>Ve la Plática de inducción</h1>
                                </Col>
                                <Col id='colRP' sm={24} md={24} lg={24}>
                                    <ReactPlayer
                                        url={tipoVida.urlCapsula}
                                        className='react-player'
                                        controls
                                        width='640px'
                                        height='360px'
                                    />
                                </Col>
                            </Row>

                            <Footer>
                                <Row>
                                    <Col xs={0} sm={2} md={8} lg={8}></Col>
                                    <Col id='colBtn' xs={24} sm={20} md={8} lg={8}>
                                        <Button type="primary"
                                            className="button_style"
                                            id="btnPlatica"
                                            style={{ width: 220 }}
                                            htmlType="submit"
                                            onClick={this.submit}
                                        >
                                            Realiza tu evaluación
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={2} md={8} lg={8}></Col>
                                </Row>
                            </Footer>

                        </Card>
                    </React.Fragment>
                } />
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
            </AuthenticatedComponent>
        )
    }
}


const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if (state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;

    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}
const msj = connect(mapStateToProps, { emitirMensaje })(Platica)
export default withRouter(Form.create({
    name: 'platicaInduccion'
})(msj));

import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import iconRegistro from "../../img/registra_icon.png";
import * as etiquetas from '../../ApplicationResources';
import iconReporte from "../../img/reporte_icon.png";
import iconValidar from "../../img/validar_icon.png";
import '../../css/menuMovil.css';
import axios from 'axios';

class MenuMovil extends Component{

  constructor(props) {
    super(props);
    this.state = {
      rutaPublicador:""
    }
    this.obtenerRutaPublicador();
  }

  obtenerRutaPublicador(){
      var datos = this.props.datosUsuario;
      var datosUsuario = { idEstado: datos.idEstado,
                        idDistrito: datos.idDistrito,
                        rolUsuario: datos.rolUsuario,
                        usuario: datos.username
                      };

      var postData = {
        idProceso : etiquetas.id_proceso_electoral,
        idDetalle : etiquetas.id_detalle_proceso,
        idEtiqueta : 54, 
        datosUsuario: datosUsuario
      }
      axios.post(process.env.REACT_APP_API_URL +
        "/centralReclutaSEyCAE/ws/obtenerRutaPublicador", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
            }
         ).then(response => {
          this.setState({
            rutaPublicador : response.data !== null ?
                                    response.data.replace(/['"]+/g, '') : ""
          });
      }).catch(error => {
        var msjError = "";
        console.error("Error al consultar la ruta del publicador "+
            "obtenerRutaPublicador()",error);
        if (error.response){
            if (error.response.status === 409){
                msjError = error.response.data.message;
            } else if (error.response.data && error.response.data.causa
                && error.response.data.causa.includes("Error de validación")){
                msjError = error.response.data.message;
            } else {
                msjError = etiquetas.gral_error_cau;
            }
        } else {
            msjError = etiquetas.gral_error_cau;
        }
        console.error( "error...", msjError);
      });
  }
  render() {
    return(
      <React.Fragment>
        <div className="element_option_menu_mov">
          <Link to={'/bandeja'} className="btn btn-success mr-2">
            <img src={iconValidar}/>
            <label className="label_lement_menu_mov">
              {etiquetas.menu_element_uno}
            </label>
          </Link>
        </div>
        <hr/>
        <div className="element_option_menu_mov">
          <Link to={'/bandeja'} className="btn btn-success mr-2">
            <img src={iconRegistro}/>
            <label className="label_lement_menu_mov">
              {etiquetas.menu_element_dos}
            </label>
          </Link>
        </div>
        <hr/>
        <div className="element_option_menu_mov">
          <a href={this.state.rutaPublicador}
             className="btn btn-success mr-2" target="_blank">
            <img src={iconReporte}/>
              <label className="label_lement_menu_mov">
                {etiquetas.menu_element_tres}
              </label>
            </a>
        </div>
        <hr/>
      </React.Fragment>
    );
  }
};

const mapStateToProps = state => {
  var datosUsuario;

  if (state.sesion.datosUsuario) {
      datosUsuario = state.sesion.datosUsuario;
  }

  return {
      datosUsuario: datosUsuario,
  };
}

export default connect(mapStateToProps)(MenuMovil);

import React from 'react';
import {Card, Row, Col, message, Modal} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import InputText from '../interfaz/InputText';
import Select, {Option} from '../interfaz/Select';
import errorImgModal from '../../img/error_icon.svg';

let msjError = "";

class DatosEscolaridad extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {
            lstEscolaridades : null
            , carreraRequired : false,
            msjError : "",
            abrirModalError : false
        };

    }

    cerrarErrorModal(){
      this.setState({abrirModalError:false});
    }

    componentWillMount() {
      this.props.getEscolaridades()
              .then(lstEscolaridades => this.setState({lstEscolaridades}))
              .catch(error => {
                console.error("Error al consultar las escolaridades "+
                    "componentWillMount()",error);
                if (error.response){
                    if (error.response.status === 409){
                        msjError = error.response.data.message;
                    } else if (error.response.data && error.response.data.causa
                        && error.response.data.causa.includes("Error de validación")){
                        msjError = error.response.data.message;
                    } else {
                        msjError = etiquetas.gral_error_cau;
                    }
                } else {
                    msjError = etiquetas.gral_error_cau;
                }
                this.setState({
                  msjError : msjError,
                  abrirModalError : true
                });
              });
    }

    render() {
        return(
           <React.Fragment>
              <>
                <Card title={etiquetas.datos_escolaridad_lbl_titulo} bordered={false}>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <Select nombre='idEscolaridad'
                                    placeholder={etiquetas.datos_escolaridad_lbl_escolaridad}
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{type: 'number'}]}
                                    showSearch={true}>
                                        {
                                            this.state.lstEscolaridades &&
                                            this.state.lstEscolaridades.map((escolaridad, index) => (
                                                <Option key={index} value={escolaridad.idEscolaridad}>{escolaridad.descripcion}</Option>
                                            ))
                                        }
                            </Select>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="carrera"
                                       placeholder={etiquetas.datos_escolaridad_lbl_carrera}
                                       form={this.props.form}
                                       required={this.state.carreraRequired}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={50}
                                       rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="estudioActual"
                                       placeholder={etiquetas.datos_escolaridad_lbl_estudioActual}
                                       form={this.props.form}
                                       required={this.props.form.getFieldValue("institucion")}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={50}
                                       rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre="institucion"
                                       placeholder={etiquetas.datos_escolaridad_lbl_institucion}
                                       form={this.props.form}
                                       required={this.props.form.getFieldValue("estudioActual")}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={50}
                                       rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                </Card>
                <Modal
                  className="modalError"
                  title={<img  src={errorImgModal} id="img-error-modal"/>}
                  centered
                  onCancel={()=>this.cerrarErrorModal()}
                  visible={this.state.abrirModalError}
                  footer={false}
                  width={400}
                >
                  {this.state.msjError}
                </Modal>
                </>
           </React.Fragment>
        );
    }
};

export default DatosEscolaridad;

import React, {Component} from 'react'
import axios from 'axios'

class EnlaceDescarga extends Component {
    state = {
        cargado: false,
    }

    componentDidMount() {
        axios.post(this.props.urlDoc, this.props.data, {
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data], {type: this.props.type});
                this.srcURLObject = window.URL.createObjectURL(blob);

                return this.srcURLObject;
            }],
        }).then(() => {
            this.setState({cargado: true});
        });
    }

    render() {
        const nombreDescarga = this.props.nombreDescarga || 'archivo';
        const href = this.state.cargado ? this.srcURLObject : '#';
        const clase = this.state.cargado ? '' : 'inactivo';
        const claseFinal = (this.props.boton ? 'ant-btn ant-btn-primary' : "") + clase

        return <a href={href} className={claseFinal} style={{borderRadius: 15}}
            download={nombreDescarga}>
            {this.props.children}
        </a>;
    }
}

export default EnlaceDescarga;

import React, { Component } from 'react'
import Template from '../interfaz/Template';
import { connect } from 'react-redux'
import AuthenticatedComponent from '../../componentes/AuthenticatedComponent'
import axios from 'axios';
import { Form, Row, Col, Button, Modal } from 'antd';
import errorImgModal from '../../img/error_icon.svg';
import aspiranteCompulsado from '../../img/info11_Militante_representante.png';
import aspiranteCompulsadoJDE from '../../img/info12_Militante_representante_JDE.png';
import * as etiquetas from '../../ApplicationResources';

let msjError = "";

export class AspiranteCompulsado extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cargado: false,
            continuar: false,
            msjError: "",
            abrirModalError: false
        }
    }

    funcionListo = () => {
        if (!this.props.datosAspirante)
            this.props.history.goBack();

        const idProcesoElectoral = this.props.datosAspirante.idProcesoElectoral;
        const idDetalleProceso = this.props.datosAspirante.idDetalleProceso;
        const idParticipacion = this.props.datosAspirante.idParticipacion;
        const idConvocatoria = this.props.datosAspirante.idConvocatoria;
        const folio = this.props.datosAspirante.folio;
        const claveElector = this.props.datosAspirante.claveElectorFuar;
        const idAspirante = this.props.datosAspirante.idAspirante;

        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse', {
            idProcesoElectoral: idProcesoElectoral,
            idDetalleProceso: idDetalleProceso,
            idParticipacion: idParticipacion,
            idConvocatoria: idConvocatoria,
            folio: folio,
            claveElector: claveElector,
            idAspirante: idAspirante
        }, {
                responseType: 'blob',
                transformResponse: [data => {
                    let blob = new window.Blob([data], { type: this.props.type });
                    this.srcURLObject = window.URL.createObjectURL(blob);
                    return this.srcURLObject;
                }]
            },
        ).then(res => {
            this.setState({ cargado: true })
        }
        )
    }

    descargarCompulsaClick = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse'
            , {
                idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral
                , idDetalleProceso: this.props.datosAspirante.idDetalleProceso
                , idParticipacion: this.props.datosAspirante.idParticipacion
                , idConvocatoria: this.props.datosAspirante.idConvocatoria
                , folio: this.props.datosAspirante.folio
                , claveElector: this.props.datosAspirante.claveElectorFuar
                , usuario: this.props.datosUsuario.username
                , ipUsuario: this.props.datosUsuario.ip
            }, {
                responseType: 'blob',
                transformResponse: [data => {
                    let blob = new window.Blob([data], { type: "application/zip" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', `Compulsa_${this.props.datosAspirante.claveElectorFuar}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }]
            },
        ).then(response => {
            console.log(response, response.data);
        }).catch(error => {
            console.error("Error en la descarga de la compulsa", error);
            if (error.response) {
                if (error.response.status === 409) {
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                    && error.response.data.causa.includes("errores")) {
                } else {
                    msjError = etiquetas.gral_error_descargar_compulsa;
                }
            } else {
                msjError = etiquetas.gral_error_descargar_compulsa;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    activarBoton = (valor) => {
        this.setState({ continuar: valor })
    }

    permitirRegistroJDE = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/permitirRegistro', {
            idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
            idDetalleProceso: this.props.datosAspirante.idDetalleProceso,
            idParticipacion: this.props.datosAspirante.idParticipacion,
            idAspirante: this.props.datosAspirante.idAspirante,
            claveElector: this.props.datosAspirante.claveElectorFuar,
            jdePermiteRegistro: 1,
            usuario: this.props.datosUsuario.username
        }, {
                responseType: 'blob',
                transformResponse: [data => {
                    let blob = new window.Blob([data], { type: this.props.type });
                    this.srcURLObject = window.URL.createObjectURL(blob);
                    return this.srcURLObject;
                }]
            },
        ).then(res => {
            this.setState({ cargado: true })
            this.props.history.push('/cargaDocumentos');
        })
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        var renglon = "";

        /*if (this.props.datosAspirante.origenRegistro === 2) {
            renglon =
                <React.Fragment>
                    <center>
                        <img src={aspiranteCompulsadoJDE} alt="AspiranteCompulsado" width="70%" height="60%" />

                    </center>
                    <div className="mensaje_compulsado">
                        <hr></hr>
                        Puedes descargar el Acuse de validación del aspirante <a href={this.srcURLObject}
                            download={`Compulsa_${this.props.datosAspirante.claveElectorFuar}.pdf`}>
                            Aquí</a>
                        <Row>
                            <Col>
                                <p></p>
                                <Checkbox nombre="jdePermiteRegistro"
                                    form={this.props.form}
                                    required={false}
                                    style={{ 'textAlign': 'justify' }}
                                    onChange={this.activarBoton}
                                >
                                    Permitir continuar con la carga de documentos del aspirante.
                        </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <Button type="primary"
                                    className="button_style"
                                    style={{ width: 220 }}
                                    htmlType="submit"
                                    disabled={!this.state.continuar}
                                    onClick={this.permitirRegistroJDE}
                                >
                                    Continuar
                        </Button>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </div>
                </React.Fragment>;
        } else {*/
            renglon =
                <>
                    <center>
                        <img src={aspiranteCompulsado} alt="AspiranteCompulsado" width="70%" height="60%" />
                        <p />
                    </center>
                    <div className="mensaje_compulsado">
                        {/*Encontramos que eres representante o militante de un partido político.
                    <p>Acude a tu Junta Distrital Electoral para continuar con el proceso.</p>
                    <hr></hr>*/}
                        Puedes descargar tu Acuse de validación <a href={this.srcURLObject} download={`Compulsa_${this.props.datosAspirante.claveElectorFuar}.pdf`}>Aquí</a>
                        {/*<div>
					    <Button type="" icon="download" id="boton-edit"
					  			className="button_style"
                                style={{width:200}}
                                onClick={this.descargarCompulsaClick}>
							Descargar Compulsa
						</Button>
                        </div>*/}
                    </div>
                </>
        //}

        return (
            <AuthenticatedComponent listo={this.funcionListo}>
                <Template contenido={
                    <React.Fragment>
                        <Form>
                            {renglon}
                        </Form>
                    </React.Fragment>
                }
                    bloqueado={!this.state.cargado}
                />
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
            </AuthenticatedComponent>
        )
    }
}

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    datosUsuario = state.sesion.datosUsuario;


    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}

export default Form.create({ name: 'aspiranteCompulsado' })(connect(mapStateToProps)(AspiranteCompulsado));

import React, {Component} from 'react';
import moment from 'moment'
import 'moment/locale/es';
import {TimePicker} from 'antd';
import {configuraComponente} from '../../utils/funciones';
import '../../css/select.css';

class Tiempo extends Component {
	constructor (props) {
        super(props);
		configuraComponente.bind(this)();
        this.custom.trigger = 'onChange';
	}

	setMinMaxTime = () => {
		if (this.props.format) {
			if (this.props.minTime) {
				const minTime = moment(this.props.minTime, this.props.format);
				this.setState({minTime});
			}

			if (this.props.maxTime) {
				const maxTime = moment(this.props.maxTime, this.props.format);
				if (maxTime.minutes() === 0 &&
					maxTime.seconds() === 0)
					maxTime.add(59, 'seconds');

				this.setState({maxTime});
			}
		}
	}

	componentDidMount() {
		this.setMinMaxTime();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.minTime !== this.props.minTime ||
			prevProps.maxTime !== this.props.maxTime)
			this.setMinMaxTime();
	}

	disabledHours = () => {
        if (this.state.minTime === undefined && this.state.maxTime === undefined)
            return [];

        const salida = [];

		if (this.state.minTime !== undefined) {
            const hours = this.state.minTime.hours();
            for (let i = 0; i < hours; i++) {salida.push(i)};
        }

		if (this.state.maxTime !== undefined) {
            const hours = this.state.maxTime.hours() + 1;
            for (let i = hours; i <= 23; i++) {salida.push(i)};
        }

		return salida;
	}

	disabledMinutes = (hour) => {
		if (this.state.minTime === undefined && this.state.maxTime === undefined)
			return [];

		const salida = [];

		if (this.state.minTime !== undefined) {
			const hours = this.state.minTime.hours();

			if (hours === hour)
				for (let i = 0; i < this.state.minTime.minutes(); i++) {salida.push(i)};
		}

		if (this.state.maxTime !== undefined) {
			const hours = this.state.maxTime.hours() + 1;

			if (hours === hour)
				for (let i = this.state.maxTime.minutes() + 1; i <= 59; i++) {salida.push(i)};
		}

		return salida;
	}

    render () {
        const format = this.props.format || 'HH:mm:ss';
        this.customRules.type = 'object';
        this.customRules.initialValue = moment();

        const input = <TimePicker showTime={this.props.showTime}
                                  format={format}
                                  hideDisabledOptions={true}
                                  disabled={this.props.disabled}
                                  onChange={this.onChange}
                                  placeholder={this.props.formato}
                                  disabledHours={this.disabledHours}
								  disabledMinutes={this.disabledMinutes}/>

        return this.funcComunComponent(input, 'tiempo-container', false);
    }
}

export default Tiempo;

import React from 'react';
import {Icon, Row, Form} from 'antd';
import Template from '../interfaz/Template';
import {registrarTerminado} from '../../redux/actions/accionesSesion'
import {connect} from 'react-redux'
import { Link } from "react-router-dom";
import AuthenticatedComponent from '../../componentes/AuthenticatedComponent';
import exitoModificaAsp from '../../img/info14_datos_actualizados_modifica.png';
import exitoModificaJDE from '../../img/info16_datos_actualizados_JDE.png';

class ExitoModifica extends React.Component {

    componentWillUnmount() {}

    regresar = () =>{
        const datosAspirante = this.props.datosAspirante || {};
        const datosUsuario = this.props.datosUsuario || {};
        console.log("ESTATUS ",datosAspirante.estatusRegistro);

        if(datosUsuario.origenRegistro === 2 && datosUsuario.tipoUsuario === 'E' &&
          (datosAspirante.estatusRegistro === 1 || datosAspirante.estatusRegistro === 2)){
                this.props.history.push('/cargaDocumentos');
        }else{
            if((datosAspirante.estatusRegistro === 1 && datosAspirante.bPlatica === null) || 
               (datosAspirante.estatusRegistro === 2 && datosAspirante.bObservaciones === 1)){
                this.props.history.push('/cargaDocumentos');
            }
            if(datosAspirante.estatusRegistro === 2 && datosAspirante.bPlatica === null){
                this.props.history.push('/platica_induccion');
            }
            if(datosAspirante.estatusRegistro === 2 && datosAspirante.bPlatica === 0){
                this.props.history.push('/finalizado');
            }
        }
    }

    render(){
        var renglon ="";
        const datosUsuario = this.props.datosUsuario || {};
        if(datosUsuario.tipoUsuario === 'I'){
            renglon =
                <React.Fragment>
                <div className="exito_container">
                    <div style={{'margin':'95px auto'}}>
                        <center>
                            <img src={exitoModificaJDE} alt="ExitoModifica" width="100%" height="100%"/>
                            <br/><br/>
                            <br/><br/>
                                <Link to={'/bandeja'} className="btn btn-success mr-2" style={{fontSize: '1.5em'}}>
                                    Regresar a bandeja
                                </Link>
                        </center>
                        <br/><br/>
                    </div>
                </div>
            </React.Fragment>
        }else{
            renglon =
            <React.Fragment>
                <div className="exito_container">
                    <div style={{'margin':'95px auto'}}>
                        <center>
                            <img src={exitoModificaAsp} alt="ExitoModifica" width="100%" height="100%"/>
                       <br/><br/>
                            <Link onClick={this.regresar} className="btn btn-success mr-2" style={{fontSize: '1.5em'}}>
                                Regresar
                            </Link>
                       </center>
                       
                       <br></br>
                    </div>
                </div>
            </React.Fragment>
        }
        return(
            <AuthenticatedComponent>
            <Template contenido={
                <React.Fragment>
                        {renglon}
                </React.Fragment>
            }guia={false}/>
            </AuthenticatedComponent>
        )
    }
};

const mapStateToProps = state => {
    var datosAspirante, datosUsuario;

    if (state.sesion.datosUsuario) {
        datosUsuario = state.sesion.datosUsuario;
        datosAspirante = datosUsuario.datosAspirante;
    }

    return {
        ventanaExito: state.sesion.exito,
        datosUsuario: datosUsuario,
        datosAspirante: datosAspirante
    };
}

const mapDispatchToProps = {
    registrarTerminado
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitoModifica);

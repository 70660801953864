import React, {Component} from 'react';
import {Icon, Form} from 'antd';
import '../../css/wizard.css';
import {convertirAErrorApropiado, comparaArreglos, cambiaronProps} from '../../utils/funciones';

class Wizard extends Component {
    constructor(props) {
        super(props);

        var pestaniasValidas = [];
        this.customValArr = [];
        for (var i = 0; i < this.props.lista.length; i++) {
            pestaniasValidas[i] = false;

            if (this.props.objetos[i] === undefined)
                this.props.objetos[i] = {};

            this.customValArr[i] = {};
        }

        this.state = {
            habilitarFlechaDer: false,
            activeIndex: props.activeIndex ? props.activeIndex : 0,
            pestaniasValidas: pestaniasValidas,
        };

        props.form.customVal = this.customValArr[this.state.activeIndex];
        props.referencia.current = this;
    }

    componentDidMount() {
        this.form = this.props.form;
        this.props.form.setFields(this.props.objetos[this.state.activeIndex]); // si es modifica entonces setea los objetos props
        setTimeout(() => {
            this.props.form.validateFields();
        }, 0);

        this.formData = {};
        this.formData.formValues = this.props.form.getFieldsValue();
        this.formData.formErrors = this.props.form.getFieldsError();

        if (this.props.callbackPaso)
            this.props.callbackPaso(0, this.props.lista.length);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const newFormData = {}, oldFormData = this.formData;
        this.formData = newFormData;

        newFormData.formValues = nextProps.form.getFieldsValue();
        newFormData.formErrors = nextProps.form.getFieldsError();

        {
            const llaves1 = Object.keys(oldFormData.formValues);
            const llaves2 = Object.keys(newFormData.formValues);

            if (llaves1.length !== llaves2.length)
                return true;

            for (var i = 0; i < llaves1.length; i++) {
                if (!(llaves1[i] in newFormData.formValues) ||
                    oldFormData.formValues[llaves1[i]] !== newFormData.formValues[llaves1[i]])
                    return true;
            }
        }

        {
            const llaves1 = Object.keys(oldFormData.formErrors);
            const llaves2 = Object.keys(newFormData.formErrors);

            if (llaves1.length !== llaves2.length)
                return true;

            for (var i = 0; i < llaves1.length; i++) {
                if (!(llaves1[i] in newFormData.formErrors)) {
                    const errores1 = oldFormData.formErrors[llaves1[i]];
                    const errores2 = newFormData.formErrors[llaves1[i]];
                    if (!comparaArreglos(errores1, errores2))
                        return true;
                }
            }
        }

        return cambiaronProps(this.props, nextProps) || cambiaronProps(this.state, nextState);
    }

    cambiarEstado = (dir) => {
        this.setState(state => {
            const val = state.activeIndex + dir;

            this.props.form.customVal = this.customValArr[val];

            if (this.props.callbackPaso)
                this.props.callbackPaso(val, this.props.lista.length);

            return {activeIndex: val}
        });
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.activeIndex !== this.state.activeIndex) {
            this.props.form.setFields(this.props.objetos[this.state.activeIndex]);
            setTimeout(this.props.form.validateFields, 0);
        }

        if (this.props.callValidacion !== undefined) {
            var valido = !this.state.pestaniasValidas.some(v => !v);
            this.props.callValidacion(valido, this.props.objetos, this.callbackResponse);
        }
    }

    cleanWizard = () => {
        const objetos = this.props.objetos;
        objetos.forEach((_, ind) => {objetos[ind] = {}});
        for (var i = 0; i < objetos.length; i++)
            this.customValArr[i] = {};
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        nextProps.form.customVal = this.props.form.customVal;
        const errors = nextProps.form.getFieldsError();
        const values = nextProps.form.getFieldsValue();
        var error = Object.values(errors).some(val => Boolean(val));
        const llaves = Object.keys(values);

        for (var i = 0; i < llaves.length; i++) {
            const llave = llaves[i];

            if (errors === null || errors[llave] === undefined) {
                const obj = {};
                this.props.objetos[this.state.activeIndex][llave] = obj;
                obj.value = values[llave];
            }
        }

        error = this.revisaNegocio(this.state.activeIndex, error);

        var pst = this.state.pestaniasValidas.slice();
        pst[this.state.activeIndex] = !error;

        this.setState({pestaniasValidas: pst,
                       habilitarFlechaDer: !error});

        if (error !== !this.state.habilitarFlechaDer)
            this.establecerEstadoError(error);

    }

    callbackResponse = (errors) => {
        if (!errors) {
            this.cleanWizard();
            return;
        }

        var min = undefined;
        Object.keys(errors).map(key => {
            key = parseInt(key);
            const pestaniaValores = errors[key];
            var tieneValoresOErrores = false;

            Object.keys(pestaniaValores).map(llave => {
                const valor = pestaniaValores[llave];
                var eliminarObjeto = true;
                if (valor.values !== undefined || valor.error !== undefined) {
                    tieneValoresOErrores = true;
                    eliminarObjeto = false;
                }

                if (valor.validacion !== undefined)
                    this.customValArr[key][llave] = valor.validacion;

                if (eliminarObjeto)
                    delete pestaniaValores[llave];
            });

            if(!tieneValoresOErrores)
                return;

            min = min === undefined ? key : Math.min(min, key);

            const convertidos = convertirAErrorApropiado(pestaniaValores, this.props.objetos[key]);
            Object.assign(this.props.objetos[key], convertidos);
        });

        if (min === this.state.activeIndex)
            this.props.form.setFields(this.props.objetos[min]);

        this.establecerEstadoError(min !== undefined, min);
        this.props.callValidacion(false, this.props.objetos, this.callbackResponse);
    }

    establecerEstadoError(error, pestaniaActiva) {
        this.setState(state => {
            pestaniaActiva = pestaniaActiva !== undefined ? pestaniaActiva : state.activeIndex;

            error = this.revisaNegocio(pestaniaActiva, error);

            var pst = state.pestaniasValidas.slice();
            pst[pestaniaActiva] = !error;

            this.props.form.customVal = this.customValArr[pestaniaActiva];

            if (this.props.callbackPaso)
                this.props.callbackPaso(pestaniaActiva, this.props.lista.length);

            return {habilitarFlechaDer: !error,
                    pestaniasValidas: pst,
                    activeIndex: pestaniaActiva}
        });
    }

    revisaNegocio (pestaniaActiva, error) {
        const customVal = this.customValArr[pestaniaActiva];

        Object.values(customVal).map(val => {
            if (!val)
                error = true;
        });

        return error;
    }

    cambiaNegocio(nombreCampo, valido) {
        if(nombreCampo in this.props.form.customVal)
            this.props.form.customVal[nombreCampo] = valido;

        this.props.form.validateFields();
    }

    render() {
        const flechaIzquierda = this.state.activeIndex === 0 ? undefined :
                                (<span className="flecha_wizard flecha_wizard_active flecha_wizard_left" onClick={() => this.cambiarEstado(-1)}>
                                    <Icon component={FlechaIzq}/>
                                    <span>&nbsp;Anterior</span>
                                </span>);

        const cambio = this.state.habilitarFlechaDer ? () => this.cambiarEstado(1) : undefined;
        const clase = this.state.habilitarFlechaDer ? 'flecha_wizard_active' : '';
        const flechaDerecha = this.state.activeIndex === this.props.lista.length - 1 ?
                              (<span className={"flecha_wizard flecha_wizard_right"}>{this.props.botonFinal}</span>) :
                              (<span className={"flecha_wizard " + clase + " flecha_wizard_right"} onClick={cambio}>
                                <span>Siguiente&nbsp;</span>
                                <Icon component={FlechaDer}/>
                              </span>);

        return (
            <div>
                <div className="contenido_wizard">
                    {React.cloneElement(this.props.lista[this.state.activeIndex],  {form: this.props.form})}
                </div>
                <div>
                    {flechaDerecha}
                    {flechaIzquierda}
                </div>
                <div style={{clear: 'both'}}></div>
            </div>
        );
    }
}

function FlechaDer() {
    return (
        <svg width='1em' height='1em' fill='currentColor' className='ruta'
             viewBox='0 0 28 28'>
            <path d="M 0 14 A 14 14 0 1 1 28 14 A 14 14 0 1 1 0 14 " />
            <path d="M 6 12 L 18 12 L 14 8 L 16 6 L 24 14 L 16 22 L 14 20 L 18 16 L 6 16 A 1 1 0 1 1 6 12" fill="white"/>
        </svg>
    );
}

function FlechaIzq() {
    return (
        <svg width='1em' height='1em' fill='currentColor' className='ruta'
             viewBox='0 0 28 28'>
            <path d="M 0 14 A 14 14 0 1 1 28 14 A 14 14 0 1 1 0 14 " />
            <path d="M 22 12 L 10 12 L 14 8 L 12 6 L 4 14 L 12 22 L 14 20 L 10 16 L 22 16 A 1 1 0 1 0 22 12" fill="white"/>
        </svg>
    );
}

export default Form.create({ name: 'wizard' })(Wizard);

import React from 'react';
import {Card, Row, Col, Checkbox as CheckboxANTD} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import RadioGroup, {Radio} from '../interfaz/Radio';
import Checkbox from '../interfaz/Checkbox';
import InputText from '../interfaz/InputText';

const formaParticipacionOptions = [
    { value: 'S', label: 'SE' },
    { value: 'C', label: 'CAE' },
    { value: 'O', label: 'Otro' },
];

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

class DatosOtros1 extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {

        };

        this.handleParticipoProcesoChange = this.handleParticipoProcesoChange.bind(this);
        this.handleFormasSeleccionadasChange = this.handleFormasSeleccionadasChange.bind(this);

    }

    handleParticipoProcesoChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    cualProceso: null,
                    formasSeleccionadas: null,
                    participoOtroEspecifique: null,
                },
                () => {
                    this.props.form.validateFields(['cualProceso', 'formasSeleccionadas', 'participoOtroEspecifique'], { force: true });
                }
            );
    }

    handleFormasSeleccionadasChange = (value) => {
        this.props.form.setFieldsValue(
            {
                participoOtroEspecifique: null,
            },
                () => {
                    this.props.form.validateFields(['participoOtroEspecifique'], { force: true });
                }
            );
    }

    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_datos_otros_lbl_titulo} bordered={false}>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'participoProceso'}
                                        name={'participoProceso'}
                                        label={etiquetas.datos_datos_otros_lbl_participoProceso}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}
                                            onChange={(value) => this.handleParticipoProcesoChange(value)}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="cualProceso"
                                        placeholder={etiquetas.datos_datos_otros_lbl_cualProceso}
                                        form={this.props.form}
                                        disabled={!(this.props.form.getFieldValue("participoProceso") === 'S')}
                                        required={this.props.form.getFieldValue("participoProceso") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={50}
                                        rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox.Group nombre={'formasSeleccionadas'}
                                            label={etiquetas.datos_datos_otros_lbl_formasSeleccionadas}
                                            form={this.props.form}     
                                            disabled={!(this.props.form.getFieldValue("participoProceso") === 'S')}           
                                            required={this.props.form.getFieldValue("participoProceso") === 'S'}
                                            msjRequerido={etiquetas.general_dato_requerido}
                                            mensajeValidacion={etiquetas.general_dato_requerido}
                                            rules={[{type: 'array'}]}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.handleFormasSeleccionadasChange(value)}>
                                    <Row style={{'textAlign':'center','display':'flex'}}>
                                        {
                                            formaParticipacionOptions &&
                                            formaParticipacionOptions.map((opcion, index) => (
                                                <Col span={9} key={index}>
                                                    <CheckboxANTD value={opcion.value}>{opcion.label}</CheckboxANTD>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                            </Checkbox.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="participoOtroEspecifique"
                                        placeholder={etiquetas.datos_datos_otros_lbl_participoOtroEspecifique}
                                        form={this.props.form}
                                        disabled={!(this.props.form.getFieldValue("formasSeleccionadas") && this.props.form.getFieldValue("formasSeleccionadas").includes('O'))}
                                        required={this.props.form.getFieldValue("formasSeleccionadas") && this.props.form.getFieldValue("formasSeleccionadas").includes('O')}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={100}
                                        rules={[{
                                                max: 100
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'diponibilidadTiempo'}
                                        name={'diponibilidadTiempo'}
                                        label={etiquetas.datos_datos_otros_lbl_diponibilidadTiempo}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                </Card>
           </React.Fragment>
        );
    }
};
export default DatosOtros1;

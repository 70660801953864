import React  from 'react';
import {Icon, Row} from 'antd';
import Template from "./../../interfaz/Template.js";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { cargarAspirante } from "../../../utils/funciones";
import { implantarAspirante } from "../../../redux/actions/accionesSesion";
import { emitirOperacion } from "../../../redux/actions/accionesOperacion";
import AuthenticatedComponent from './../../AuthenticatedComponent';
import exitoModificaJDE from '../../../img/info16_datos_actualizados_JDE.png';
import { connect } from "react-redux";
function Valida({folioAcceso, tipo, history}) {

	return (

<Template contenido={
                <div className="exito_container">
                    <div>
                    <center>
                        <br/><br/>
                            <br/><br/>
                            <img src={exitoModificaJDE} alt="ExitoModifica" width="100%" height="100%"/>
                            <br/><br/>
                            <br/><br/>
                        </center>
                     
                       {}
                       <br></br>
                       <br></br>
                       <Row>
                       <center>
                       {}
                        {tipo === 0 ?
                        <Link
                            className="btn btn-success mr-2"
                            style={{fontSize: '1.5em'}}
                            onClick={() =>
                            cargarAspirante(folioAcceso).then(datosAspirante => {
                                emitirOperacion({
                                accion:  "captura"
                                });
                                implantarAspirante(datosAspirante);
                                history.push(`/validacion_documentos`);
                            })
                            }
                        >
                           Ir a validacion de documentos
                        </Link>:
                        <Link
                            className="btn btn-success mr-2"
                            style={{fontSize: '1.5em'}}
                            onClick={() =>
                            cargarAspirante(folioAcceso).then(datosAspirante => {

                                implantarAspirante(datosAspirante);
                                history.push(`/cargaDocumentos`);
                            })
                            }
                        >
                       Ir a carga de documentos
                    </Link>


                        }

                       </center>
                       </Row>
                       <br/><br/>
                            <br/><br/>

                    </div>
                </div>
            }
            guia={false}
            />

	)
}

export default withRouter(
    connect(
        _ => {
          return {};
        },
        { implantarAspirante, emitirOperacion }
      )(Valida)
    );

import React, {Component} from 'react';
import {ConfigProvider, Layout, Button, Row, Col, Icon} from 'antd';

import esES from 'antd/es/locale/es_ES';
import * as etiquetas from '../../ApplicationResources';
import {connect} from 'react-redux';
import logoINE from '../../img/logo_INE_blanco-06.png';
import flecha from '../../img/flecha.png';
import userIcon from '../../img/user_icon.png';
import cauIcon from '../../img/cau_icon.png';
import menuIcon from '../../img/menu_icon.png';
import idSistema from '../../img/logo_recluta.svg';
import GuiaEstatus from './GuiaEstatus';
import {withRouter} from 'react-router-dom';
import Mensaje from './Mensaje';
import axios from "axios";
import BlockLoader from './Loader.js';
import '../../css/options_menu.css';
import Filtro from '../modulosJDE/Filtros.js';
import MenuMovil from './MenuMovil.js';
import {obtenerEstado} from '../../utils/funciones.js';
import {obtenerFormatoDistrito} from '../../utils/funciones.js';

const {Header, Content, Footer} = Layout;


let ocultarGrafica = true;
let rolUsuario = "";
let nombreUsuario = "";
let nivelUsuario = ""; 

class Template extends Component {

	redireccionar = () => {
		this.props.history.push('/logout');
	}

	constructor(props){
		super(props);
		var tipoUsuario = this.props.haySesion ?
																this.props.datosUsuario.tipoUsuario : "";
		this.obtenerRolUsuario();

		if(window.location.pathname === '/menuJDE' && tipoUsuario === 'I'){
			ocultarGrafica = false;
		}
		this.obtenerNiveles(tipoUsuario);

		this.state = {mostrarOcultarInfo: false,
									mostrarOcultarMenu : false};
    // This binding is necessary to make `this` work in the callback
		this.mostrarInfoUsuario = this.mostrarInfoUsuario.bind(this);
    this.mostrarMenu = this.mostrarMenu.bind(this);
	}


	mostrarInfoUsuario() {
	 this.setState(prevState => ({
		 mostrarOcultarInfo: !prevState.mostrarOcultarInfo
	 }));
 }

 mostrarMenu() {
	this.setState(prevState => ({
		mostrarOcultarMenu: !prevState.mostrarOcultarMenu
	}));
}

	obtenerNiveles = (tipoUsuario) =>{
		if (tipoUsuario !== "" && tipoUsuario === "E") {
			this.obtenerNivelUsuarioE();
		}else if (tipoUsuario !== "" && tipoUsuario === "I"){
			nombreUsuario = this.props.haySesion ?this.props.datosUsuario.username:"";
			this.obtenerNivelUsuarioI();
		}
	}

	/**
	* Obtiene la lista de los estados para el filtro de OC
	**/
	obtenerListaEstados = async () =>{
		var postData = {
			idProcesoElectoral : etiquetas.id_proceso_electoral,
			idDetalleProceso : etiquetas.id_detalle_proceso,
			idEstado : typeof(this.props.datosUsuario.idEstado) === 'undefined'
			|| this.props.datosUsuario.idEstado === null  ? 0 :
																							 this.props.datosUsuario.idEstado
		};
		const estados = await axios.post(process.env.REACT_APP_API_URL +
			"/centralReclutaSEyCAE/ws/obtenerEstados", postData
					, {
							headers: {
								'Accept': 'application/json'
								 ,'Content-Type': 'application/json'
							}
					}
			);

			return JSON.parse(JSON.stringify(estados.data));
	}

	/**
	* Obtiene la lista de distritos para el filtro de OC y JL
	**/
	obtenerListaDistritos = async (idEstado) =>{
		var idDto = this.props.datosUsuario.idDistrito > 0 ?
																				 this.props.datosUsuario.idDistrito : 0;
		var estadoId = typeof(idEstado) !== "undefined"  ? idEstado :
																							 this.props.datosUsuario.idEstado;
		var postData = {
			idProcesoElectoral : etiquetas.id_proceso_electoral,
			idDetalleProceso : etiquetas.id_detalle_proceso,
			idEstado : estadoId !== null ? estadoId : 0,
			idDistrito : idDto
		};

		const distritos = await axios.post(process.env.REACT_APP_API_URL +
			"/centralReclutaSEyCAE/ws/obtenerDistritos", postData
					, {
							headers: {
								'Accept': 'application/json'
								 ,'Content-Type': 'application/json'
							}
					}
			);
			return JSON.parse(JSON.stringify(distritos.data));
	}

	/**
	* Obtiene los datos para la gráfica que se muestra al inicio
	**/
	cargarGrafica = async (idEdo,idDto) =>{
		if(window.location.pathname === '/menuJDE'){
		var idEstado = idEdo === undefined || idEdo === 0 ?
																			 this.props.datosUsuario.idEstado : idEdo;
		var idDistrito = idDto === undefined || idDto === 0 ?
																		 this.props.datosUsuario.idDistrito : idDto;
		var postData = {
			idProcesoElectoral : etiquetas.id_proceso_electoral,
			idDetalleProceso : etiquetas.id_detalle_proceso,
			idEstado : idEstado,
			idDistrito : idDistrito
		};
		console.log("Cargando datos grafica...",postData);
		const response = await axios.post(process.env.REACT_APP_API_URL +
	 	 "/centralReclutaSEyCAE/ws/obtenerInformacionGrafica", postData
	 			 , {
	 					 headers: {
	 							 'Accept': 'application/json'
	 							 ,'Content-Type': 'application/json'
	 					 }
	 			 }
	 	 );

		 return response.data[0];
	 }
	}

	/**
	* Obtiene el rol de usuario OC, Jl o JD
	**/
	obtenerRolUsuario = () =>{
		var usuarioRol = this.props.haySesion ? this.props.datosUsuario.rolUsuario
																																					 : "";
		rolUsuario = usuarioRol.substr(-2,2);
		if(rolUsuario === 'JD'){
			this.mostrarBarchart = true;
		}
	}

	/**
	* Obtiene el nivel para el usuario JD
	**/
	obtenerNivelUsuarioI = () =>{

		var usuarioRol = this.props.haySesion ? this.props.datosUsuario.rolUsuario																																				 : "";
		var idEstado = this.props.haySesion ? this.props.datosUsuario.idEstado : "";
		var idDistrito =this.props.haySesion ? this.props.datosUsuario.idDistrito: "";
    var nombreEstado = obtenerEstado(idEstado);
		var distrito = obtenerFormatoDistrito(idDistrito);

		var rol_div = usuarioRol.split(".");
		var nivel = rol_div[1].split("_");

		if (nivel.length === 2) {
			rolUsuario = nivel[1];
			nivelUsuario = rolUsuario+" "+distrito+" "+nombreEstado;
		}else{
			switch (rolUsuario) {
				case 'JL':
					nivelUsuario = rolUsuario+" "+distrito+" "+nombreEstado;
				break;
				case 'JD':
					nivelUsuario = rolUsuario+"E "+distrito+" "+nombreEstado;
				break;
				case 'OC':
					nivelUsuario = "DECEyEC";
				break;
			}
		}
	}

	/**
	* Obtiene el nivel del aspirante
	**/
	obtenerNivelUsuarioE = () =>{
		nombreUsuario = this.props.haySesion ?
														 this.props.datosUsuario.datosAspirante.nombre:"";
		var postData = {
			idProcesoElectoral : this.props.haySesion ?
							 this.props.datosUsuario.datosAspirante.idProcesoElectoral : "",
			idDetalleProceso :  this.props.haySesion ?
								 this.props.datosUsuario.datosAspirante.idDetalleProceso : "",
			idParticipacion : this.props.haySesion ?
									 this.props.datosUsuario.datosAspirante.idParticipacion : ""
			};
			var distrito = 0;

			this.obtenerEdoDtoAspirante(postData).then(datos => {
				distrito = obtenerFormatoDistrito(datos[0].idDistrito);
				nivelUsuario = distrito+" "+datos[0].nombreEstado;
				this.setState({clave:nivelUsuario})
			});
	}

	/**
	* Obtiene el estado y distrito del aspirante logeado.
	**/
	obtenerEdoDtoAspirante (parametros) {
		return new Promise ((resolver, rechazar) => {
			axios.post(process.env.REACT_APP_API_URL +
				"/centralReclutaSEyCAE/ws/obtenerEstadoDistrito",
			parametros,
			{
				headers: {
					'Accept': 'application/json',
				  'Content-Type': 'application/json'
			 }
			}).then(res => {
				resolver(res.data);
			}).catch(_error => {
				rechazar()
			});
		});
	}

	render() {
		const bloquear = this.props.bloqueado || this.props.cargando;
		const tipoUsuario = this.props.datosUsuario ?
																		  this.props.datosUsuario.tipoUsuario : 'E';
		const centroAyuda = tipoUsuario === 'I'
			?<React.Fragment>
					<a href='https://intranet.ine.mx/tutoriales/sistemas/Electorales/2021/federal/reclutaenlinea/'
						 target='_blank'><img src={cauIcon} id='question-icon' style={{cursor:'pointer'}}/>
					 </a></React.Fragment> : <React.Fragment><a href='#'>
						<img src={cauIcon} id='question-icon' style={{cursor:'pointer'}}/>
			</a></React.Fragment>;

		return (
			<ConfigProvider locale={esES}>
								<BlockLoader blocking={bloquear && this.props.bloquearTodo}
							 styleClass={'bloq_sobre_todo'}>
					<Layout className="layout template" style={{'overflowX': 'hidden'}}>
						<Header className="header_style">
							<Row gutter={16} style={{'marginTop':'-12px'}}>
								<Col xs={12} sm={12} md={12} lg={12} id="logo-img">
									{this.props.haySesion ?
										<>
											<img src={menuIcon} id="menu-icon"
													 onClick={this.mostrarMenu}/>
									  </>
									:""}
									<a href="/loginSEyCAE" id="logo-sistema">
										<img id="idSistema" src={idSistema} alt=""/>
									</a>
								</Col>
								{this.props.haySesion ?
								<Col xs={12} sm={12} md={12} lg={12} id="main-menu" >

									<div id="div-question-icon">
										{centroAyuda}
									</div>
									<div id="div-icon-user">
										<img src={userIcon} id="user-icon"/>

												{
													nombreUsuario.length > 10
													 ?
													 <>
													 <label id="user-name">{nombreUsuario}</label>
														 <p id="level" className={rolUsuario === 'JD' ? 'reducir':''}>
			 												{nivelUsuario}
			 											</p>
														</>
													 :
													  <>
													 <label id="user-name">{nombreUsuario} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
													 <p id="level" style={{'marginLeft': '36px'}}
														  className={rolUsuario === 'JD' ? 'reducir':' '}>
			 												{nivelUsuario}
			 											</p>
														</>
												}


									</div>
									<div id="div-icon-user-mov">
										<img src={userIcon} id="user-icon"/>
										<img src={flecha} id="flecha" onClick={this.mostrarInfoUsuario}/>
									</div>
									<div id="division-header"></div>

									<div className="div_logout">
							    {this.props.haySesion ? <Button icon="logout" type=""
							        onClick={this.redireccionar} id="boton-logout">
							        Cerrar sesión
							    </Button>
							    : ""}
							    </div>
								</Col>
								: ""}
							</Row>
						</Header>
						<div id="info-user-mov"
								 className={this.state.mostrarOcultarInfo ? "show" : "hide"}>
							<img src={userIcon} id="user-icon"/>
											{this.props.haySesion ?
												<>
												<p id="user-name-mov">
													{nombreUsuario}
												</p>
												<p id="nivel-mov">
													{nivelUsuario}
												</p>
												</>
											: ""}
							 <hr/>
							 <p className="otras_opciones">
								 <a href={centroAyuda} target="_blank">Ayuda</a>
							 </p>
							 <p className="otras_opciones">
								 {this.props.haySesion ? <a
										 onClick={this.redireccionar} id="boton-logout">
										 Cerrar sesión
								 </a>
								 : ""}
							 </p>
						</div>
						{this.props.haySesion ?
						<div id="menu-mov"
							 className={this.state.mostrarOcultarMenu ? "show" : "hide"}>
							<MenuMovil></MenuMovil>
						</div>
						:""}
						<Content className={this.props.contenidoExpandido ? 'contenido_expandido' : undefined}>
							<BlockLoader blocking={bloquear && !this.props.bloquearTodo}>
								{this.props.guia && tipoUsuario === 'E'? <GuiaEstatus/> : null}

								{tipoUsuario === 'E' ? <Mensaje id='mensajeUsuario'/> : null}
								{this.props.bloqueado ? <div className='template_cargando'>Cargando...</div> :
														this.props.contenido}
							</BlockLoader>

						{!ocultarGrafica	? <div>
								 <Filtro
										listaEstados = {this.obtenerListaEstados}
										listaDistritos = {this.obtenerListaDistritos}
										infoGrafica = {this.cargarGrafica}
										mostrarBarchart = {this.mostrarBarchart}
										rolUsuario = {rolUsuario}
										idProcesoElectoral ={etiquetas.id_proceso_electoral}
										idDetalleProceso = {etiquetas.id_detalle_proceso}
										idDto = {!this.props.haySesion ? 0 :this.props.datosUsuario.idDistrito}
										idEdo = {!this.props.haySesion ? 0 :this.props.datosUsuario.idEstado}
									>
									</Filtro>

						</div> : "" }
					</Content>
						<Footer className={window.location.pathname === "/bandeja" ?
							'footer_style_bandeja web' : 'footer_style web'}>
							<Row>
								<Col xs={2} sm={4} md={12} lg={12}>
									<Row>
										<Col xs={2} sm={6} md={6} lg={12} id="div-logo">
											<img id="logoINE" src={logoINE} alt=""/>
										</Col>
										<Col xs={2} sm={6} md={6} lg={12}>
											<h5 className='text_footer' id="ine-footer">
												<div id="division-footer"></div>
												<Icon type="copyright" id="icon-copy"/> INE M&eacute;xico 2020.
												<a href="#" id="cau">CAU</a>
											</h5>
										</Col>
									</Row>
								</Col>
								<Col xs={2} sm={6} md={6} lg={12} id="div-version">
									<h5 className='text_footer' id="footer-version">
										Versi&oacute;n del sistema 2.0
									</h5>
									<p style={{'marginBottom': '0px','marginTop': '-15px','fontSize':'9px'}}>
										{etiquetas.hora_fecha_version}
									</p>
								</Col>
							</Row>
						</Footer>
						<Footer className='footer_style movil'>
								<div id="div-logo-mov">
											<img id="logoINE-mov" src={logoINE} alt=""/>
											<div className='text_footer'
														id="division-footer"></div>
											<Icon className='text_footer'
														type="copyright" id="icon-copy"/>
											<h5 className='text_footer'>INE M&eacute;xico 2020.</h5>
											<a className='text_footer'
														href="#" id="cau">CAU</a>
													<span style={{'fontSize':'7px','marginTop':'0px','float':'left','color':"#000"}}>
														{etiquetas.hora_fecha_version}
												</span >
								</div>
								<div id="div-version-mov">
									<h5 className='text_footer' id="footer-version">
										Versi&oacute;n del sistema 2.0
									</h5>
								</div>
						</Footer>
					</Layout>
				</BlockLoader>
			</ConfigProvider>
		);
	}
}

Template.defaultProps = {
	guia: true,
	bloqueado: false,
	cargando: false,
	contenidoExpandido: false,
	bloquearTodo: true,
}

const mapStateToProps = (state) => {
	return {
		haySesion: Boolean(state.sesion.datosUsuario && state.sesion.datosUsuario.username),
		datosUsuario: state.sesion.datosUsuario,
	};
};


export default withRouter(connect(mapStateToProps)(Template));

/*---------------------------------------------------------------------------------
                                ETIQUETAS Y MENSAJES
 Contiene las etiquetas y mensajes generales del sistema y de cada módulo
---------------------------------------------------------------------------------*/
export const hora_fecha_version = "V2.0 05022021 14:00";
export const id_detalle_proceso = 108;
export const id_proceso_electoral = 16;

/*------------- ETIQUETAS GENERALES -------------*/
export const general_btn_aceptar     = "Aceptar";
export const general_btn_cancelar    = "Cancelar";
export const general_dato_requerido  = "Dato requerido";
export const general_formato_invalido = "Formato inválido";
export const general_telefono_requerido = "Ingrese al menos un número telefónico";
export const general_experiencia_laboral = "*Tu experiencia profesional nos ayuda a conocer tu perfil,captura lo que consideres necesario.";
export const general_telefono_requerido_leyenda = "*Al menos uno de los dos teléfonos es requerido";

export const gral_no_convo_activa_seccion = "No existe convocatoria activa en la sección.";
export const gral_descarga_compulsa_inter = "La descarga de compulsa fue interrumpida. Por favor inténtalo más tarde.";
export const gral_serivicio_no_disponible = "Servicio no disponible. Por favor inténtalo más tarde.";
export const gral_no_existe_clave_elector = "Los datos ingresados no se encuentran actualizados en lista nominal acude a JD.";
export const gral_correos_diferentes = "Los correos no son iguales.";
export const gral_sin_resultados = "Sin resultados para mostrar. Prueba con diferentes palabras."
export const gral_sin_registro_aspirantes  = "Aún no se cuenta con registro de aspirantes."
export const gral_no_participacion = "Sin participación geográfica."
export const gral_no_existe_convocatoria = "No existe convocatoria activa en la sección.";
export const gral_observacion_documentos="Encontramos observaciones en tus documentos, cárgalos de nuevo."
export const gral_archivos_permitidos = "Solo se permiten los formatos PDF, JPG y  PNG.";
export const gral_peso_archivo = " debe ser menor a 1.5 MB";
export const gral_validando_documentos = "Validación de documentos en proceso.";
export const gral_documentos_validados = "Tus documentos fueron validados.";
export const gral_correo_confirmacion = "En breve recibirás un correo electrónico de confirmación.";
export const gral_solicitud_declinada = "Tu solicitud ha sido declinada, para mayor información comunícate a la Junta Distrital";

export const gral_error_cau = "El sistema no pudo realizar la acción solicitada, intenta de nuevo.";
export const gral_error_menor_edad = "El aspirante debe tener más de 18 años.";
export const gral_error_mayor_edad = "El aspirante debe tener menos de 60 años.";
export const gral_error_descargar_compulsa = "Ocurrió un error al descargar la compulsa.";
export const gral_error_nombre_archivo = "El nombre del documento no debe contener caracteres especiales.";
export const gral_error_descarga_acuse_capsula = "Ocurrió un error al descargar el acuse de la cápsula.";
export const gral_error_sede_examen = "Ocurrió un error al obtener la sede del examen.";
export const gral_error_guia_estudio = "Ocurrió un error al descargar la guia de estudio.";
export const gral_error_platicas = "Ocurrió un error al obtener la programación de pláticas.";
export const gral_error_no_hay_platicas = "Aún no hay pláticas programadas. Consulta el listado con la JDE.";
export const gral_error_reenvio = "Ocurrió un error al enviar las notificaciones.";
export const gral_error_no_hay_correo = "El aspirante no cuenta con correo electrónico registrado.";
export const gral_error_contacto = "Ocurrió un error al obtener los datos de contacto de la Junta Distrital.";
export const gral_error_fecha_fuera_rango = "Fecha fuera del periodo valido de la convocatoria.";
export const gral_error_administrador = "Ocurrio un error al actualizar el registro.";

/* ------------------ etiquetas menu ---------------- */
export const menu_element_uno = "Validar y dar seguimiento";
export const menu_element_dos = "Registrar un ciudadano";
export const menu_element_tres = "Reportes";

/*------------- ETIQUETAS MODULO LOGIN -------------*/
export const login_btn_inicioSesion = "Inicia sesión"

export const login_lbl_usuario      = "Usuario"
export const login_lbl_constrasenia = "Contraseña"
export const login_lbl_registrate   = "Registrate ahora"

export const login_msg_constrasenia = "Utiliza tu Clave de Elector o tu SIAP. Si eres trabajador del INE, ingresa con tu usuario y contraseña institucional"
export const login_lbl_sesionActiva = "El usuario cuenta con una sesión activa, volverlo a intentar después de 30 minutos."


/*------------- ETIQUETAS REGISTRO ASPIRANTE -------------*/

export const datos_credencial_lbl_titulo           = ""
export const datos_credencial_lbl_claveElectorFuar = "Clave de elector o SIAP (antes FUAR)"
export const datos_credencial_lbl_estado           = "Estado"
export const datos_credencial_lbl_seccion          = "Sección electoral"
export const datos_credencial_lbl_fechaRegistro    = "Fecha de registro"
export const datos_credencial_lbl_convocatoria     = "Convocatoria"

export const datos_personales_lbl_titulo           = "Datos personales"
export const datos_personales_lbl_rfc              = "RFC"
export const datos_personales_lbl_curp             = "CURP"
export const datos_personales_lbl_apellidoPaterno  = "Apellido paterno"
export const datos_personales_lbl_apellidoMaterno  = "Apellido materno"
export const datos_personales_lbl_nombre           = "Nombre (s)"
export const datos_personales_lbl_lugarNacimiento  = "Lugar de nacimiento"
export const datos_personales_lbl_fechaNacimiento  = "Fecha de nacimiento"
export const datos_personales_lbl_edad             = "Edad"
export const datos_personales_lbl_sexo             = "Sexo"
export const datos_personales_lbl_correoElectronico = "Correo electrónico"
export const datos_personales_lbl_confirmaCorreoElectronico = "Confirma tu correo electrónico"

export const datos_direccion_lbl_titulo            = "Domicilio"
export const datos_direccion_lbl_calleNumero       = "Calle, número exterior, número interior"
export const datos_direccion_lbl_colonia           = "Colonia"
export const datos_direccion_lbl_cp                = "Código postal"
export const datos_direccion_lbl_municipio         = "Municipio o Alcaldía"
export const datos_direccion_lbl_localidad         = "Localidad"
export const datos_direccion_lbl_telefono          = "Teléfono fijo (10 digitos)"
export const datos_direccion_lbl_celular           = "Teléfono celular (10 digitos)"

export const datos_escolaridad_lbl_titulo            = "Escolaridad"
export const datos_escolaridad_lbl_escolaridad       = "Escolaridad (Último grado de estudios)"
export const datos_escolaridad_lbl_carrera           = "Carrera"
export const datos_escolaridad_lbl_estudioActual     = "Estudios que realiza actualmente"
export const datos_escolaridad_lbl_institucion       = "Institución"

export const datos_medio_difusion_lbl_titulo         = "Medio por el que te enteraste de la convocatoria"
export const datos_medio_difusion_lbl_mediosDifusion = "Seleccione según corresponda"
export const datos_medio_difusion_lbl_especifiqueMedio = "Especifique"
export const datos_medio_difusion_lbl_motivoParticipar = "¿Cuál es el motivo por el que quieres participar como SE o CAE?"

export const datos_experiencia_laboral_lbl_titulo             = "Experiencia laboral"
export const datos_experiencia_laboral_lbl_tituloEmpresa      = "Empleo anterior"
export const datos_experiencia_laboral_lbl_nombreEmpresa      = "Nombre de la empresa o Institución"
export const datos_experiencia_laboral_lbl_puestoEmpresa      = "Puesto"
export const datos_experiencia_laboral_lbl_fechaInicioEmpresa = "Fecha Inicio"
export const datos_experiencia_laboral_lbl_fechaFinEmpresa    = "Fecha Fin"
export const datos_experiencia_laboral_lbl_telefonoEmpresa    = "Teléfono"

export const datos_datos_otros_lbl_titulo                      = "Datos adicionales"
export const datos_datos_otros_lbl_participoProceso            = "¿Has participado en algún proceso electoral?"
export const datos_datos_otros_lbl_cualProceso                 = "¿Cuál?"
export const datos_datos_otros_lbl_formasSeleccionadas         = "¿De qué forma has participado?"
export const datos_datos_otros_lbl_participoOtroEspecifique    = "Otro"
export const datos_datos_otros_lbl_diponibilidadTiempo         = "¿Tiene disponibilidad de tiempo para prestar sus servicios en horario fuera de lo habitual?"
export const datos_datos_otros_lbl_serviciosFinSemFestivos     = "¿Está dispuesto a prestar sus servicios en fines de semana y días festivos?"
export const datos_datos_otros_lbl_actividadesCampo            = "¿Está dispuesto a realizar actividades de campo? (visitar ciudadanos casa por casa, trasladarse grandes distancias, entre otros)"
export const datos_datos_otros_lbl_militantePartido            = "¿Milita en algún partido político u organización política o ha participado activamente en alguna campaña electoral en el último año?"
export const datos_datos_otros_lbl_representantePartido        = "¿Ha participado como representante de partido político con registro vigente o coalición en alguna elección realizada en los últimos tres años?"
export const datos_datos_otros_lbl_afinidadFamConsaguineo      = "¿Es familiar consanguíneo o por afinidad, hasta el 4° grado, de algún/a Vocal de la Junta o del Consejo Distrital o Local (consejeros/as y representantes de Partido Político o, en su caso, candidatos/as independientes que ya estén registrados para el PE 2020-2021?"
export const datos_datos_otros_lbl_experienciaManejoGrupos     = "¿Cuenta con experiencia en manejo o trato con grupos?"
export const datos_datos_otros_lbl_impartirCapacitacion        = "¿Ha impartido capacitación presencial o virtual?"
export const datos_datos_otros_lbl_hablaLenguaIndigena         = "¿Habla alguna lengua indígena?"
export const datos_datos_otros_lbl_cualLenguaIndigena          = "¿Cuál?"
export const datos_datos_otros_lbl_sabeManejar                 = "¿Sabe manejar?"
export const datos_datos_otros_lbl_tieneLicenciaManejo         = "¿Cuenta con licencia de manejo?"
export const datos_datos_otros_lbl_vehiculoPropio              = "¿Cuenta con vehículo propio?"
export const datos_datos_otros_lbl_marcaModelo                 = "Anote marca y modelo"
export const datos_datos_otros_lbl_utilizarVehiculo            = "¿Está usted dispuesto(a) a utilizar su vehículo para sus actividades si el INE le brinda un apoyo económico para combustible?"
export const datos_datos_otros_lbl_tieneCelular                = "QUITAR... ¿Tiene teléfono celular?"
export const datos_datos_otros_lbl_companiaCelular             = "QUITAR... Anote el nombre de la compañía que le brinda el servicio"
export const datos_datos_otros_lbl_utilizarCelular             = "QUITAR... ¿Está usted dispuesto(a) a utilizar su teléfono celular para sus actividades si el INE le brinda los recursos económicos para la compra de tiempo aire?"
export const datos_datos_otros_lbl_tiempoTraslado              = "¿Cuánto tiempo le lleva trasladarse de su domicilio a la Junta Distrital Ejecutiva?"
export const datos_datos_otros_lbl_tieneInternet               = "¿Cuenta con acceso a Internet en su casa?"
export const datos_datos_otros_lbl_discapacidadesSeleccionadas = "¿Tiene alguna discapacidad?"
export const datos_datos_otros_lbl_especifiqueDiscapacidad     = "Especifique"


/*------------- CONSTANTES REGISTRO ASPIRANTE -------------*/

export const WIZARD_DATOS_CREDENCIAL               = 0
export const WIZARD_DATOS_PERSONALES               = 1
export const WIZARD_DATOS_DIRECCION                = 2
export const WIZARD_DATOS_ESCOLARIDAD              = 3
export const WIZARD_MEDIO_DIFUSION                 = 4
export const WIZARD_EXPERIENCIA_LABORAL            = 5
export const WIZARD_DATOS_OTROS                    = 6

export const REGEX_FORMATO_CLAVE_ELECTOR_FUAR       = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/i;
export const REGEX_FORMATO_CLAVE_ELECTOR            = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$/i;
export const REGEX_FORMATO_SIAP                     = /^([0-9]{13})$/i;
export const REGEX_FORMATO_NOMBRES                  = /^[A-ZÁÉÍÓÚÜÑ\'\°\.\-\/\s]*$/i;
export const REGEX_FORMATO_RFC                      = /^[A-Z]{3,4}[0-9]{6}([A-Z0-9]{3})*$/i;
export const REGEX_FORMATO_CURP                     = /^[A-Z]{4}[0-9]{6}[H|M][A-Z]{5}[A-Z0-9][0-9]$/i;
export const REGEX_FORMATO_CORREO                   = /^[_A-Z0-9-\+]+(\.[_A-Z0-9-]+)*@[A-Z0-9-]+(\.[A-Z0-9]+)*(\.[A-Z]{2,})$/i;
export const REGEX_FORMATO_TELEFONO                 = /^\d{8,10}$/i;
export const REGEX_FORMATO_NO_CARACTERES_ESPECIALES = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

export const REGEX_FORMATO_SOLO_DIGITOS             = /^\d*$/i;

export const FORMATO_FECHA_MOMENT                  = 'DD/MM/YYYY';
export const FORMATO_HORA_MOMENT                   = 'HH:mm:ss';

/*------------- ETIQUETAS MODIFICA CORREO ASPIRANTE -------------*/
export const modifica_correo_lbl_titulo           = "No recibí correo electrónico"
export const modifica_correo_lbl_claveElectorFuar = "Clave de elector o SIAP (antes FUAR)"
export const modifica_correo_lbl_estado           = "Estado"
export const modifica_correo_lbl_seccion          = "Sección electoral"
export const modifica_correo_lbl_cp                = "Código postal"
export const modifica_correo_lbl_telefono          = "Teléfono fijo (10 digitos)"
export const modifica_correo_lbl_correoElectronico = "Nuevo correo electrónico"
export const modifica_correo_lbl_confirmaCorreoElectronico = "Confirma tu nuevo correo electrónico"

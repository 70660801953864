import React from 'react';
import {Icon, Button} from 'antd';
import Template from '../interfaz/Template';
import {borrarOperacion} from '../../redux/actions/accionesOperacion'
import {connect} from 'react-redux'
import { Link } from "react-router-dom";
import operacionExitosa from '../../img/info15_cambios_guardados_validacion_docs.png';

let radioDecision = new Boolean(false);

class OperacionExitosa extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.operaciones)
            this.props.history.push('/acceso');
    }

    componentWillUnmount() {
        this.props.borrarOperacion();
    }

    continuar = () => {
        this.props.history.push(this.props.operaciones.irA);
    }

    render() {
        const operaciones = this.props.operaciones || {};
        console.log(this.props.operaciones.radioDecision);
        if(this.props.operaciones.radioDecision === undefined || this.props.operaciones.radioDecision === "false" || 
            this.props.operaciones.radioDecision === "true"){
            radioDecision = "false";
        }else if(this.props.operaciones.radioDecision === 'permite'){
            radioDecision = "true";
        }

        return(
            <Template contenido={
                <React.Fragment>
                    <div className="exito_container">
                        <div style={{'margin':'90px auto'}}>
                            <center>
                                <p/>
                                    <img src={operacionExitosa} alt="OperacionExitosa" width="100%" height="100%"/>
                                <p/>
                            </center>
                            {radioDecision === 'true' ?
                                <Link className='exito_detalle' to='/cargaDocumentos'>
                                    Ir a Carga de documentos
                                </Link> : 
                                null
                            }
                        </div>
                    </div>
                    {operaciones.irA ? (
                        <div className='exito_continuar'>
                            <Button onClick={this.continuar}>Continuar <Icon type="arrow-right" /></Button>
                        </div>
                    ) : null}
                </React.Fragment>
            }
            guia={false}
            contenidoExpandido={true}/>
        );
    }
};

const mapStateToProps = state => {
    return {
        operaciones: state.operaciones,
    }
}

const mapDispatchToProps = {
    borrarOperacion
}

export default connect(mapStateToProps, mapDispatchToProps)(OperacionExitosa);
import React from 'react';
import axios from 'axios';
import { Col, Modal, Icon} from 'antd';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux'
import * as etiquetas from '../../ApplicationResources';
import solicitudDeclinada from '../../img/info06_Declinado.png';
import claveContacto from '../../img/modal_contacto.png';
import errorImgModal from '../../img/error_icon.svg';

let msjError = "";

class SolicitudDeclinada extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
			modalContactoVisible: false,
            modalContactoInfo : [],
            msjError: "",
            abrirModalError: false
        };
    }

    listo = () => {
        if (this.props.datosAspirante.estatusRegistro !== 4)
            this.props.history.push('/acceso');
       /* this.props.emitirMensaje({
            id: 'mensajeUsuario',
            tipoMensaje: 'error',
            contenido: etiquetas.gral_solicitud_declinada,
        });*/
    }

    handleContactoJDEClick = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/contactoJDE'
            ,{
                claveElectorFuar : this.props.datosAspirante.claveElectorFuar
            },{
                headers: {
                    'Accept': 'application/json'
                    ,'Content-Type': 'application/json'
                }
            },
        ).then(response => {
            if(response.data){
                this.setState({ modalContactoVisible : true,
                                modalContactoInfo : response.data
                           });
            }
        }).catch(error => {
            console.error("Error al consultar contacto en SolicitudDeclinada", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_contacto;
                }
            } else {
                msjError = etiquetas.gral_error_contacto;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

	handleContactoJDEClose = () => {
        this.setState({ modalContactoVisible : false });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        const contacto = this.state.modalContactoInfo.map((item) =>
            <li className="vinetaContacto"><p className="text_junta"><b id="titulo_junta">{item.nombreJunta}</b><br/>
                <Icon id="icon-contacto" type="user" theme="outlined"/><b>Responsable: </b>{item.nombreContacto}<br/>
                <Icon id="icon-contacto" type="environment" theme="outlined"/><b>Domicilio: </b>{item.domicilioJunta} <br/>
                <Icon id="icon-contacto" type="phone" theme="outlined" rotate={90}/><b>Teléfono: </b> {item.telefono} <br/>
                <Icon id="icon-contacto" type="mail" theme="outlined"/><b>Correo electrónico: </b> {item.correoElectronico} <br/></p>
            </li> ) || {};

        return(
            <AuthenticatedComponent listo={this.listo}>
                <Template contenido={
                    <React.Fragment>
                        <center>
                            <p/>
                            <a href="#!" onClick={this.handleContactoJDEClick}>
                                <img src={solicitudDeclinada} alt="SolicitudDeclinada" width="70%" height="60%"/>
                            </a>
                            <p/>
                        </center>
                        <Modal visible={this.state.modalContactoVisible}
                                title={null}
                                centered={true}
                                onOk={this.handleContactoJDEClose}
                                onCancel={this.handleContactoJDEClose}
                                footer={null}
                                className="modal_contacto">
                       
                            <div id="modal_clave">
                                <div id="modal_infoContacto">
                                    Si requieres más información, comunícate vía telefónica, por correo electrónico o acude
                                    directamente al domicilio para atenderte y aclarar tus dudas.
                                </div>
                                <br/><hr/><br/>
                                <div>
                                    <Col xs={24} sm={6} md={12} lg={10}>
                                        <img src={claveContacto} id="modal_contactoImgClave"/>
                                    </Col>
                                    <Col xs={24} sm={6} md={12} lg={12}>
                                        {contacto}
                                    </Col>
                                </div>
                            </div>
                        </Modal>
                        <Modal className="modalError"
                               title={<img  src={errorImgModal} id="img-error-modal"/>}
                               centered
                               onCancel={()=>this.cerrarErrorModal()}
                               visible={this.state.abrirModalError}
                               footer={false}
                               width={400}>
                            {this.state.msjError}
                        </Modal>
                    </React.Fragment>
                }
                contenidoExpandido={true}/>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante;

    if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    else
        datosAspirante = {estatusRegistro: 0};

    return {
        operaciones: state.operaciones,
        datosAspirante: datosAspirante,
    }
}

export default connect(mapStateToProps, {emitirMensaje})(SolicitudDeclinada);

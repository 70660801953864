import React from 'react';
import axios from 'axios';
import {Button, message, Row, Col, Checkbox, Modal} from 'antd';
import Template from '../interfaz/Template';
import {cargarAspirante} from '../../utils/funciones'
import {registrarTerminado, implantarAspirante} from '../../redux/actions/accionesSesion'
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';
import exitoRegistro from '../../img/info01_Validacion_de_correo.png';
import errorImgModal from '../../img/error_icon.svg';

let msjError = "";
class ExitoRegistro extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ventanaExito) {
            this.props.history.push('/registro_aspirante');
        }

        this.state = {
            permitirJDE: false,
            continuar: false,
            cargado: false,
            msjError : "",
            abrirModalError : false
        }

        this.handleDescargarCompulsaClick  = this.handleDescargarCompulsaClick.bind(this);
        this.handleAdjuntarDocumentosClick = this.handleAdjuntarDocumentosClick.bind(this);
    }

    handleDescargarCompulsaClick = () => {
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse'
            ,{
                idProcesoElectoral : this.props.idProcesoElectoral
                , idDetalleProceso : this.props.idDetalleProceso
                , idParticipacion : this.props.idParticipacion
                , idConvocatoria : this.props.idConvocatoria
                , folio : this.props.folioPrincipal
                , claveElector: this.props.claveElectorFuar
            },{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `Compulsa_${this.props.claveElectorFuar}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(error => {
          console.error("Error al descargar la compulsa "+
              "handleDescargarCompulsaClick()",error);
          if (error.response){
              if (error.response.status === 409){
                  msjError = error.response.data.message;
              } else if (error.response.data && error.response.data.causa
                  && error.response.data.causa.includes("Error de validación")){
                  msjError = error.response.data.message;
              } else {
                  msjError = etiquetas.gral_error_cau;
              }
          } else {
              msjError = etiquetas.gral_error_cau;
          }
          this.setState({
            msjError : msjError,
            abrirModalError : true
          });
        });
    }

    handleAdjuntarDocumentosClick = () => {
        cargarAspirante(this.props.folioAcceso).then(datosAspirante => {
            this.props.implantarAspirante(datosAspirante);
            if(datosAspirante.bAcudirJDE === 0){
                this.props.history.push('/cargaDocumentos');
            }else{
                axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/autoriza_aspirante', {
                    idProceso: datosAspirante.idProcesoElectoral,
                    idDetalle: datosAspirante.idDetalleProceso,
                    idParticipacion: datosAspirante.idParticipacion,
                    flujoCompulsa: 1,
                    folioAcceso: datosAspirante.folioAcceso,
                    claveElector: datosAspirante.claveElectorFuar,
                }).then(res => {
                    this.setState({cargado: true})
                    this.props.history.push('/cargaDocumentos');
                })
            }
        });
    }

    componentWillMount(){
        cargarAspirante(this.props.folioAcceso).then(datosAspirante => {
            if(datosAspirante.bAcudirJDE === 1)
                this.setState({permitirJDE: true});
        });
    }

    componentWillUnmount() {
        this.props.registrarTerminado(false);
    }

    activarBoton = (valor) => {
        this.setState({ continuar: valor })
    }

    render() {

        const usuarioInterno = (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I'?true:false);
        const nombreBoton = this.state.permitirJDE === true ? "Continuar" : "Adjuntar Documentos";

        return(
          <>
            <Template contenido={
                <div className="exito_container">
                    <div style={{'height': '100%', 'width':'70%', 'textAlign':'center'}}>
                        <br/><br/>
                        {!usuarioInterno &&
                            <>
                                <img src={exitoRegistro} alt="ExitoRegistro" width="90%" height="50%"/>
                                <p/>
                            </>
                        }{usuarioInterno &&
                            <>
                                {this.props.correoElectronico &&
                                    <>
                                        <span className='exito_detalle'>
                                            Revisa en tu bandeja de entrada o en tus correos no deseados.
                                        </span>
                                    </>
                                }
                                {this.state.permitirJDE === true ?
                                    <>
                                    <div className='exito_informacion'>
                                        Encontramos que el aspirante registrado es Militante o Representante de un partido político.
                                        <Row>
                                            <Col>
                                                <p/>
                                                <Checkbox nombre="jdePermiteRegistro"
                                                          form={this.props.form}
                                                          required={false}
                                                          style={{ 'textAlign': 'justify' }}
                                                          onChange={this.activarBoton}
                                                >
                                                    Permite continuar con la carga de documentos del aspirante.
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    </div>
                                    </>
                                : null}
                                <div className='exito_informacion'>
                                    <div>Otorga al aspirante su usuario y contraseña:</div>
                                    <div className='usu_contr'>
                                        <div><span>Usuario: </span>{this.props.folioAcceso}</div>
                                        <div><span>Contraseña: </span>{this.props.claveElectorFuar}</div>
                                    </div>
                                </div>

                                <span className='exito_detalle'>Continúa con:</span>

                                <div>
                                    <Button type="primary"
                                            className="button_style"
                                            style={{width:170}}
                                            disabled={this.props.claveElectorFuar.length <= 13}
                                            onClick={() => this.handleDescargarCompulsaClick()}>Descargar Compulsa
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button type="primary"
                                            className="button_style"
                                            style={{width:170}}
                                            disabled={this.state.permitirJDE && !this.state.continuar}
                                            onClick={() => this.handleAdjuntarDocumentosClick()}>
                                        {nombreBoton}
                                    </Button>
                                </div>
                                <br/><br/><br/>
                            </>
                        }
                    </div>
                </div>
            }/>

            <Modal
              className="modalError"
              title={<img  src={errorImgModal} id="img-error-modal"/>}
              centered
              onCancel={()=>this.cerrarErrorModal()}
              visible={this.state.abrirModalError}
              footer={false}
              width={400}
            >
              {this.state.msjError}
            </Modal>
          </>
        );
    }
};

const mapStateToProps = state => {
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario
        , ventanaExito: state.sesion.exito
        , folioAcceso: state.operaciones && state.operaciones.folioAcceso ? state.operaciones.folioAcceso : null
        , folioPrincipal: state.operaciones && state.operaciones.folioAcceso ? Number(state.operaciones.folioAcceso.substr(state.operaciones.folioAcceso.length - 4)) : null
        , idProcesoElectoral: state.operaciones && state.operaciones.idProcesoElectoral ? state.operaciones.idProcesoElectoral : null
        , idDetalleProceso: state.operaciones && state.operaciones.idDetalleProceso ? state.operaciones.idDetalleProceso : null
        , idParticipacion: state.operaciones && state.operaciones.idParticipacion ? state.operaciones.idParticipacion : null
        , idConvocatoria: state.operaciones && state.operaciones.idConvocatoria ? state.operaciones.idConvocatoria : null
        , claveElectorFuar: state.operaciones && state.operaciones.claveElectorFuar ? state.operaciones.claveElectorFuar : null
        , correoElectronico: state.operaciones && state.operaciones.correoElectronico ? state.operaciones.correoElectronico : null
    }
}

const mapDispatchToProps = {
    registrarTerminado,
    implantarAspirante
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitoRegistro);

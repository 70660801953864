import React from 'react';
import axios from 'axios';
import {Card, Row, Col, Modal, Select as _Select, message, Popover, Icon, Button} from 'antd';
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';
import {valueUpperCase} from '../../utils/funciones.js';
import InputText from '../interfaz/InputText';
import Checkbox from '../interfaz/Checkbox';
import Select from '../interfaz/Select';
import ayudaClaveFUAR from '../../img/Credencial_FUAR.gif';
import {esClaveEoSiap} from '../../utils/funciones.js';
import {obtenerEstadoPorClave} from '../../utils/funciones.js';
import {obtenerEntidadDeClaveE} from '../../utils/funciones.js';
import moment from 'moment';
import Calendario from '../interfaz/Calendario';
import '../../css/registroAspi.css';
import '../../css/modelStyle.css';
import errorImgModal from '../../img/error_icon.svg';


const { Option } = _Select;
let msjError = "";
let mostrarCamposExtra = false;

class DatosCredencial extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            lstEstados: null,
            lstEstadosDisable: false,
            avisoPrivacidadVisible: false,
            modifica: false,
            infoAspirante : [],
            idEstado : 0,
            seccion : 0,
            nombre : "",
            apellidoP : "",
            apellidoM : "",
            ltsConvocatorias : null,
            puedeRegistrar : true,
            mjs: "",
            modalAbrir: false,
            consultarListaN:null,
            fechaFueraRango:"",
            avanzar : true,
            msjError : "",
            abrirModalError : false,
            sinConvocatoria : "",
            habilitaSeccion: false,
            lstSecciones: null
        };

        this.handleBlurClave = this.handleBlurClave.bind(this);
        this.handleObetenerInfoBlur = this.handleObetenerInfoBlur.bind(this);
        this.handleObtenerConvocatorias  = this.handleObtenerConvocatorias.bind(this);
        this.handleVerificarFechaRrgistro = this.handleVerificarFechaRrgistro.bind(this);
        this.handleObtenrIdDtoSeccion = this.handleObtenrIdDtoSeccion.bind(this);
        this.limpiarValoresCampo = this.limpiarValoresCampo.bind(this);
        this.handleConsultarListaNominal();
        this.mostrarCamposExtra();
    }


    componentDidMount() {
      if(this.props.modifica === true){  // si es modifica entonces desaparece el  guia de estatus
            this.setState({ modifica : true});
            /* Se habilitara el campo de seccion, cuando se identifique que la seccion es de otro corte geografico */
            if(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL].otroCorte === 1){
              this.props.datosUsuario.tipoUsuario === "I" ? this.setState({habilitaSeccion: true}) : this.setState({habilitaSeccion: false});
              this.handleSecciones();
            }
        }else if(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['id_convocatoria'] !== undefined) { 
          this.handleObtenerConvocatorias();
        }
    }

    mostrarCamposExtra(){
      console.log("contenido de datosUsuario ",this.props.datosUsuario);
      if(this.props.datosUsuario !== undefined){
        if ("username" in this.props.datosUsuario) {
          mostrarCamposExtra = true;
        }
      }

      console.log("mostrar campos extra ",mostrarCamposExtra);
    }

    componentWillMount() {
      this.props.getEstados().then(lstEstados => {
            this.setState(
                {
                    lstEstados: lstEstados
                    , lstEstadosDisable: (this.props.datosUsuario &&
                              this.props.datosUsuario.tipoUsuario === 'I' &&
                                           this.props.datosUsuario.idEstado > 0)
                }
                , () => {
                    if (this.props.datosUsuario &&
                        this.props.datosUsuario.tipoUsuario === 'I' &&
                        this.props.datosUsuario.idEstado > 0) {
                        this.props.form.setFieldsValue({
                            idEstado: this.props.datosUsuario.idEstado
                        });
                    }
            });
        })
        .catch(error => {
          console.error("Error al cargar los estados"+
              "componentWillMount()",error);
            if (error.response && error.response.status === 409){
                msjError = error.response.data.message;
            } else {
                msjError = etiquetas.gral_serivicio_no_disponible;
            }

            this.setState({
              msjError : msjError,
              abrirModalError : true
            });
        });

    }

    handleAvisoPrivacidadClick = (e) => {
        e.preventDefault();
        this.setState({
            avisoPrivacidadVisible : true
        });
    }

    handleAvisoPrivacidadClose = () => {
        this.setState({
            avisoPrivacidadVisible : false
        });
    }

    habilitarCampos = () =>{
      this.setState({
        idEstado : 0,
        seccion : 0
      });
      this.cerrarModal();
      this.setState({modalAbrir: false});

      const usuario = this.props.datosUsuario;

      if(!this.state.modifica){
        if(usuario && usuario.tipoUsuario === 'I' && usuario.idEstado > 0){
          Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], { 'seccion': {'value': undefined}});
          this.props.form.setFieldsValue({seccion: undefined});
        }else{
          Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], { 'idEstado': {'value': undefined},
                                                                                 'seccion': {'value': undefined}});
          this.props.form.setFieldsValue({idEstado: undefined, seccion: undefined});
        }
      }
      this.props.wizard.current.cambiaNegocio('claveElectorFuar', false);
    }

    /**
    * Abre la ventana modal
    **/
    abrirModal(){
      var modal = document.getElementById("tvesModal");
      var body = document.getElementsByTagName("body")[0];
      modal.style.display = "block";

      body.style.position = "static";
      body.style.height = "100%";
      body.style.overflow = "hidden";
    }

    /**
    * Cierra la ventana modal
    **/
    cerrarModal(event) {
      var modal = document.getElementById("tvesModal");
      var body = document.getElementsByTagName("body")[0];
      modal.style.display = "none";

      body.style.position = "inherit";
      body.style.height = "auto";
      body.style.overflow = "visible";
    }

    cerrarErrorModal(){
      this.setState({abrirModalError:false});
    }

    /**
    * Muestra los mensajes de error
    **/
    mostrarError(error){
      if (error.response){
          if (error.response.status === 409){
              msjError = error.response.data.message;
          } else if (error.response.data && error.response.data.causa
              && error.response.data.causa.includes("Error de validación")){
              msjError = error.response.data.message;
          } else if (error.response && error.response.status === 500){
              msjError = error.response.data.message;
          } else {
              msjError = etiquetas.gral_error_cau;
          }
      } else {
          msjError = etiquetas.gral_error_cau;
      }
      this.setState({
        msjError : msjError,
        abrirModalError : true
      });
    }

    /**
    * Obtiene la lista de las convocatorias vigentes
    * que se muestran en la lista desplegable para JD
    **/
    handleObtenerConvocatorias(event){
      if(this.props.datosUsuario === undefined){
        return;
      }
      var postData = {
        "idEstado" : this.props.objetos
          [etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value !== undefined ?
          this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value :  0,
        "seccion" : this.props.objetos
            [etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value !== undefined ?
          this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value : 0,
      }

      axios.post(process.env.REACT_APP_API_URL +
        "/centralReclutaSEyCAE/ws/convocatorias", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
            }
         ).then(response => {
           console.log("convocatorias vigentes... ", response,response.data.length);
           if(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idConvocatoria'] !== undefined){
            this.handleConvocatoriaChange();
          }else{
            this.props.form.setFieldsValue({
              id_convocatoria : undefined, fechaSolicitud : undefined
            });
          }
           
           var sinConvocatorias = "";
           if(response.data === null || response.data.length === 0){
             sinConvocatorias = etiquetas.gral_no_convo_activa_seccion;
           }
           this.setState({
             sinConvocatoria:sinConvocatorias,
             ltsConvocatorias:response.data
           })
      }).catch(error => {
        console.error("Error al consultar las convocatorias vigentes "+
            "handleObtenerConvocatorias()",error);
        this.mostrarError(error);
      });
    }

    handleConvocatoriaChange= (value, event) => {
      this.props.getConvocatorias()
        .then(ltsConvocatorias => this.setState({ ltsConvocatorias: ltsConvocatorias }))
        .catch(error => {
          console.error("Error al cargar las convocatorias",error);
            if (error.response && error.response.status === 409){
                msjError = error.response.data.message;
            } else {
                msjError = etiquetas.gral_serivicio_no_disponible;
            }

            this.setState({
              msjError : msjError,
              abrirModalError : true
            });
        });
    }

    /**
    * Revisa si la clave que se ingreso es SIAP o clave de elector.
    * 1.- clave de elector 2.- SIAP
    **/
    handleBlurClave(event) {
      const claveFuar = this.props.form.getFieldValue('claveElectorFuar');
      var claveEoSiap = 0;
      this.setState({
       idEstado : 0,
       seccion : 0
      });

      claveEoSiap = esClaveEoSiap(claveFuar);

      if (claveEoSiap === 1 && this.state.consultarListaN === 1) {
        this.obtenerDatosAspirante(claveFuar);
      }else{
        this.setState({puedeRegistrar:true});
        var datosAspirante = {};
          datosAspirante.numeroElector = this.props.form.getFieldValue('claveElectorFuar');
        this.confirmarRegistro(datosAspirante);
      }

      Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL  ], {
          'datosPersonales': {'value': claveEoSiap}
      });


      this.mostrarCampoEdo();
    }

    /*
    * valida que la entidad del usuario de JD coincida con la de
    * la clave de elector para poder registrarlo o no.
    * 87. naturalizados
    * 88. doble nacionalidad.
    */
    validarEntidad(datosAspirante){
      if (this.props.datosUsuario === undefined) {
        return true;
      }

      this.props.wizard.current.cambiaNegocio('seccion', false);
      var puedeRegistrar = false;

      var entidadAsp = datosAspirante.idEstado;
      var distritoAsp = datosAspirante.idDistrito;
      var entidadUsrJd = this.props.datosUsuario.idEstado;
      var distritoUsrJd = this.props.datosUsuario.idDistrito;

      if (entidadUsrJd === 0 || (entidadUsrJd === entidadAsp &&
                                               distritoUsrJd === distritoAsp)) {
        puedeRegistrar = true;
        this.props.wizard.current.cambiaNegocio('seccion', true);
      }
      this.setState({puedeRegistrar:puedeRegistrar,avanzar:puedeRegistrar});

      return puedeRegistrar;
    }

    /**
    * truco para refrescar y se pudiera tomar el valor.
    **/
    mostrarCampoEdo(){
      this.setState({
        infoAspirante:null
      });
    }

    /**
    * Obtiene la información personal de la lista nominal
    * mediante la clave de elctor
    **/
    obtenerDatosAspirante(claveFuar){
      var postData = {
  			claveElector : claveFuar
  		}
      axios.post(process.env.REACT_APP_API_URL +
  			"/centralReclutaSEyCAE/ws/detalleAspirantes", postData,
  					{
  							headers: {
  								'Accept': 'application/json',
  								'Content-Type': 'application/json'
  							}
  					}
  			 ).then(response => {
           this.setState({
             infoAspirante:response.data.resultadoRepre
           })
           if(response.data.resultadoRepre !== null){
             if(this.validarEntidad(response.data.resultadoRepre)){
                 this.acomodarDatosAspirante(response.data.resultadoRepre);
             }else{
               msjError = "La clave de elector ingresada no pertenece a "+
                                       "tu distrito, intenta nuevamente.";
               this.setState({
                 msjError : msjError,
                 abrirModalError : true
               });
             }
           }else{
               var datosAspirante = {};
               this.setState({puedeRegistrar:true});
               datosAspirante.numeroElector =
                              this.props.form.getFieldValue('claveElectorFuar');
               this.confirmarRegistro(datosAspirante);
           }
      }).catch(error => {
          console.error("Error al consultar clave en LN "+
              "obtenerDatosAspirante()",error);
          this.mostrarError(error);
      });
    }

    /**
    * Acomoda la información obtenida de la lista nominal
    **/
    acomodarDatosAspirante(datosAspirante){
      if(datosAspirante !== null){
        this.setState({
         idEstado : datosAspirante.idEstado,
         seccion : datosAspirante.seccion,
         nombre : datosAspirante.nombre,
         apellidoP : datosAspirante.apellidoPaterno,
         apellidoM : datosAspirante.apellidoMaterno
       });
       Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], {
            'idEstado': {'value': this.state.idEstado},
            'seccion': {'value': this.state.seccion}
       });
       this.props.form.setFieldsValue({idEstado:this.state.idEstado,seccion:this.state.seccion});

       this.confirmarRegistro(datosAspirante);

       this.handleObtenerConvocatorias();

     }else{
         this.setState({
          idEstado : 0,
          seccion : 0,
          nombre : "",
          apellidoP : "",
          apellidoM : ""
        });
         Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], {
             'datosPersonales': {'value': 2}
         });
     }
     this.props.getConvocatoria(1);

    }

    limpiarValoresCampo(event){
      this.props.form.setFieldsValue({
        seccion : undefined,
        id_convocatoria : undefined,
        fechaSolicitud : undefined
      });
      this.props.getConvocatoria();
    }

    /** Verifica si la clave de elector se encuentra en base de datos**/
    confirmarRegistro(datosAspirante) {
        axios.post(process.env.REACT_APP_API_URL +
                                 '/centralReclutaSEyCAE/ws/confirmarRegistro', {
            claveElector: datosAspirante.numeroElector,
            idProceso: etiquetas.id_proceso_electoral,
            idDetalle : etiquetas.id_detalle_proceso,
        },{
            headers: { 'Accept': 'application/json'
                    ,'Content-Type': 'application/json'
            }
        }).then(response => {
            if(response.data !== ""){
                var artClave = " ";
                var esClaveElectorFuar = etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(datosAspirante.numeroElector);
                if(esClaveElectorFuar){
                  artClave = this.props.datosUsuario === undefined ?'Tu SIAP ' : 'El SIAP ';
                }else{
                  artClave = this.props.datosUsuario === undefined ? 'Tu clave de elector ' : 'La clave de elector ';
                }
                this.setState({mjs: artClave + response.data.claveElectorFuar + ' se registró en '
                           + response.data.nombreEstado + ' en el distrito de '
                           + response.data.cabeceraDistrital});
                this.setState({modalAbrir:true});
                Object.assign(
                  this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL],
                  { 'claveElectorFuar': {'value': ""}});
                this.props.form.setFieldsValue({claveElectorFuar: ""});
            }else {
                if(this.state.infoAspirante !== null){
                    this.abrirModal();
                }
            }
        }).catch(error => {
          console.error("Error al verificar que ya exista en aspirantes  "+
                "la clave de elector. confirmarRegistro()",error);
          this.mostrarError(error);
        });
    }

    /**
    * Obtiene el idProceso, detalle
    **/
    handleObetenerInfoBlur(event){

      var postData = {
        "idEstado" : this.props.objetos
            [etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value !== undefined ?
            this.props.objetos
                      [etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value : 0,
        "seccion" : this.props.objetos
            [etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value !== undefined ?
            this.props.objetos
                        [etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value : 0,
        "idConvocatoria" : this.props.objetos
            [etiquetas.WIZARD_DATOS_CREDENCIAL]['id_convocatoria'].value !== undefined ?
            this.props.objetos
                        [etiquetas.WIZARD_DATOS_CREDENCIAL]['id_convocatoria'].value : 0,
        "esJuntaDistrital": this.props.datosUsuario !== undefined ? true : false
      }

      // Obtenido los datos del servidor
      axios.post(process.env.REACT_APP_API_URL +
        '/centralReclutaSEyCAE/ws/convocatoria', postData,
        {
              headers: {
                  'Accept': 'application/json'
                  ,'Content-Type': 'application/json'
              }
          }
      ).then(response => {
        console.log("reponse convocatoria",response);
          if(response.data !== ""){
              Object.assign(this.props.objetos
                          [etiquetas.WIZARD_DATOS_CREDENCIAL], response.data);
              this.props.wizard.current.cambiaNegocio('seccion', true);
              this.handleVerificarFechaRrgistro();
          } else {
            this.setState({fechaFueraRango: etiquetas.gral_error_fecha_fuera_rango});
            this.props.form.setFieldsValue({
              id_convocatoria : undefined,
              fechaSolicitud : undefined
            });
          }
      }).catch(error => {
            console.error("Error al consultar la información de la "+
                "convocatoria en handleObetenerInfoBlur()",error);
          this.mostrarError(error);
      });

    }

    /*
    * Obtiene el valor del parametro 35 de la tablas c_parametros
    * el cual indicará si podemos ontener la información del Aspirante
    * de la lista nominal o no.
    */
    handleConsultarListaNominal(){
      var postData = {
        idProcesoElectoral: 16,
        idDetalleProcesoElectoral : 108,
        idParametro : 35
  		}
      axios.post(process.env.REACT_APP_API_URL +
  			"/centralReclutaSEyCAE/ws/consultarListaN", postData,
  					{
  							headers: {
  								'Accept': 'application/json',
  								'Content-Type': 'application/json'
  							}
  					}
  			 ).then(response => {
           this.setState({
               consultarListaN : response.data.valorParametro
           })
      }).catch(error => {
            console.error("Error al consultar el parametro 35 en "+
                          "handleConsultarListaNominal()",error);
          this.mostrarError(error);
      });
    }

    /*
    * Valida que la fecha registrada para registro se encuentre dentro
    * del rango de la convocatoria.
    */
    handleVerificarFechaRrgistro(event){
      var error = "";
      if(this.state.ltsConvocatorias !== null){
        var idConvocatoria = this.props.form.getFieldValue("id_convocatoria");
        var fechaRegistro = new Date(
            this.props.form.getFieldValue("fechaSolicitud").format("YYYY-MM-DD"));
        var fechaInicio;
        var fechaFin;
        console.info("valores",idConvocatoria,fechaRegistro);
        console.info("ltsConvocatorias ",this.state.ltsConvocatorias.length);
        for(var i=0; i < this.state.ltsConvocatorias.length;i++){
          if(this.state.ltsConvocatorias[i].idConvocatoria === idConvocatoria){
            fechaInicio = new Date(this.state.ltsConvocatorias[i].fechaRegistroInicio);
            fechaFin = new Date(this.state.ltsConvocatorias[i].fechaRegistroFin);
          }
        }
        // se setean las hrs, min, s, ms para que solo se comparen las fechas.
        fechaRegistro.setHours(0,0,0,0);
        fechaInicio.setHours(0,0,0,0);
        fechaFin.setHours(0,0,0,0);

        if(fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin){
          console.info("se puede registrar");
          this.props.wizard.current.cambiaNegocio('seccion', true);
        } else {
          console.info("La fecha de regsitro está fuera de rango-DatosCredencial.js");
          this.props.wizard.current.cambiaNegocio('seccion', false);
          error = etiquetas.gral_error_fecha_fuera_rango;
        }
        console.log("avanzar",this.state.avanzar);
        if(this.state.avanzar === false){
          this.props.wizard.current.cambiaNegocio('seccion', false);
        }


      }else{
        console.info("la lista de convocatorias está vacia",
                                                   this.state.ltsConvocatorias);
         this.props.form.setFieldsValue({
           seccion : undefined,
           id_convocatoria : undefined,
           fechaSolicitud : undefined
         });
         error = etiquetas.gral_error_fecha_fuera_rango;
      }

      this.setState({fechaFueraRango: error});
    }

    /*
    *
    */
    handleObtenrIdDtoSeccion(event){
      if (this.props.datosUsuario === undefined &&
                            "username" in this.props.datosUsuario) {
        return true;
      }
      if(this.props.datosUsuario.idEstado === 0 &&
                                     this.props.datosUsuario.idDistrito === 0) {
          this.handleObtenerConvocatorias();
          return true;
      }
      var claveFuar = this.props.form.getFieldValue("claveElectorFuar");
      console.info("Se valida el estado y distrito de la clave de elector"+
      "contra los del usuario jd que lo está registrando");

      var idEstado = this.props.form.getFieldValue("idEstado") !== undefined ?
                                this.props.form.getFieldValue("idEstado") : 0;
      var seccion = this.props.form.getFieldValue("seccion") !== undefined ?
                                  this.props.form.getFieldValue("seccion") : 0;

      if((idEstado === 0 || seccion === 0) ||
          ( idEstado === ' ' || seccion === '') ||
          (claveFuar === undefined || claveFuar === '')){
          return false;
      }
      var postData = {
        idEstado : idEstado !== '' ? idEstado : 0,
        seccion : seccion !== '' ? seccion : 0
      }
      console.info("postData",postData);
      axios.post(process.env.REACT_APP_API_URL +
  			"/centralReclutaSEyCAE/ws/consultarDsitrito", postData,
  					{
  							headers: {
  								'Accept': 'application/json',
  								'Content-Type': 'application/json'
  							}
  					}
  			 ).then(response => {
           var error = this.verificarDistritoAspUsr(response.data.idDistrito);
           console.log("la clave de elector pertence al usuario...", error);
           if(error !== false){
             this.handleObtenerConvocatorias();
           }else{
             this.setState({
               msjError : "La clave de elector ingresada no pertenece a "+
                                       "tu distrito, intenta nuevamente.",
               abrirModalError : true
             });
           }

      }).catch(error => {
            console.error("Error al consultar el distrito por la sección en"+
            "handleObtenrIdDtoSeccion()",error);
        this.mostrarError(error);
      });
    }

    verificarDistritoAspUsr(distritoAsp){
      this.props.wizard.current.cambiaNegocio('seccion', false);
      var puedeRegistrar = false;
      var claveFuar = this.props.form.getFieldValue("claveElectorFuar");

      if(claveFuar === undefined || claveFuar === ''){
        this.setState({avanzar:puedeRegistrar});
        return puedeRegistrar;
      }
      var claveEoSiap = esClaveEoSiap(claveFuar);

      if (claveEoSiap === 1) {
        var entidadAsp = this.props.form.getFieldValue("idEstado") !== undefined
                                ? this.props.form.getFieldValue("idEstado") : 0;
        var entidadUsrJd = this.props.datosUsuario.idEstado;
        var distritoUsrJd = this.props.datosUsuario.idDistrito;
        console.info("valores para validar su puede registar la clave",
                  entidadUsrJd , entidadAsp ,distritoUsrJd , distritoAsp);

        if((entidadUsrJd === 0 && distritoUsrJd === 0) ||
            (entidadUsrJd > 0 && distritoUsrJd === 0)){
              return true
        }
        if((entidadUsrJd === entidadAsp && distritoUsrJd === distritoAsp)) {
          puedeRegistrar = true;
          this.props.wizard.current.cambiaNegocio('seccion', true);
        }else {
          this.props.form.setFieldsValue({'seccion': undefined,
                                                   'claveElectorFuar':undefined});
        }
      }else{
        puedeRegistrar = true;
      }
      this.setState({avanzar:puedeRegistrar});
      return puedeRegistrar;
    }

    handleSecciones(){
      var postData = {
        "idDetalleProceso": etiquetas.id_detalle_proceso,
        "idParticipacion": this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL].idParticipacion,
        "idEstado" : this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value
      }

      const jwt = localStorage.getItem('jwt-token');
      axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/secciones', postData,
      {
        headers: { 'Accept': 'application/json'
                  ,'Content-Type': 'application/json'
                  ,'Authorization': jwt
        }
      }).then(response => {
        if(response.data.length !== null || response.data.length !== 0){
          this.setState({lstSecciones: response.data});
        }
      }).catch(error => {
        console.error("Error al consultar las secciones. handleSecciones()",error);
        this.mostrarError(error);
      });
      
    }

    render() {
        const claveFUAR	= 	<Popover overlayClassName='pop_tooltip'
                                     disabled={this.state.modifica}
                            content={<img src={ayudaClaveFUAR} width='350px'/>}>
								<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
							</Popover>
        const soloSIAP = this.props.soloFuar || (this.state.modifica && this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'E');
        const modificaSeccion = (!this.state.modifica || this.state.modifica) && this.state.habilitaSeccion && this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I';
  
        return(
            <React.Fragment>
              <Modal
                className="modalError"
                title={<img  src={errorImgModal} id="img-error-modal"/>}
                centered
                onCancel={()=>this.cerrarErrorModal()}
                visible={this.state.abrirModalError}
                footer={false}
                width={400}
              >

                {this.state.msjError}
              </Modal>
              <div id="tvesModal" class="modalContainerCredencial">
                <div class="modal-content">
                <br/>
                <p>
                  Su nombre es:
                </p>
                <p>
                  <b>{this.state.nombre}</b>
                </p>
                <br/><br/>
                <p>
                  <Button class="close" onClick={this.cerrarModal}
                      style={{'marginRight':'15px'}}>
                    Si
                  </Button>
                  <Button class="close" onClick={this.habilitarCampos}>
                    NO
                  </Button>
                </p>
                </div>
              </div>
                <Card title={etiquetas.datos_credencial_lbl_titulo} bordered={false}>
                  <Row>
                      <Col sm={24} md={24} lg={24}>
                          <Checkbox nombre="avisoPrivacidad"
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{validator : (rule, value, cb) => (value === true ? cb() : cb(true))}]}
                                    style={{ 'textAlign': 'justify' }}
                                    disabled={this.state.modifica}
                                    onClick={()=>this.props.wizard.current.form.validateFields()}
                                    >
                              He leído el
                              <a href="#!" onClick={this.handleAvisoPrivacidadClick}> Aviso de Privacidad </a>
                              y otorgo mi consentimiento para que los datos personales sean tratados conforme al mismo.
                          </Checkbox>
                      </Col>
                  </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre='claveElectorFuar'
                                onBlur={this.handleBlurClave}
                                validacionNegocio={false}
                                placeholder={etiquetas.datos_credencial_lbl_claveElectorFuar}
                                form={this.props.form}
                                required={true}
                                msjRequerido={etiquetas.general_dato_requerido}
                                maxLength={18}
                                disabled={soloSIAP}
                                customTooltip={claveFUAR}
                                rules={[{
                                          min: 13,
                                          max: 18,
                                          pattern: etiquetas.REGEX_FORMATO_CLAVE_ELECTOR_FUAR
                                      }]}
                                custom={
                                  {
                                      getValueFromEvent: valueUpperCase
                                  }
                                }/>
                            </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col sm={24} md={12} lg={12}
                          className={this.state.idEstado > 0 ? "disabledSeccion" : "enableSeccion"}>

                            <Select nombre='idEstado'
                                    placeholder={etiquetas.datos_credencial_lbl_estado}
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{
                                            type: 'number'
                                        }]}
                                    showSearch={true}
                                    onChange={this.limpiarValoresCampo}
                                    selectedValue={this.state.idEstado}
                                    disabled={this.state.modifica || this.state.lstEstadosDisable}
                              >
                                    {
                                        this.state.lstEstados &&
                                        this.state.lstEstados.map((estado, index) => (
                                            <Option key={index} value={estado.idEstado}>
                                              {estado.nombreEstado}
                                            </Option>
                                        ))
                                    }
                            </Select>

                        </Col>
                        <Col sm={24} md={12} lg={12}
                          className={this.state.seccion > 0 || !this.state.puedeRegistrar ? "disabledSeccion" : "enableSeccion"}>
                          {!modificaSeccion ?
                            <InputText nombre='seccion'
                               placeholder={etiquetas.datos_credencial_lbl_seccion}
                               form={this.props.form}
                               inputType='text'
                               value={this.state.seccion}
                               required={true}
                               msjRequerido={etiquetas.general_dato_requerido}
                               maxLength={4}
                               //disabled
                               rules={[{
                                        max: 4
                                        , pattern: /^\d{1,4}$/i
                                    }]}
                               onBlur={this.props.datosUsuario === undefined && !mostrarCamposExtra ?
                                 this.props.getConvocatoria : this.handleObtenrIdDtoSeccion}
                               custom={{
                                    getValueFromEvent: (event, previo) => {
                                        if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                            return	event.target.value;
                                        }
                                        return previo;
                                    }
                                }}
                                validacionNegocio={!this.props.modifica}
                                disabled={this.state.modifica && !this.state.habilitaSeccion}
                              />
                            :<Select nombre='seccion'
                                    placeholder={etiquetas.datos_credencial_lbl_seccion}
                                    form={this.props.form}
                                    required={this.props.datosUsuario !== undefined ? true :false}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{
                                            type: 'number'
                                        }]}
                                    showSearch={true}
                                    selectedValue={this.state.seccion}
                                    disabled={this.state.modifica && !this.state.habilitaSeccion && this.props.datosUsuario.tipoUsuario === "I"}
                              >
                              {
                                this.state.lstSecciones &&
                                this.state.lstSecciones.map((seccion, index) => (
                                    <Option key={index} value={seccion.seccion}>
                                      {seccion.seccion}
                                    </Option>
                                ))
                              }
                            </Select> }
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col sm={24} md={12} lg={12}
                          className={mostrarCamposExtra ? "show" : "hide"}
                          id={this.state.sinConvocatoria !== "" ? "sinConvocatoria":"conConvocatoria"}>

                            <Select nombre='id_convocatoria'
                                    placeholder={etiquetas.datos_credencial_lbl_convocatoria}
                                    form={this.props.form}
                                    required={this.props.datosUsuario !== undefined ? true :false}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{
                                            type: 'number'
                                        }]}
                                    showSearch={true}
                                    selectedValue={this.state.idConvocatoria}
                                    disabled={this.state.modifica}
                                    >
                                    {
                                        this.state.ltsConvocatorias &&
                                        this.state.ltsConvocatorias.map((convocatoria, index) => (
                                            <Option key={index} value={convocatoria.idConvocatoria}>
                                              <b>{convocatoria.numeroConvocatoria+" "}</b>
                                              {"("+convocatoria.fechaRegistroInicio.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')+" a "}
                                              {convocatoria.fechaRegistroFin.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')+")"}
                                            </Option>
                                        ))
                                    }
                            </Select>
                            <div className={this.state.sinConvocatoria !== ""
                                   ? "mostrarErrorFecha":"ocultarErrorFecha"}>
                              {this.state.sinConvocatoria}
                            </div>
                        </Col>
                        <Col sm={24} md={12} lg={12} style={{'marginTop':'5px'}}
                            className={mostrarCamposExtra? "show" : "hide"}
                            id={this.state.fechaFueraRango !== "" ? "campoIncorrecto":"campoCorrecto"}>

                            <Calendario nombre="fechaSolicitud"
                                placeholder={etiquetas.datos_credencial_lbl_fechaRegistro}
                                label={etiquetas.datos_credencial_lbl_fechaRegistro}
                                required={this.props.datosUsuario !== undefined ? true :false}
                                msjRequerido={etiquetas.general_dato_requerido}
                                format={etiquetas.FORMATO_FECHA_MOMENT}
                                formato="dd/mm/aaaa"
                                showTime={false}
                                showToday={true}
                                form={this.props.form}
                                onChange={this.handleObetenerInfoBlur}
                                minDate="01/01/1900"
                                maxDate={moment().format(etiquetas.FORMATO_FECHA_MOMENT)}
                                disabled={this.state.modifica}

                              />
                              <div className={this.state.fechaFueraRango !== ""
                                     ? "mostrarErrorFecha":"ocultarErrorFecha"}>
                                {this.state.fechaFueraRango}
                              </div>
                        </Col>
                    </Row>

                </Card>
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.avisoPrivacidadVisible}
                    onOk={this.handleAvisoPrivacidadClose}
                    onCancel={this.handleAvisoPrivacidadClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>AVISO DE PRIVACIDAD</h3>
                        <h5 style={{'textAlign': 'center'}}>PARA EL REGISTRO DE ASPIRANTES A SE Y CAE DEL PROCESO ELECTORAL 2020-2021</h5>
                        <p style={{'textAlign': 'justify'}}>El Instituto Nacional
                          Electoral, con domicilio en Viaducto Tlalpan #100, Col. Arenal
                          Tepepan, Alcaldía Tlalpan, C.P. 14610, Ciudad de México informa que
                           los datos proporcionados por las y los ciudadanos serán utilizados
                           para el registro en el proceso
                             de reclutamiento del personal eventual que
                              colaborará con el INE como Supervisores/as Electorales o
                              Capacitadores/as-Asistentes Electorales conforme a lo establecido
                              en el artículo 16 y 113 fracción I de la Ley Federal de Transparencia
                              y Acceso a la Información Pública, artículos 3 fracción II, 16, 17, 21, 26 y 27
                              de la ley General de Protección de Datos Personales en Posesión de Sujetos Obligos,
                              y conforme a la normatividad electoral.
                            </p>
                        <p style={{'textAlign': 'justify'}}>Si desea conocer
                          nuestro aviso de privacidad integral en la siguiente dirección electrónica:
                            <a href="https://www.ine.mx/transparencia/listado-bases-datos-personales" rel="noopener noreferrer" target="_blank"> https://www.ine.mx/transparencia/listado-bases-datos-personales/</a>.
                        </p>
                    </div>
                </Modal>
                <Modal title={null}
                       centered
                       visible={this.state.modalAbrir}
                       onOk={null}
                       onCancel={null}
                       closable={false}
                       footer={null}>
                    <div style={{'fontSize': '16px'}}>
                        {this.state.mjs}
                    </div>
                    <br/><br/>
                    <div id="modal_botones">
                        <Button className="botonesModal" style={{width: 150}} onClick={this.habilitarCampos}> Aceptar </Button>
                    </div>
                </Modal>
           </React.Fragment>
        );
    }
};

const mapStateToProps = state => {

    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ?
                                           state.sesion.datosUsuario: undefined;
    return {
        datosUsuario: datosUsuario,
    };
}

export default connect(mapStateToProps)(DatosCredencial);

import React, {Component} from 'react'
import { Row, Col, Icon, Button, Select, Modal} from 'antd';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import axios from 'axios';
import Template from '../interfaz/Template';
import '../../css/administrarRegistro.css';
import AuthenticatedComponent from '../AuthenticatedComponent';
import errorImgModal from '../../img/error_icon.svg';
import icono_exito from '../../img/icono_exito.png';
import icono_basura from '../../img/icono_basura.png';

import * as etiquetas from '../../ApplicationResources';

let msjError = "";
const rolesCaptura =  [ "ELECINE.ADMIN.OC", "ELECINE.UNICOM.OC", "ELECINE.CAPTURA.OC", "ELECINE.CAPTURA.JD", "ELECINE.CAPTURA_VECEYEC.JD" ];
const {Option} = Select;

class AdministrarRegistro extends Component {

    constructor(props) {
        super(props);

        this.state = {
            datosAspirante : this.props.datosAspirante || {},
            mostrarOcultarCambioEstatus: false,
            claveRepetida: false,
            numConv: 0,
            estatusActual: "",
            cambioEstatus: [],
            selectEstatus: false,
            guardar: true,
            valueSelect: null,
            cargando: false,
            modalOk: false,
            modalEliminar: false,
            mostrarConfirmacion: false,
            msjError: "",
            abrirModalError: false,
            abrirModalPermisos: false
        };   
    }
    componentWillMount=() =>{
        this.setState({modalReenvioOk: false});
    }

    listo = () => {
        const datosAspirante = this.props.datosAspirante;

        if (!datosAspirante) {
            this.props.history.push('/bandeja');
            return;
        }

        if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario)){
            this.setState({abrirModalPermisos: true})
        }
        this.estatusActual(datosAspirante);
        this.verificaClaveRepetida(datosAspirante);
        this.asignarEstatus(datosAspirante);
    }

    verificaClaveRepetida=(datosAspirante)=>{
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/esClaveRepetida', {
            idProcesoElectoral: datosAspirante.idProcesoElectoral,
            idDetalleProceso: datosAspirante.idDetalleProceso,
            idParticipacion: datosAspirante.idParticipacion,
            idConvocatoria: datosAspirante.idConvocatoria,
            folio: datosAspirante.folio,
            claveElectorFuar: datosAspirante.claveElectorFuar
        },{
            responseType: 'application/json',
            headers : {Authorization: localStorage.getItem('jwt-token')}
        }).then(res => {
            if(res.data.esClaveRepetida === true){
                this.setState({claveRepetida: true});
            }else{
                this.setState({claveRepetida: false});
            }
        });
    }

    /******Asigna el estatus actual del aspirante******/
    estatusActual=(datosAspirante)=>{
        const estatusRegistro = datosAspirante.estatusRegistro;
        const bObservacion = datosAspirante.bObservacion;
        const bAcudirJDE = datosAspirante.bAcudirJDE;
        const bValidaCorreo = datosAspirante.bValidaCorreo;

        if(estatusRegistro === 1 && bValidaCorreo === null)
           this.setState({estatusActual: "Registrado sin correo validado"});
        if(estatusRegistro === 1 && bValidaCorreo === 1)
           this.setState({estatusActual: "Registrado con correo validado"});
        if(estatusRegistro === 2 && bObservacion === null)
            this.setState({estatusActual: "Por validar"});
        if(estatusRegistro === 2 && bObservacion === 1)
            this.setState({estatusActual: "Con observaciones"});
        if((estatusRegistro === 1 && bAcudirJDE === 1) || (estatusRegistro === 2 && bObservacion === 1 && bAcudirJDE === 1))
            this.setState({estatusActual: "Ciudadano que acude a JDE"});
        if(estatusRegistro === 4 && bObservacion === 1)
            this.setState({estatusActual: "Declinado"});
        if(estatusRegistro === 3 && bObservacion === 0 && bAcudirJDE === 0)
            this.setState({estatusActual:"Finalizado"});
        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtenerNoConvocatoria', {
            idDetalleProceso: datosAspirante.idDetalleProceso,
            idParticipacion: datosAspirante.idParticipacion,
            idConvocatoria: datosAspirante.idConvocatoria
        },{
            responseType: 'application/json',
            headers : {Authorization: localStorage.getItem('jwt-token')}
        }).then(res => {
            this.setState({numConv: res.data.numeroConvocatoria});
        })
    }
    
    /******Asigna los posibles cambios de estatus******/
    asignarEstatus=(datosAspirante)=>{
        const estatusRegistro = datosAspirante.estatusRegistro;
        const bObservacion = datosAspirante.bObservacion;
        const bAcudirJDE = datosAspirante.bAcudirJDE;
        const bValidaCorreo = datosAspirante.bValidaCorreo;
        var cambiarEstatus = [];

        if(estatusRegistro === 1 && bValidaCorreo === null)
            cambiarEstatus.push({value:  4, label:'Validar correo'});
        /*if(estatusRegistro === 2 && bValidaCorreo === 1 || bObservacion === 1)
            cambiarEstatus.push({ value: '5', label: 'Permitir registro'});*/
        if(estatusRegistro === 2 && bObservacion === 1) //Con observaciones
            cambiarEstatus.push({ value: '1', label: 'Por validar'}, { value: '2', label: 'Acudir a JDE'});
        if(estatusRegistro === 4 && bObservacion === 1) //Declinado
            cambiarEstatus.push({ value: '1', label: 'Por validar'});
        if(estatusRegistro === 3 && bObservacion === 0 && datosAspirante.idSedeProgramada === null){ //Finalizado sin sede
            cambiarEstatus.push({ value: '1', label: 'Por validar'});
        }else if(estatusRegistro === 3 && bObservacion === 0 && datosAspirante.idSedeProgramada !== null){ //Finalizado con sede
            cambiarEstatus.push({ value: '1', label: 'Por validar'}, { value: '3', label: 'Quitar sede de examen'});
        }
        cambiarEstatus = cambiarEstatus.sort((a, b) => a.value - b.value )
        this.setState({cambioEstatus: cambiarEstatus});
    }

    abrirCambioEstatus=()=>{
        this.setState(prevState => ({mostrarOcultarCambioEstatus: !prevState.mostrarOcultarCambioEstatus}));
    }

    onChange=(valueSelect)=> {
        console.log('valueSelect = ', valueSelect);
        this.setState({valueSelect, selectEstatus: true});
    }
    
    guardarEstatus=()=>{
        this.setState({cargando: true});
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/administrarRegistro', {
            idProcesoElectoral : this.state.datosAspirante.idProcesoElectoral
            , idDetalleProceso : this.state.datosAspirante.idDetalleProceso
            , idParticipacion : this.state.datosAspirante.idParticipacion
            , idAspirante : this.state.datosAspirante.idAspirante
            , folio : this.state.datosAspirante.folio
            , claveElectorFuar: this.state.datosAspirante.claveElectorFuar
            , selectValue: this.state.valueSelect
        },{
            headers: {
                'Content-Type': 'application/json'
                , 'Authorization': localStorage.getItem("jwt-token")        
            }
        }).then(res => {
            if(res.data.code === 200){
                this.setState({cargando: false, guardar: false, modalOk:true});
                console.log('Se actualiza BD');
            }
        }).catch(error => {
            console.error("Error al actualizar el registro", error);
            this.setState({cargando: false});
            msjError = etiquetas.gral_error_administrador;
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    confirmaEliminar=()=>{
        this.setState({mostrarConfirmacion: true})
    }
    cancelarConfirmacion = () => {
        this.setState({mostrarConfirmacion: false});
    }

    eliminarRegistro=()=>{
        this.setState({mostrarConfirmacion: false, cargando: true});
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/administrarEliminarRegistro', {
            idProcesoElectoral : this.state.datosAspirante.idProcesoElectoral
            , idDetalleProceso : this.state.datosAspirante.idDetalleProceso
            , idParticipacion : this.state.datosAspirante.idParticipacion
            , idAspirante : this.state.datosAspirante.idAspirante
            , folio : this.state.datosAspirante.folio
            , claveElectorFuar: this.state.datosAspirante.claveElectorFuar
        },{
            headers: {
                'Content-Type': 'application/json'
                , 'Authorization': localStorage.getItem("jwt-token")        
            }
        }).then(res => {
            if(res.data.code === 200){
                this.setState({cargando: false, guardar: false, modalEliminar:true});
                console.log('Se actualiza BD');
            }
        }).catch(error => {
            console.error("Error al eliminar el registro", error);
            this.setState({cargando: false});
            msjError = etiquetas.gral_error_administrador;
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    cerrarOkModal= () =>{
        this.setState({modalOk: false});
        //window.location.reload(); //refresca la pantalla
        //window.location.href = window.location.href;
    }

    cerrarEliminarModal=()=>{
        this.setState({modalEliminar: false});
    }

    cerrarErrorModal=()=> {
        this.setState({abrirModalError:false});
        if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario) || this.props.datosAspirante.correo === null){
            this.props.history.push('/bandeja');
        }
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};

        return (
            <AuthenticatedComponent externo={false} listo={this.listo}>
            <Template contenido={
                <div className="contenedorReenvio">
                    <Link to={`/bandeja`}className="btn btn-success mr-2">
                        <Icon type="arrow-left" /> Regresar
                    </Link>
                    <div className="container_administrador">
                        <div style={{textAlign:'center'}}>
                            <span className='exito_titulo' style={{fontSize: '2em'}}><b> Administrar Registro </b></span> 
                        </div>
                        <br/>
                        <div className="datos_Registro">
                            <Row>
                                <Col xs={12} sm={2} md={6} lg={12}>
                                    <span className="titulo_admin">Aspirante</span>
                                </Col>
                                <Col xs={12} sm={2} md={6} lg={12}>
                                    <span className="estatusActual"><b>Estatus actual: </b> {this.state.estatusActual}</span>
                                </Col>
                            </Row>
                            <Row style={{padding: '10px 0'}}>
                                <Col xs={24} sm={2} md={6} lg={6}>
                                    <span className='datosAspirante_admin'><b>Nombre: </b> 
                                        {datosAspirante.nombre +" "+ datosAspirante.apellidoPaterno +" "+ datosAspirante.apellidoMaterno}</span>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <span className='datosAspirante_admin'><b>Folio: </b> {datosAspirante.folio}</span>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={6}>
                                    <span className='datosAspirante_admin'><b>Clave de elector: </b> {datosAspirante.claveElectorFuar}</span>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <span className='datosAspirante_admin'><b>Convocatoria: </b> {this.state.numConv}</span>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <span className='datosAspirante_admin'><b>Sección: </b> {datosAspirante.seccionDom}</span>
                                </Col>
                            </Row>
                        </div>
                        <br/><hr/><br/>
                        <center>
                            <Row>
                                <Col xs={24} sm={2} md={0} lg={6}/>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <Button disabled={this.state.claveRepetida}
                                            onClick={()=>this.abrirCambioEstatus()}>
                                                Modificar estatus
                                    </Button>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <Button disabled={datosAspirante.estatusRegistro === 4 ? false:true} //disabled={!this.state.guardar}
                                            onClick={()=>this.confirmaEliminar()}
                                            >
                                                Eliminar registro
                                    </Button>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={4}>
                                    <Button disabled={true}
                                            //onClick={()=>this.reenviarNotificaciones()}
                                            >
                                                Bitácora de registro
                                    </Button>
                                </Col>
                                <Col xs={24} sm={2} md={6} lg={6}/>
                            </Row>
                            <div className={this.state.mostrarOcultarCambioEstatus ? "show" : "hide"} id="div_Admin">
                                <Row>
                                    <Col xs={24} sm={0} md={0} lg={8}/>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <Select placeholder="Selecciona estatus"
                                                onChange={this.onChange}
                                                style={{ width: "100%" }}>
                                            {
                                                this.state. cambioEstatus &&
                                                this.state.cambioEstatus.map((opcion, index) => (
                                                    <Option key={index} value={opcion.value}>{opcion.label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                    <Col xs={24} sm={0} md={0} lg={8}/>
                                </Row>
                                <br/><br/><br/>
                                <Row>
                                    <Col xs={24} sm={0} md={0} lg={8}/>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <Button style={{width:180, float:'right'}} 
                                                disabled={!this.state.selectEstatus}
                                                onClick={()=>this.guardarEstatus()}
                                                >
                                                    Guardar cambios
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={0} md={0} lg={8}/>
                                </Row>
                            </div>   
                                
                        </center>
                        <br/>
                    </div>                
                </div>
            }
            guia={false}
            cargando={this.state.cargando}
            />
                <Modal visible={this.state.modalOk} 
                       title={null}
                       onOk={()=>this.cerrarOkModal()}
                       onCancel={()=>this.cerrarOkModal()}
                       centered
                       width={307}
                       footer={null}>
                    <Row style={{padding: '10px 0'}}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <img src={icono_exito} id="iconoAdmin"/></Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <p className="parrafoCentrado"> El registro ha sido actualizado</p>
                        </Col>
                   </Row>
                </Modal>
                <Modal visible={this.state.modalEliminar} 
                       title={null}
                       onOk={()=>this.cerrarEliminarModal()}
                       onCancel={()=>this.cerrarEliminarModal()}
                       centered
                       width={307}
                       footer={null}>
                    <Row style={{padding: '10px 0'}}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <img src={icono_basura} id="iconoAdmin"/></Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <p className="parrafoCentrado"> El registro se eliminó correctamente</p>
                        </Col>
                   </Row>
                </Modal>
                <Modal visible={this.state.mostrarConfirmacion}
                       title={null}
                       centered
                       closable={false}
                       onOk={this.aceptarConfirmacion}
                       onCancel={this.cancelarConfirmacion}
                       footer={null}
                       width={340}>
                    <div className="mensaje_confirmacion">¿Está seguro de eliminar el registro?</div>
                        <br/>
                    <div id="modal_botones">
                        <Button className="botonesModal" style={{borderRadius: '5px'}} onClick={this.eliminarRegistro}>
                            Si
                        </Button>
                        <Button className="botonesModal" style={{borderRadius: '5px'}} onClick={this.cancelarConfirmacion}>
                            No
                        </Button>
                    </div>
                </Modal>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.abrirModalPermisos}
                    onOk={()=>this.cerrarErrorModal()}
                    onCancel={()=>this.cerrarErrorModal()}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
                        <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
                    </div>
                </Modal>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante, datosUsuario;

    if (state.sesion.datosUsuario) {
        datosUsuario = state.sesion.datosUsuario;
        datosAspirante = datosUsuario.datosAspirante;
    }

    return {
        datosUsuario: datosUsuario,
        datosAspirante: datosAspirante,
    };
}

export default connect(mapStateToProps)(AdministrarRegistro);
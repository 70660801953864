import React, {Component} from 'react'
import { Row, Col, Icon, Button, message, Checkbox, Checkbox as CheckboxANTD, Modal} from 'antd';
import { Link } from "react-router-dom";
import axios from 'axios';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import errorImgModal from '../../img/error_icon.svg';
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';
import { ConsoleWriter } from 'istanbul-lib-report';

let msjError = "";

class DescargaAcuses extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            datosAspirante : this.props.datosAspirante || {},
            descarga: false,
            checkedValues: null,
            acuses: [],
            cargando: false,
            msjError: "",
            abrirModalError: false
        };  
    }

    funcionListo= () => {
        this.buscarExpediente();
        const datosAspirante = this.props.datosAspirante || {};
        if (!datosAspirante){
            this.props.history.goBack();
        }
    }

    buscarExpediente(){
        axios.post(process.env.REACT_APP_API_URL + "/centralReclutaSEyCAE/ws/buscarExpediente", {
            idProcesoElectoral : this.state.datosAspirante.idProcesoElectoral
            , idDetalleProceso : this.state.datosAspirante.idDetalleProceso
            , idParticipacion : this.state.datosAspirante.idParticipacion
            , idConvocatoria : this.state.datosAspirante.idConvocatoria
            , folio : this.state.datosAspirante.folio
            , claveElector: this.state.datosAspirante.claveElectorFuar
        },{
            headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
        }).then(response => {
            if (!response.data.existeExpediente || response.data.nombreArchivos.length === 0) {
                msjError = 'No se encontraron sus Acuses';
                this.props.history.goBack();
                this.setState({msjError: msjError, abrirModalError: true});
                return;
            }

            if(response.data.nombreArchivos !== null){
                var acusesDescarga = [];
                this.nombreArchivos = response.data.nombreArchivos;
                
                this.nombreArchivos.forEach(valor => {
                    if((valor.split('_')[0]) === `Compulsa`)
                        acusesDescarga.push({value: 1 , label:'Compulsa'});
                    if((valor.split('_')[0]) === `Recepcion`)
                        acusesDescarga.push({value: 2 , label:'Recepción de Documentos'});
                    if((valor.split('_')[0]) === `Capsula`)
                        acusesDescarga.push({value: 3 , label:'Cápsula de Inducción'});
                    if((valor.split('_')[0]) === `Validacion`)
                        acusesDescarga.push({value: 4 , label:'Documentos Validados'});
                });
                acusesDescarga = acusesDescarga.sort((a, b) => a.value - b.value )
                this.setState({acuses:acusesDescarga});
            }
        }).catch(error => {
            if (error.response){
                console.error("error...", 5);
              }
        });
    }

    regresar = () =>{
        this.props.history.goBack();
    }

    onChange(checkedValues) {
        console.log('checked = ', checkedValues);
        this.setState({checkedValues});
        this.setState({descarga:checkedValues});
    }

    descargarAcuses=()=>{
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcusesZip', {
            idProcesoElectoral : this.state.datosAspirante.idProcesoElectoral
            , idDetalleProceso : this.state.datosAspirante.idDetalleProceso
            , idParticipacion : this.state.datosAspirante.idParticipacion
            , idConvocatoria : this.state.datosAspirante.idConvocatoria
            , folioPrincipal : this.state.datosAspirante.folio
            , claveElectorFuar: this.state.datosAspirante.claveElectorFuar
            , checkedValues: this.state.checkedValues
        },{
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data], {type: "application/zip"});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', `Acuses_${this.state.datosAspirante.claveElectorFuar}.zip`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }]
          },
        ).then(res => {
          console.log("Descargando...",res);
        }).catch(error => {
            console.error("Error al descargar los acuses", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_descarga_compulsa_inter;
                }
            } else {
                msjError = etiquetas.gral_descarga_compulsa_inter;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};

        const Acuses = this.state.acuses && this.state.acuses.map((opcion, index) => (
                            <Col span={20} offset={4} key={index}>
                                <CheckboxANTD className='container_reenvioCheck' 
                                              value={opcion.value}>{opcion.label}
                                </CheckboxANTD>
                            </Col>
                        )) || {};

        return(
            <AuthenticatedComponent listo={this.funcionListo}>
            <Template  contenido={
                <div className="contenedorReenvio">
                    <Link to="" onClick={this.regresar} className="btn btn-success mr-2">
                        <Icon type="arrow-left" /> Regresar
                    </Link>
                    <div className="container_reenvio">
                        <div>
                            <div style={{textAlign:'center'}}>
                            <span className='exito_titulo' style={{fontSize: '2em'}}><b> Descarga de Acuses </b></span>
                            </div>
                            <br/>
                            <div>
                                <span className='subtitulo_cargaDocumentos'><b>NOMBRE: </b></span>
                                <span className='subtitulo_cargaDocumentos'>{datosAspirante.nombre +" "+ datosAspirante.apellidoPaterno +" "+ datosAspirante.apellidoMaterno}</span>
                            </div>
                            <br/>
                            <div>
                                <span className='subtitulo_cargaDocumentos'><b>CLAVE DE ELECTOR: </b></span>
                                <span className='subtitulo_cargaDocumentos'>{datosAspirante.claveElectorFuar}</span>
                            </div>
                            <br/><hr/><br/>
                            <center>
                                <div>
                                    <span className='subtitulo_cargaDocumentos'>Selecciona el o los acuses que requieras descargar. </span>
                                    <br/><br/>
                                </div>
                                <div>
                                    <Checkbox.Group rules={[{type: 'array'}]}
                                                    style={{ width: '100%'}}
                                                    onChange={(value) => this.onChange(value)}>
                                        <Row style={{textAlign:'center'}}>
                                            { Acuses }
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                                <br/>
                                <Button icon="download" id="boton-resend"
                                       //className="button_style"
                                        style={{width:150}} 
                                        htmlType="submit" 
                                        disabled={!this.state.descarga}
                                        onClick={()=>this.descargarAcuses()}>
                                            Descarga
                                </Button>
                            </center>
                            <br/>
                        </div>
                    </div>
                </div>
            }
            guia={false}/>
            <Modal className="modalError"
                   title={<img  src={errorImgModal} id="img-error-modal"/>}
                   centered
                   onCancel={()=>this.cerrarErrorModal()}
                   visible={this.state.abrirModalError}
                   footer={false}
                   width={400}>
                {this.state.msjError}
            </Modal>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
        datosUsuario = state.sesion.datosUsuario;

    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}
export default connect(mapStateToProps)(DescargaAcuses);
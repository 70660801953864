import React from 'react';
import {Card, Row, Col, Checkbox as CheckboxANTD, message, Modal} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import InputText from '../interfaz/InputText';
import TextArea from '../interfaz/TextArea'
import Checkbox from '../interfaz/Checkbox';
import errorImgModal from '../../img/error_icon.svg';

let msjError = "";

class MedioConvocatoria extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {
            lstMediosDifusion : null,
            caracteresRetantesMotivo: 200,
            msjError: "",
            abrirModalError: false
        };

        this.handleMediosSeleccionadosChange = this.handleMediosSeleccionadosChange.bind(this);
    }

    componentWillMount() {
      this.props.getMediosDifusionAspirante()
              .then(lstMediosDifusion => this.setState({lstMediosDifusion}))
              .catch(error => {
                  console.error("Error en componentWillMount de MedioConvocatoria", error);
                  if (error.response && error.response.status === 409){
                      msjError = error.response.data.message;
                  } else {
                      msjError = etiquetas.gral_serivicio_no_disponible;
                  }
                  this.setState({msjError: msjError, abrirModalError: true});
              });
    }

    handleMediosSeleccionadosChange = (value) => {
        this.props.form.setFieldsValue(
                {
                    especifiqueMedio: null,
                },
                () => {
                    this.props.form.validateFields(['especifiqueMedio'], { force: true });
                }
            );
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_medio_difusion_lbl_titulo} bordered={false}>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox.Group nombre='mediosSeleccionados'
                                            label={etiquetas.datos_medio_difusion_lbl_mediosDifusion}
                                            form={this.props.form}
                                            required={true}
                                            msjRequerido={etiquetas.general_dato_requerido}
                                            mensajeValidacion={etiquetas.general_dato_requerido}
                                            rules={[{type: 'array'}]}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.handleMediosSeleccionadosChange(value)}>
                                    <Row style={{'textAlign':'justify'}}>
                                        {
                                            this.state.lstMediosDifusion &&
                                            this.state.lstMediosDifusion.map((medioDifusion, index) => (
                                                <Col span={8} key={index}>
                                                    <CheckboxANTD value={medioDifusion.idMedioDifusion}>{medioDifusion.descripcionMedio}</CheckboxANTD>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                            </Checkbox.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="especifiqueMedio"
                                       placeholder={etiquetas.datos_medio_difusion_lbl_especifiqueMedio}
                                       form={this.props.form}
                                       disabled={!(this.props.form.getFieldValue("mediosSeleccionados") && this.props.form.getFieldValue("mediosSeleccionados").includes(12))}
                                       required={(this.props.form.getFieldValue("mediosSeleccionados") && this.props.form.getFieldValue("mediosSeleccionados").includes(12))}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={100}
                                       rules={[{
                                                max: 100
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <TextArea nombre="motivoParticipar"
                                       placeholder={etiquetas.datos_medio_difusion_lbl_motivoParticipar}
                                       form={this.props.form}
                                       autoSize={{minRows: 5, maxRows: 5}}
                                       required={true}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={200}
                                       rules={[{
                                                max: 200
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}
                                       custom={{
                                            getValueFromEvent: (event) => {

                                                this.setState({ caracteresRetantesMotivo : 200 - event.target.value.length});

                                                return	event.target.value;
                                            }
                                        }}/>
                            <div style={{'textAlign': 'justify', 'fontStyle':'italic'}}>{this.state.caracteresRetantesMotivo} caracteres restantes.</div>
                        </Col>
                    </Row>
                </Card>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
           </React.Fragment>
        );
    }
};

export default MedioConvocatoria;

import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { Card, Row, Col, Form, Button, Icon, Input, Select, message, Typography, Modal, Layout} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import TemplateExterno from '../interfaz/TemplateExterno';
import {valueUpperCase} from '../../utils/funciones.js';
import claveContacto from '../../img/modal_contacto.png';
import estadoContacto from '../../img/contactoJDE.png';
import errorImgModal from '../../img/error_icon.svg';
const {Footer} = Layout;
const { Item } =Form;
const { Option } = Select;
const { Text } = Typography;

let msjError = "";

class ModificaCorreoComponent extends React.Component {

    constructor(props) {
        // Calling the constructor of
        // Parent Class React.Component
        super(props);

        // Setting the initial state
        this.state = {
            lstEstados: null,
			modalContactoVisible: false,
            modalContactoInfo : [],
            modalContactoClave: false,
            botonContactoActivo: false,
            msjError: "",
            abrirModalError: false
        };
    }

    componentWillMount() {
        this.setState({botonContactoActivo: false});
        this.getEstados()
                    .then(lstEstados => {
                        this.setState({lstEstados})
                    })
                    .catch(error => {
                        console.error("Error al consultar el catalodo de Estados en getEstados", error);
                        if (error.response && error.response.status === 409){
                            msjError = error.response.data.message;
                        } else {
                            msjError = etiquetas.gral_serivicio_no_disponible;
                        }
                        this.setState({msjError: msjError, abrirModalError: true});
                    });
    }

    getEstados = async () => {

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/estados_convocatorias_vigentes'
                                    , { esJuntaDistrital : false}
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        return await response.data;
    }

    getConvocatoria = () => {

        // Validando que esten establecidos los valores de Estado y Seccion
        if(this.props.form.getFieldValue('idEstado') === undefined
            || this.props.form.getFieldValue('seccion') === undefined){ return }
        if(this.props.form.getFieldValue('idEstado') !== undefined){ this.setState({botonContactoActivo: true}); }

        // Obtenido los datos del servidor
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/convocatoria'
                    , {
                        "idEstado" : this.props.form.getFieldValue('idEstado'),
                        "seccion" : this.props.form.getFieldValue('seccion'),
                        "esJuntaDistrital" : false
                    }
                    , {
                        headers: {
                            'Accept': 'application/json'
                            ,'Content-Type': 'application/json'
                        }
                    }
                ).then(response => {
                    if(response.data){
                        Object.assign(this.state, response.data);
                    } else {
                        this.props.form.setFields({
                            seccion: {
                              errors: [new Error(etiquetas.gral_no_convo_activa_seccion)],
                            },
                        });
                    }
                }).catch(error => {
                    console.log("Error al consultar getConvocatoria", error);
                    if (error.response && error.response.status === 409){
                        msjError = error.response.data.message;
                    } else {
                        msjError = etiquetas.gral_serivicio_no_disponible;
                    }
                    this.setState({msjError: msjError, abrirModalError: true});
                });
    }

    handleCorreoBlur = (value, source) => {

        const correoElectronico = this.props.form.getFieldValue('correoElectronico');
        const confirmaCorreoElectronico = this.props.form.getFieldValue('confirmaCorreoElectronico');

        if(correoElectronico && confirmaCorreoElectronico){
            if(correoElectronico !== confirmaCorreoElectronico){
                if(source === 'correoElectronico'){
                    this.props.form.setFields({
                        correoElectronico: {
                          errors: [new Error(etiquetas.gral_correos_diferentes)],
                        },
                    });
                }
                if(source === 'confirmaCorreoElectronico'){
                    this.props.form.setFields({
                        confirmaCorreoElectronico: {
                          errors: [new Error(etiquetas.gral_correos_diferentes)],
                        },
                    });
                }
            }
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((errors, values) => {
            if (!errors) {
                const datosModificaCorreo = {};
                Object.assign(datosModificaCorreo, values);
                Object.assign(datosModificaCorreo, this.state);
                axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/mofidica_correo'
                , datosModificaCorreo
                , {
                    headers: {
                        'Accept': 'application/json'
                        ,'Content-Type': 'application/json'
                    }
                }).then(response => {
                    this.props.form.resetFields();
                    message.info(etiquetas.gral_correo_confirmacion, 5);
                }).catch(error => {
                    console.error("Error al enviar los datos en handleSubmit", error);
                    if (error.response){
                        if (error.response.status === 409){
                            msjError = error.response.data.message;
                            Object.assign(this.props.form,
                                { 'seccion': {'value': ""}});
                              this.props.form.setFieldsValue({seccion: ""});
                        } else if (error.response.data && error.response.data.causa
                                    && error.response.data.causa.includes("errores")){
                        } else {
                            msjError = etiquetas.gral_error_cau;
                        }
                    } else {
                        msjError = etiquetas.gral_error_cau;
                    }
                    this.setState({msjError: msjError, abrirModalError: true});
                });
            }
        });
    }

    /*
	 *Obtiene los datos de la JDE donde el aspirante puede comunicarse en caso de tener dudas
	*/
    handleContactoJDEClick = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/contactoJDE'
            ,{
                claveElectorFuar : this.props.form.getFieldValue('claveElectorFuar'),
                idEstado : this.props.form.getFieldValue('idEstado')
            },{
                headers: {
                    'Accept': 'application/json'
                    ,'Content-Type': 'application/json'
                }
            },
        ).then(response => {
            if(response.data){
                if(response.data.length === 1){
                    this.setState({ modalContactoVisible : true,
                                    modalContactoInfo : response.data,
                                    modalContactoClave : true
                         });
                } else {
                    this.setState({ modalContactoVisible : true,
                                    modalContactoInfo : response.data,
                                    modalContactoClave : false
                        });         
                }
            }
        }).catch(error => {
            console.error("Error al consultar contacto en ModificaCorreo", error);
            if (error.response){
                if (error.response.status === 409){
                    msjError = error.response.data.message;
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){
                } else {
                    msjError = etiquetas.gral_error_contacto;
                }
            } else {
                msjError = etiquetas.gral_error_contacto;
            }
            this.setState({msjError: msjError, abrirModalError: true});
        });
    }

	handleContactoJDEClose = () => {
        this.setState({ modalContactoVisible : false });
    }

    cerrarErrorModal() {
        this.setState({abrirModalError:false});
    }

    render() {
        const { getFieldDecorator, getFieldsError, isFieldTouched } = this.props.form;
        const contacto = this.state.modalContactoInfo.map((item) =>
            <li className="vinetaContacto"><p className="text_junta"><b id="titulo_junta">{item.nombreJunta}</b><br/>
                <Icon id="icon-contacto" type="user" theme="outlined"/><b>Responsable: </b>{item.nombreContacto}<br/>
                <Icon id="icon-contacto" type="environment" theme="outlined"/><b>Domicilio: </b>{item.domicilioJunta} <br/>
                <Icon id="icon-contacto" type="phone" theme="outlined" rotate={90}/><b>Teléfono: </b> {item.telefono} <br/>
                <Icon id="icon-contacto" type="mail" theme="outlined"/><b>Correo electrónico: </b> {item.correoElectronico} <br/></p>
            </li> ) || {};
        return(
            <TemplateExterno enlaces={true}>
                <Form
                    layout='horizontal'
                    labelAlign='left'
                    colon={true}
                    onSubmit={this.handleSubmit}>

                    <Card title={etiquetas.modifica_correo_lbl_titulo} bordered={false}>
                        <Text style={{color: '#1890ff'}}><Icon type="info-circle" theme="filled" style={{fontSize: '1.0em'}} /> Ingresa tus datos para actualizar el correo que registraste.</Text>
                        <Row>
                            <Col sm={24} md={24} lg={24}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_claveElectorFuar}
                                    required={true}>
                                    {getFieldDecorator('claveElectorFuar', {
                                        getValueFromEvent: valueUpperCase,
                                        rules: [{ required: true
                                                , whitespace: false
                                                , min: 13
                                                , max: 18
                                                , pattern: etiquetas.REGEX_FORMATO_CLAVE_ELECTOR_FUAR
                                                , message: () => this.props.form.getFieldValue('claveElectorFuar') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_claveElectorFuar}
                                            maxLength={18}
                                            allowClear/>,
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_estado}
                                    required={true}>
                                    {getFieldDecorator('idEstado', {
                                        rules: [{ required: true
                                                , type: 'number'
                                                , message: this.props.form.getFieldValue('idEstado') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Select placeholder={etiquetas.modifica_correo_lbl_estado}
                                            showSearch
                                            optionFilterProp="children"
                                            //onChange={this.getConvocatoria}
                                            >
                                            {
                                                this.state.lstEstados &&
                                                this.state.lstEstados.map((estado, index) => (
                                                    <Option key={index} value={estado.idEstado}>{estado.nombreEstado}</Option>
                                                ))
                                            }
                                        </Select>,
                                    )}
                                </Item>
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_seccion}
                                    required={true}>
                                    {getFieldDecorator('seccion', {
                                        getValueFromEvent: (event) => {
                                            if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                return	event.target.value;
                                            }
                                            return this.props.form.getFieldValue('seccion');
                                        },
                                        rules: [{ required: true
                                                , max: 4
                                                , pattern: /^\d{1,4}$/i
                                                , message: () => this.props.form.getFieldValue('seccion') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_seccion}
                                            maxLength={4}
                                            disabled={this.props.form.getFieldValue('idEstado') === undefined ? true: false}
                                            onBlur={this.getConvocatoria}/>,
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_telefono}
                                    required={false}>
                                    {getFieldDecorator('telefono', {
                                        getValueFromEvent: (event) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return this.props.form.getFieldValue('telefono');
                                        },
                                        rules: [{ required: false
                                                , max: 10
                                                , pattern: etiquetas.REGEX_FORMATO_TELEFONO
                                                , message: () => this.props.form.getFieldValue('telefono') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_telefono}
                                            maxLength={10}/>,
                                    )}
                                </Item>
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_cp}
                                    required={true}>
                                    {getFieldDecorator('cp', {
                                        getValueFromEvent: (event) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return this.props.form.getFieldValue('cp');
                                        },
                                        rules: [{ required: true
                                                , len: 5
                                                , pattern: /^\d{5,5}$/i
                                                , message: () => this.props.form.getFieldValue('cp') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_cp}
                                            maxLength={5}/>,
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_correoElectronico}
                                    required={true}>
                                    {getFieldDecorator('correoElectronico', {
                                        rules: [{ required: true
                                                , whitespace: true
                                                , type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                                , message: () => this.props.form.getFieldValue('correoElectronico') ? etiquetas.general_formato_invalido : etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_correoElectronico}
                                            maxLength={60}
                                            onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                            onBlur={(value) => this.handleCorreoBlur(value, 'correoElectronico')}/>,
                                    )}
                                </Item>
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_confirmaCorreoElectronico}
                                    required={true}>
                                    {getFieldDecorator('confirmaCorreoElectronico', {
                                        rules: [{ required: true
                                                , whitespace: true
                                                , type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                                , message: () => this.props.form.getFieldValue('confirmaCorreoElectronico') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_confirmaCorreoElectronico}
                                            maxLength={60}
                                            onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                            onBlur={(value) => this.handleCorreoBlur(value, 'confirmaCorreoElectronico')}/>,
                                    )}
                                </Item>
                            </Col>
                        </Row>
                    </Card>
                    <Footer>
                        <Row type="flex" justify="center">
                            <Col span={12}>
                                <Item>
                                    <Button type="primary" 
                                            id="button_correo"
                                            style={{marginLeft: '65%'}}
                                            htmlType="submit" >
                                        Aceptar
                                    </Button>
                                </Item>
                            </Col>
                            <Col span={12}>
                                <Item>
                                    <Button type="primary"
                                            id="button_correo"
                                            style={{marginLeft: '15%'}}
                                            disabled={!this.state.botonContactoActivo}
                                            onClick={this.handleContactoJDEClick}>
                                        Contacto
                                    </Button>
                                </Item>
                            </Col>
                        </Row>
                    </Footer>
                </Form>
                <Modal
                    visible={this.state.modalContactoVisible}
                    title={null}
					centered={true}
					onOk={this.handleContactoJDEClose}
                    onCancel={this.handleContactoJDEClose}
                    footer={null}
                    className="modal_contacto"
                >
                {this.state.modalContactoClave ?
                    <div id="modal_clave">
                        <div id="modal_infoContacto">
                            Si requieres más información, comunícate vía telefónica, por correo electrónico o acude
                            directamente al domicilio para atenderte y aclarar tus dudas.
                        </div>
                        <br/><hr/><br/>
                        <div>
                            <Col xs={24} sm={6} md={12} lg={10}>
                                <img src={claveContacto} id="modal_contactoImgClave"/>
                            </Col>
                            <Col xs={24} sm={6} md={12} lg={12}>
                                {contacto}
                            </Col>
                        </div>
                    </div> :
                    <div id="modal_estado">
                        <Col xs={24} sm={6} md={12} lg={10}>
                            <img src={estadoContacto} id="modal_contactoImgEstado"/>
                        </Col>
                        <Col xs={24} sm={6} md={12} lg={12}>
                            <div className="lstJuntas">
                                {contacto}
                            </div>
                        </Col>
                    </div>
                }
                </Modal>
                <Modal className="modalError"
                       title={<img  src={errorImgModal} id="img-error-modal"/>}
                       centered
                       onCancel={()=>this.cerrarErrorModal()}
                       visible={this.state.abrirModalError}
                       footer={false}
                       width={400}>
                    {this.state.msjError}
                </Modal>
            </TemplateExterno>
        );
    }
};

export default withRouter((Form.create({ name: 'modificaCorreoComponent' })(ModificaCorreoComponent)));

import React, {Component} from 'react'
import Template from '../interfaz/Template'
import AuthenticatedComponent from '../AuthenticatedComponent';
import {Layout, Form, Button,  Row, Col, message,Icon, Modal} from 'antd'
import SourceViewer from '../interfaz/SourceViewer'
import Cubrimiento from '../interfaz/Cubrimiento'
import InputText from '../interfaz/InputText'
import {connect} from 'react-redux'
import axios from 'axios'
import {irAtras, toggleMostrar, establecerIniciales, compararValores} from '../../utils/funciones'
import {implantarAspirante} from '../../redux/actions/accionesSesion'
import {emitirOperacion, borrarOperacion} from '../../redux/actions/accionesOperacion'
import '../../css/visor.css'
import { Link } from "react-router-dom";
import errorImgModal from '../../img/error_icon.svg';
import * as etiquetas from '../../ApplicationResources';

let msjError = "";
const {Header, Sider, Footer, Content} = Layout;
var _compararValores;

const rolesCaptura = [
        'ELECINE.ADMIN.OC', 'ELECINE.CAPTURA.JD','ELECINE.CAPTURA.JL',
        'ELECINE.CAPTURA.OC', 'ELECINE.CAPTURA_CONSEJERO.JL',
        'ELECINE.CAPTURA_VE.JD', 'ELECINE.CAPTURA_VE.JL',
        'ELECINE.CAPTURA_VECEYEC.JD', 'ELECINE.CAPTURA_VECEYEC.JL',
        'ELECINE.CONSEJERO.JD'
      ];

class Visor extends Component {
    state = {
        cuadroFoco: undefined,
        tab: 0,
        cargado: false,
        sedesCargadas: false,
        mostrarCarta: false,
        widthModal: 520,
        mostrarCuadroExamen: false,
        validacion: [],
        todosValidos: false,
        cargando: false,
        declina: false,
        activaGuardado: false,
        existeExpediente:false,
        recarga:true,
        msjError : "",
        abrirModalError : false
    }

    irAtras = irAtras.bind(this);

    constructor(props) {
        super(props);
        this.recursos = {};
        this.controlls = [];
        this.establecerIniciales = establecerIniciales.bind(this);
        this.confirmacion = '¿Estás seguro?';
        _compararValores = compararValores.bind(this, this.callbackCambio, this.callbackRegreso);
    }

	  UNSAFE_componentWillReceiveProps = (nextProps) => {
        nextProps.form.haValidado = this.props.form.haValidado;
	  }

    cerrarErrorModal(){
      this.setState({abrirModalError:false});
      this.props.history.push('/bandeja');
    }

    buscarExpediente(){

      const datosAspirante = this.props.datosAspirante;
      const datosUsuario = this.props.datosUsuario;

      if (!datosAspirante) {
          this.props.history.push('/bandeja');
          return;
      }

      var postData = {
        idProcesoElectoral: datosAspirante.idProcesoElectoral,
        idDetalleProceso: datosAspirante.idDetalleProceso,
        idParticipacion: datosAspirante.idParticipacion,
        idAspirante: datosAspirante.idAspirante,
        idConvocatoria : datosAspirante.idConvocatoria,
        folio : datosAspirante.folio,
        claveElector: datosAspirante.claveElectorFuar,
        usuario: datosUsuario.username,
        ipUsuario: datosUsuario.ip
  		}

      axios.post(process.env.REACT_APP_API_URL +
  			"/centralReclutaSEyCAE/ws/buscarExpediente", postData,
  					{
  							headers: {
  								'Accept': 'application/json',
  								'Content-Type': 'application/json'
  							}
  					}
  			 ).then(response => {
           if (!response.data.existeExpediente) {
              this.setState({
                msjError : "No existe expediente",
                abrirModalError : true
              });
              return;
           }
      }).catch(error => {
        console.error("Error al buscar el expediente "+
            "buscarExpediente()",error);
        if (error.response){
            if (error.response.status === 409){
                msjError = error.response.data.message;
            } else if (error.response.data && error.response.data.causa
                && error.response.data.causa.includes("Error de validación")){
                msjError = error.response.data.message;
            } else {
                msjError = etiquetas.gral_error_cau;
            }
        } else {
            msjError = etiquetas.gral_error_cau;
        }
        this.setState({
          msjError : msjError,
          abrirModalError : true
        });
      });
    }

    listo = () => {
      this.buscarExpediente();
      const datosAspirante = this.props.datosAspirante;
      const datosUsuario = this.props.datosUsuario;

      if (!datosAspirante) {
          this.props.history.push('/bandeja');
          return;
      }

      const jwt = localStorage.getItem('jwt-token');

      const url = process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_archivo';
      const viewers = [];
      viewers[0] = [];
      viewers[1] = [];

      axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_observaciones_archivos', {
          idProcesoElectoral: datosAspirante.idProcesoElectoral,
          idDetalleProceso: datosAspirante.idDetalleProceso,
          idParticipacion: datosAspirante.idParticipacion,
          idAspirante: datosAspirante.idAspirante,
      },
      {
          responseType: 'application/json',
          headers : {Authorization: jwt}
      }).then(res => {
          const archivosObservaciones = res.data.archivosObservaciones;
          const arregloDocs = archivosObservaciones.listaObservaciones;
          const acudirJDE = archivosObservaciones.acudirJDE ? 'true' : (archivosObservaciones.estatusRegistro === 4 ? 'false' : undefined);
          this.listaObservaciones = [];

          this.props.form.setFields({radioDecision: {value: acudirJDE}});
          this.setState({declina: acudirJDE === 'false'})

          arregloDocs.forEach(valor => {
              const renglon = {};
              renglon.idTipoDocumento = valor.idTipoDocumento;
              renglon.titulo = valor.nombreDocumento;
              renglon.src = valor.nombreArchivo;
              const nombre = valor.nombreDocumento.replace(/ (de|para)/g, ' ').toLowerCase();
              renglon.nombre = nombre.split(' ').map((val, index) => {
                  return index > 0 ? val.charAt(0).toUpperCase() + val.substring(1) : val;
              }).join('');

              const nombreArchivoMin = valor.nombreArchivo.toLowerCase();
              if (nombreArchivoMin.endsWith('.jpeg') || nombreArchivoMin.endsWith('.jpg'))
                  renglon.type = 'image/jpeg'
              else if (nombreArchivoMin.endsWith('.png'))
                  renglon.type = 'image/png'
              else
                  renglon.type = 'application/pdf'
                  renglon.url = url;
                  viewers[valor.obligatorio === 'S' ? 0 : 1].push(renglon);
          });

            function visible(indice) {
                return this.state.tab === indice;
            }

            const cubrir = this.props.operaciones.accion !== 'captura' || datosAspirante.bAcudirJDE === 1 ||
                           datosAspirante.estatusRegistro === 4 ||
                           !rolesCaptura.some(rol => (datosUsuario.rolUsuario === rol));

            this.renderViews = () => {
                const _viewers = {viewers: [], controlls: []};

                for (var i = 0; i < 2; i++) {
                    viewers[i].forEach(data => {
                        _viewers.viewers.push(<SourceViewer key={data.nombre + 'Sv'}
                                                    nombre={data.nombre}
                                                    urlDoc={url}
                                                    titulo={data.titulo}
                                                    type={data.type}
                                                    data={{idProcesoElectoral: datosAspirante.idProcesoElectoral,
                                                        idDetalleProceso: datosAspirante.idDetalleProceso,
                                                        idParticipacion: datosAspirante.idParticipacion,
                                                        idConvocatoria: datosAspirante.idConvocatoria,
                                                        folio: datosAspirante.folio,
                                                        claveElectorFuar: datosAspirante.claveElectorFuar,
                                                        src: data.src}}
                                                        cuadroFoco={this.state.cuadroFoco}
                                                    recursos={this.recursos}
                                                    visible={visible.bind(this, i)}/>);
                        _viewers.controlls.push(<CuadroValidacion id={data.idTipoDocumento}
                                                        key={data.nombre + 'Ctrl'}
                                                        form={this.props.form}
                                                        nombre={data.nombre}
                                                        titulo={data.titulo}
                                                        cambioFoco={this.cambiarFoco}
                                                        scrollVisor={this.scrollVisor}
                                                        visible={visible.bind(this, i)}
                                                        cubrir={cubrir}/>);
                    });
                }

                return _viewers;
            }

            this.setState({cargado: true});
        }).catch(_error => {});

    }

    cambiarFoco = (nuevoCuadro, cb) => {
        this.setState(state => {
            if (nuevoCuadro === state.cuadroFoco)
                return state;

            cb();
            return {cuadroFoco: nuevoCuadro};
        });
    }

    scrollVisor = (idRecurso) => {
        if (this.recursos[idRecurso] === undefined)
            return;

        const offset = this.recursos[idRecurso].offsetTop;
        const divVisor = document.getElementsByClassName('visor_validar')[0];

        divVisor.scrollTop = offset - 15;
    }

    cambiarTab(newTab) {
        this.setState({tab : newTab, cuadroFoco: undefined});
    }

    toggleMostrar = toggleMostrar.bind(this);


    descargarExpediente = () => {
      const datosAspirante = this.props.datosAspirante;
      const datosUsuario = this.props.datosUsuario;

      axios.post(process.env.REACT_APP_API_URL +
          '/centralReclutaSEyCAE/ws/descargarExpediente', {
                    idProcesoElectoral: datosAspirante.idProcesoElectoral,
                    idDetalleProceso: datosAspirante.idDetalleProceso,
                    idParticipacion: datosAspirante.idParticipacion,
                    idAspirante: datosAspirante.idAspirante,
                    idConvocatoria : datosAspirante.idConvocatoria,
                    folio : datosAspirante.folio,
                    claveElector: datosAspirante.claveElectorFuar,
                    usuario: datosUsuario.username,
                    ipUsuario: datosUsuario.ip
          },{
              responseType: 'blob',
              transformResponse: [ data => {
              let blob = new window.Blob([data], {type: "application/zip"});
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute('download', `${datosAspirante.claveElectorFuar}.zip`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }]
        },
      ).then(res => {
        console.log("respuesta...",res);
      })
    };

    callbackCambio = () => {
        this.setState({activaGuardado: true});
    }

    callbackRegreso = () => {
        this.setState({activaGuardado: false});
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};
        const datosUsuario = this.props.datosUsuario || {};
        const views = this.renderViews? this.renderViews() : {};
        const captura = rolesCaptura.some(rol => (datosUsuario.rolUsuario === rol))

        return (
            <AuthenticatedComponent externo={false}
                                    listo={this.listo}>
                <Template contenido={
                    <Layout className='validar_modulo'>
                      <Link to={`/bandeja`}className="btn btn-success mr-2"
                          style={{'margin-top':'15px'}}>
                        <Icon type="arrow-left" /> Regresar
                        </Link>
                        <Header theme='light' className='header_validar'>
                            <div>
                                <div>Expediente</div>

                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{'text-align':'left', 'padding-left' : '80px'}}>
                                    <p className="datosAspirante">
                                      <span>Nombre</span>:
                                      {datosAspirante.nombre} {datosAspirante.apellidoPaterno} {datosAspirante.apellidoMaterno}
                                    </p>
                                    <p className="datosAspirante">
                                      <span>Clave de elector</span>: {datosAspirante.claveElectorFuar}
                                    </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p></p>
                                    <p className="datosAspirante">
                                      <span>Sección</span>: {datosAspirante.seccionDom}
                                    </p>
                                  </Col>
                                </Row>
                            </div>
                        </Header>
                        <Layout className='contenido_validar' onFocus={this.establecerIniciales}>
                            <Content className='contenido_anidado'>
                                <React.Fragment>

                                    <div className='visor_validar'>
                                        {this.state.cargado ? views.viewers : null}
                                    </div>
                                </React.Fragment>
                            </Content>
                            <Sider theme='light' width='25%'>
                              <div className='encabezado_validar' >
                                  <span onClick={() => this.cambiarTab(0)}
                                        className={this.state.tab === 0 ?
                                                   'validar_activo' : ''}
                                                   style={{'width': '25%'}}>
                                      Obligatoria
                                  </span>
                                  <span onClick={() => this.cambiarTab(1)}
                                        className={this.state.tab === 1 ?
                                                   'validar_activo' : ''}
                                                   style={{'width': '25%'}}>
                                      Opcional
                                  </span>
                              </div>
                                {this.state.cargado ? views.controlls : null}
                                {/*    <hr />
                                        <a href='#' onClick={(e) => this.toggleMostrar(e, 'mostrarCarta')}><Icon type='eye'/> Ver carta declaratoria</a>
                                <hr/>*/}

                                <Cubrimiento cubrir={this.props.operaciones.accion !== 'captura'}>

                                </Cubrimiento>
                                <Button type='primary'
                                 onClick={this.descargarExpediente} style={{'float':'right'}}>
                                    Descargar Expediente
                                 </Button>
                            </Sider>
                        </Layout>
                        <Footer>
                        </Footer>
                    </Layout>
                }
                guia={false}
                bloqueado={!this.props.datosAspirante}
                cargando={this.state.cargando}
                bloquearTodo={this.state.cargando}/>
                <Modal
                  className="modalError"
                  title={<img  src={errorImgModal} id="img-error-modal"/>}
                  centered
                  onCancel={()=>this.cerrarErrorModal()}
                  visible={this.state.abrirModalError}
                  footer={false}
                  width={400}
                >
                  {this.state.msjError}
                </Modal>
            </AuthenticatedComponent>
        );
    }
}

class CuadroValidacion extends Component {
    state = {clase: 1}

    componentDidUpdate() {
        if (this.state.clase === 2) {
            setTimeout(() => this.setState({clase: 1}), 0);
            this.props.scrollVisor(this.props.nombre);
        }
    }

    getFocus = () => {
        this.props.cambioFoco(this.props.nombre, () => {
            this.setState({clase: 2});
        });
    }

    render() {
        const cN = this.state.clase === 1 ? 'cuadro_validacion' : 'cuadro_validacion cuadro_validacion_tomado';
        const vs = !this.props.visible() ? 'visor_hidden' : '';
        const pp = this.state.clase === 1 ? 'cambio' : '';

        return (
          <>
            <div id={'id' + this.props.nombre}
                 className={cN + ' estilosVisor  ' + vs}
                 onFocus={this.getFocus}
                 tabIndex={this.props.id}>
                <Cubrimiento cubrir={this.props.cubrir}>
                    <div className='cuadro_validacion_titulo visorCubrimiento'>
                      {this.props.titulo}
                      <Icon
                        className={pp}
                        id="icono-titulo-doc"
                        type="eye"
                        theme="outlined"
                      />
                    </div>
                    <div>
                        <InputText form={this.props.form}
                                nombre={this.props.nombre + 'Msj'}
                                elementClass='solo_error'/>
                    </div>
                </Cubrimiento>
            </div>
            <Modal
              className="modalError"
              title={<img  src={errorImgModal} id="img-error-modal"/>}
              centered
              onCancel={()=>this.cerrarErrorModal()}
              visible={this.state.abrirModalError}
              footer={false}
              width={400}
            >
              {this.state.msjError}
            </Modal>
          </>
        );
    }
}

const mapStateToProps = state => {
    var datosAspirante, datosUsuario;
    const operaciones = state.operaciones || {};

    if (state.sesion.datosUsuario) {
        datosUsuario = state.sesion.datosUsuario;
        datosAspirante = datosUsuario.datosAspirante;
    }

    return {
        datosUsuario: datosUsuario,
        datosAspirante: datosAspirante,
        operaciones
    };
}

export default Form.create({name: 'validacion', onFieldsChange: function onValuesChange() {
    _compararValores();
}})(connect(mapStateToProps, {implantarAspirante, emitirOperacion, borrarOperacion})(Visor));
